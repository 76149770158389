import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { useDispatch } from "react-redux";
import actions from "redux/actions";
import axios from "axios";

function TypeItem({ type, wage, ot, noGutter, editItem }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { editTypesForm } = actions;
  const dispatch = useDispatch();
  const fetchDetails = () => {
    dispatch(editTypesForm({ category: type, wage, ot }));
    // editItem({category:type,wage,ot});
  };

  const deleteType = () => {
    const data = { employeeType: type };

    axios({
      method: 'delete', //you can set what request you want to be
      url: `${process.env.REACT_APP_BASE_URL}/employee/deleteType`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      }
    }) .then((res) => res.json())
    .then((data) => {
      console.log(data);
      window.location.reload();
    })
    .catch((err) => console.log(err.response));




   
     
  };
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography
            variant="button"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {type}
          </MDTypography>

          <MDBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
          >
            <MDBox mr={1}>
              <MDButton variant="text" color="error" onClick={deleteType}>
                <Icon>delete</Icon>&nbsp;delete
              </MDButton>
            </MDBox>
            <MDButton
              variant="text"
              color={darkMode ? "white" : "dark"}
              onClick={fetchDetails}
            >
              <Icon>edit</Icon>&nbsp;edit
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Daily Wage:
            <MDTypography
              variant="caption"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {" Rs. " + wage}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            OT Hours:
            <MDTypography variant="caption" fontWeight="medium">
              {" Rs. " + ot }
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
TypeItem.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
TypeItem.propTypes = {
  type: PropTypes.string.isRequired,
  wage: PropTypes.number.isRequired,
  ot: PropTypes.number.isRequired,
  noGutter: PropTypes.bool,
};

export default TypeItem;
