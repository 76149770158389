import {
  accordionSummaryClasses,
  Box,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/actions";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../assets/loading.svg";
import { useFormik } from "formik";
import validationSchemaPayment from "./validationSchema/PaymentValidationSchema";

function PaymentDetails({
  editForm,
  back,
  next,
  step,
  reset,
  formDetails,
  setFormDetails,
  notifyPop,
  isEdit,
  handleCloseEdit,
}) {
  const [regError, setRegError] = useState(false);

  const myForm = React.useRef(null);

  console.log(isEdit, "edit");

  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const storeFormData = useSelector((state) => state.regForm.details);

  const notify = () =>
    toast.success("Employee Registered Successfully!!", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const initialValues = formDetails;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaPayment,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const handleInput = (e) => {
    const value = e.target.value;
    console.log(value);
    if (e.target.name === "accountNo") {
      setFormDetails({ ...formDetails, [e.target.name]: value });
    } else {
      setFormDetails({ ...formDetails, [e.target.name]: value });
    }
    console.log(formDetails);
  };

  const checkValidity = () => {
    setRegError(false);
    if (!myForm.current.checkValidity()) {
      setError(true);
      return;
    }
    setLoading(true);
    setFormDetails((prev) => {
      delete prev["lastInHandSalary"];
      delete prev["lastDate"];

      return prev;
    });
    dispatch(actions.editRegForm(formDetails));
    console.log(storeFormData, "dataform");

    const form = new FormData();
    for (let [key, value] of Object.entries(storeFormData)) {
      form.set(key, value);
    }

    for (let [key, value] of Object.entries(formDetails)) {
      if (value === null) value = "";
      form.set(key, value);
    }

    console.log(form.getAll("empId"));
    if (isEdit) {
      console.log(`Form Details: ${formDetails}`);
      axios({
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/employee/update`,
        data: formDetails,
      })
        .then((data) => {
          setLoading(false);
          setSuccess(true);
          toast.success("Updated Successfully!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setFormDetails({
            empId: "",
            employeeName: "",
            employeeDesignation: "",
            employeeDeploySite: "",
            gender: "",
            dob: "",
            qualification: "",
            contact: 0,
            email: "",
            emergencyContact: 0,
            pfNo: "",
            pfuanNo: "",
            esicNo: "",
            adharNo: 0,
            panNo: "",
            safetyPassNo: "",
            bankName: "",
            accountNo: "",
            incentive: 0,
            ifscCode: "",
            jntvtiCertNo: "",
            jntvtiCategory: "",
            workExp: 0,
            employeeType: "",
          });
          dispatch(
            actions.editRegForm({
              empId: "",
              employeeName: "",
              employeeDesignation: "",
              employeeDeploySite: "",
              gender: "",
              dob: "",
              qualification: "",
              contact: 0,
              email: "",
              emergencyContact: 0,
              pfNo: "",
              pfuanNo: "",
              esicNo: "",
              adharNo: 0,
              panNo: "",
              safetyPassNo: "",
              bankName: "",
              accountNo: "",
              incentive: 0,
              ifscCode: "",
              jntvtiCertNo: "",
              jntvtiCategory: "",
              workExp: 0,
              employeeType: "",
            })
          );
          setTimeout(() => {
            handleCloseEdit();
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setRegError(true);
          toast.error("Something went wrong!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else {
      console.log(typeof form.get("accountNo"), "formdata");
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/employee/register`,
        data: form,
        headers: {
          "Content-Type": undefined,
        },
      })
        .then((data) => {
          console.log(data);
          setLoading(false);
          setSuccess(true);
          notifyPop();
          setFormDetails({
            empId: "",
            employeeName: "",
            employeeDesignation: "",
            employeeDeploySite: "",
            gender: "",
            dob: "",
            qualification: "",
            contact: 0,
            email: "",
            emergencyContact: 0,
            pfNo: "",
            pfuanNo: "",
            esicNo: "",
            adharNo: 0,
            panNo: "",
            safetyPassNo: "",
            bankName: "",
            accountNo: "",
            incentive: 0,
            ifscCode: "",
            jntvtiCertNo: "",
            jntvtiCategory: "",
            workExp: 0,
            employeeType: "",
          });
          dispatch(
            actions.editRegForm({
              empId: "",
              employeeName: "",
              employeeDesignation: "",
              employeeDeploySite: "",
              gender: "",
              dob: "",
              qualification: "",
              contact: 0,
              email: "",
              emergencyContact: 0,
              pfNo: "",
              pfuanNo: "",
              esicNo: "",
              adharNo: 0,
              panNo: "",
              safetyPassNo: "",
              bankName: "",
              accountNo: "",
              incentive: 0,
              ifscCode: "",
              jntvtiCertNo: "",
              jntvtiCategory: "",
              workExp: 0,
              employeeType: "",
            })
          );
          setTimeout(() => {
            reset();
          }, 3000);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setRegError(true);
        });
    }
  };
  useEffect(() => {
    console.log("run");
    if (storeFormData) {
      setFormDetails(storeFormData);
    }
  }, []);

  return (
    <>
      <Box px={2} py={3}>
        <form ref={myForm}>
          {error && (
            <Typography
              variant="caption"
              sx={{ color: "red", fontSize: "14px" }}
            >
              *Please fill all the necessary fields
            </Typography>
          )}{" "}
          <Typography variant="h5">Payment Details</Typography>
          <Grid container py={3} sx={{ justifyContent: "space-between" }}>
            <Grid item xs={12} sm={12} md={3.2}>
              <TextField
                required
                id="standard-basic"
                label="Account No."
                type="number"
                name="accountNo"
                variant="standard"
                value={formDetails?.accountNo || ""}
                onChange={(event) => {
                  handleChange(event);
                  handleInput(event);
                }}
                onBlur={handleBlur}
                error={touched.accountNo && !!errors.accountNo}
                helperText={touched.accountNo && errors.accountNo}
                inputProps={{ maxLength: 35 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3.2}>
              <TextField
                id="standard-basic"
                label="Bank Name"
                name="bankName"
                value={formDetails?.bankName || ""}
                variant="standard"
                onChange={(event) => {
                  handleChange(event);
                  handleInput(event);
                }}
                onBlur={handleBlur}
                error={touched.bankName && !!errors.bankName}
                helperText={touched.bankName && errors.bankName}
                inputProps={{ maxLength: 35 }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3.2}>
              <TextField
                required
                id="standard-basic"
                label="IFSC Code"
                name="ifscCode"
                variant="standard"
                value={formDetails?.ifscCode || ""}
                onChange={(event) => {
                  handleChange(event);
                  handleInput(event);
                }}
                onBlur={handleBlur}
                error={touched.ifscCode && !!errors.ifscCode}
                helperText={touched.ifscCode && errors.ifscCode}
                inputProps={{ maxLength: 35 }}
                fullWidth
              />
            </Grid>
          </Grid>
          {regError && (
            <Box sx={{ flex: "1 1 auto" }}>
              <Typography variant="caption" color={"red"}>
                *Some Error Occurred (please check the filled details)
              </Typography>
            </Box>
          )}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                pt: 2,
                paddingRight: "30px",
              }}
            >
              <img
                src={loader}
                alt="loader"
                style={{ width: "35px", height: "35px" }}
              />
            </Box>
          ) : success ? (
            <Box sx={{ fontSize: "14px" }}>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <MDButton
                disabled={step === 0}
                variant="gradient"
                color="info"
                onClick={back}
                sx={{
                  mr: "1",
                  color: "white",
                  background: "#232526",
                  background:
                    "-webkit-linear-gradient(to right, #232526, #414345)",
                  background: "linear-gradient(to right, #232526, #414345)",
                }}
              >
                Back
              </MDButton>
              <Box sx={{ flex: "1 1 auto" }} />
              <MDButton
                variant="gradient"
                color="info"
                onClick={checkValidity}
                sx={{
                  color: "white",
                  //   background: "#232526",
                  //   background: "-webkit-linear-gradient(to right, #232526, #414345)",
                  //   background: "linear-gradient(to right, #232526, #414345)",
                }}
              >
                {"Submit"}
              </MDButton>
            </Box>
          )}
        </form>
      </Box>
    </>
  );
}

export default PaymentDetails;
