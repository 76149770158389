import { AccountCircle } from "@mui/icons-material";
import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import EmployeeDetails from "./EmployeeDetails";
import Form from "./Form";
import empty from "../../../assets/empty.gif";

import loader from "../../../assets/loading.svg";

function EmployeeLoan() {
  const [search, setSearch] = useState("");
  const [employee, setEmployee] = useState(null);
  const [error, setError] = useState(false);
  const [loan, setLoan] = useState(null);
  const [loading, setLoading] = useState(false);
  const searchEmployee = () => {
    console.log("searching");
    setLoading(true);

    const urls = [
      `${process.env.REACT_APP_BASE_URL}/employee?empId=${search}`,
      `${process.env.REACT_APP_BASE_URL}/advance?empId=${search}`,
    ];

    const promise1 = axios.get(urls[0]);
    const promise2 = axios.get(urls[1]);

    Promise.allSettled([promise1, promise2])
      .then((values) => {
        console.log(values[0].value.data.employees[0]);
        if (values[0].status === "fulfilled") {
          setEmployee(values[0].value.data.employees[0]);
          setError(false);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
        if (values[1].status === "fulfilled") {
          setLoan(values[1].value.data?.advance[0]?.advance);
          console.log(values[1].value.data?.advance[0]?.advance);
        } else {
          setLoan(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // axios({
    //   method: "get", //you can set what request you want to be
    //   url: `${process.env.REACT_APP_BASE_URL}/employee?empId=${search}`,
    // })
    //   .then((res) => {
    //     console.log(res.data.employee);
    //     setEmployee(res.data.employee);
    //     setError(false);
    //     //   window.location.reload();
    //   })
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Typography variant="h4">Advance Form</Typography>

      <Grid
        container
        gap="2rem"
        sx={{
          justifyContent: {
            xs: "center",
            md: "center",
            lg: "flex-start",
          },
          gap: {
            lg: "2rem",
            md: "0.8rem",
          },
        }}
      >
        <Grid
          item
          xs="11"
          sm="11"
          md="10"
          lg="5"
          gap="1rem"
          display={"flex"}
          flexDirection="column"
        >
          <Box
            sx={{ display: "flex", alignItems: "flex-end", marginTop: "1rem" }}
          >
            <AccountCircle
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
              fontSize="medium"
            />
            <TextField
              id="input-with-sx"
              label="Enter Employee ID"
              variant="standard"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              helperText={error}
              error={error ? true : false}
            />
            <Button
              variant="gradient"
              color="info"
              onClick={searchEmployee}
              sx={{
                color: "white",
                marginLeft: "1rem",
                border: "1px solid gray",
                transition: "all 0.1s ease-in-out",
                "&:hover": {
                  color: "white !important",
                  background: "#232526",
                  background:
                    "-webkit-linear-gradient(to right, #232526, #414345)",
                  background: "linear-gradient(to right, #232526, #414345)",
                },
              }}
            >
              {loading ? (
                <img
                  src={loader}
                  style={{ width: "20px", height: "20px" }}
                ></img>
              ) : (
                "Search"
              )}
            </Button>
          </Box>

          <Form
            empId={employee?.empId}
            loan={loan || 0}
            setEmployee={setEmployee}
            setLoan={setLoan}
          ></Form>
        </Grid>
        <Grid item xs="11" sm="11" md="10" lg="6.6">
          {employee ? (
            <EmployeeDetails
              details={employee}
              isLoanDetails={true}
              loan={loan}
            ></EmployeeDetails>
          ) : (
            <Card
              sx={{
                padding: "6.5rem 4rem",
                position: "relative",
                top: "16%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                Please Search an Employee !!
              </Typography>
              <img
                src={empty}
                alt=""
                style={{
                  height: "150px",
                  width: "150px",
                  pointerEvents: "none",
                }}
              />
            </Card>
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default EmployeeLoan;
