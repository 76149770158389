import { Card, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import logo from "assets/logo.jpeg";

import Tooltip from "@mui/material/Tooltip";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Print } from "@mui/icons-material";
import MDButton from "components/MDButton";

function PaySlip({ data, salary }) {
  const jan = new Date().getMonth() === 0 ? true : false;
  const apr = new Date().getMonth() === 3 ? true : false;

  const exportPdf = () => {
    if (data?.empId) {
      html2canvas(document.querySelector(`#invoice`)).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");

        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "in",
          format: [14, 10],
        });

        pdf.addImage(dataURL, "PNG", 0.6, 0.6);
        pdf.save(`${data?.employeeName}.pdf`);
      });
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        padding: "2rem",
        // marginTop: "1rem",
      }}
    >
      <div id="invoice">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title="Print" placement="top">
            <Print
              tooltip="print invoice"
              fontSize="small"
              sx={{
                cursor: "pointer",
                color: "#3C96EE",
                "&:hover": {
                  color: "#409AEF",
                },
              }}
              onClick={exportPdf}
            />
          </Tooltip>
        </Box>
        <Grid
          container
          sx={{
            width: "100%",
            marginTop: "1rem",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              //   justifyContent: "space-evenly",
              alignItems: "center",
              height: "75px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                // flex: 1,
                justifySelf: "flex-start",
                marginRight: "auto",
                border: "1px solid black",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem",
                width: "20%",
                borderBottom: "none",

                borderRight: "none",
              }}
            >
              <img
                src={logo}
                alt=""
                style={{ height: "70px", width: "70px" }}
              />
            </Box>
            <Box
              sx={{
                // flex: 2,
                width: "100%",
                height: "100%",
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "0.5rem",
                justifyContent: "center",
                padding: "1rem",
                borderBottom: "none",

                borderRight: "none",
              }}
            >
              <Box>
                <Typography variant="h6" textAlign={"center"}>
                  S.H. CONSTRUCTIONS & CO
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" textAlign={"center"}>
                  PAY SLIP
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                // flex: 1,
                justifySelf: "flex-end",
                marginLeft: "auto",
                border: "1px solid black",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
                padding: "1rem",
                width: "30%",

                borderBottom: "none",
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  fontSize={"14px"}
                  fontWeight={"bold"}
                >
                  {new Date().toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              width: "100%",
              gap: "4rem",
              padding: "16px 2rem",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "7px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Pay Period{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {new Date().toLocaleDateString()}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Emp ID.{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {data?.empId}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Name{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {data?.employeeName}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Designation{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {data?.employeeDesignation}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Cost Center{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {"--"}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Pay Currency{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {"INR"}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "7px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  No. of Working Days{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {data?.presentDaysNum || "--"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Balance Leave{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {salary?.leaveCalculation}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  PF No.{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {data?.pfNo}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  ESIC No.{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {data?.esicNo}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              width: "100%",
              //   gap: "4rem",
              border: "1px solid black",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                width: "50%",

                padding: "10px 2rem",
                height: "100%",
                borderRight: "1px solid black",
              }}
            >
              <Typography variant="h6" textAlign="center">
                Salary/Allowances
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  //   borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Basic Salary{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {salary?.basicSalary === undefined
                    ? salary?.basicSalary
                    : salary?.basicSalary.toFixed(2)}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  //   borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  General Allowance{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {"--"}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  //   borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Overtime{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {salary?.overtimeTotal || "--"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  //   borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Incentives{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {data?.incentive}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                width: "50%",
                height: "100%",
                padding: "10px 2rem",
              }}
            >
              <Typography variant="h6" textAlign="center">
                Deductions
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  //   borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  PF{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {salary?.pfCalculation}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  //   borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  ESIC{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {salary?.esiCalculation}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  //   borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Advance{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {data?.monthlyDeduction}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              width: "100%",
              border: "1px solid black",
              borderTop: "none",
              borderBottom: "none",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                width: "50%",
                height: "100%",
                padding: "5px 2rem",
                borderRight: "1px solid black",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  //   borderBottom: "1px solid darkgray",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  Total:{" "}
                </Typography>
                <Typography variant="caption" fontWeight="bold" fontSize="12px">
                  {salary?.totalSalary === undefined
                    ? salary?.totalSalary
                    : salary?.totalSalary.toFixed(2)}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                width: "50%",
                height: "100%",
                padding: "5px 2rem",
              }}
            >
              {!jan && !apr && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    //   borderBottom: "1px solid darkgray",
                    paddingBottom: "3px",
                  }}
                >
                  <Typography
                    variant="caption"
                    fontWeight="bold"
                    fontSize="12px"
                  >
                    Total (Jan - Dec){" "}
                  </Typography>
                  <Typography
                    variant="caption"
                    fontWeight="bold"
                    fontSize="12px"
                  >
                    : &#8377;{" "}
                    {salary?.leaveCalculation === undefined
                      ? salary?.leaveCalculation
                      : salary?.leaveCalculation.toFixed(2)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid
            item
            sx={{
              height: "30px",
              width: "100%",
              border: "1px solid black",
              borderBottom: "none",
            }}
          ></Grid>

          <Grid
            item
            sx={{
              height: "30px",
              width: "100%",
              border: "1px solid black",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px 2rem",

              //   borderBottom: "1px solid darkgray",
            }}
          >
            <Typography variant="caption" fontWeight="bold" fontSize="12px">
              Net Pay:{" "}
            </Typography>
            <Typography variant="caption" fontWeight="bold" fontSize="12px">
              {salary?.inHandSalary === undefined
                ? salary?.inHandSalary
                : salary?.inHandSalary.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Box
        fullWidth
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Box
          sx={{
            width: "50%",
          }}
        >
          <Typography variant="h6">Thank You!</Typography>
          <p
            style={{
              fontSize: "12px",
            }}
          >
            If you encounter any issues related
            <br />
            to the invoice you can contact uss
          </p>
        </Box>
        <Box>
          <MDButton
            variant="gradient"
            color="info"
            disabled={!salary?.totalSalary}
            sx={{
              padding: "0.2rem !important",
              width: "20%",
              fontSize: "10px !important",
              cursor: "pointer",
            }}
            onClick={() => window.location.reload()}
          >
            Done
          </MDButton>
        </Box>
      </Box>
    </Card>
  );
}

export default PaySlip;
