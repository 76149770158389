import { Box, Card, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import loader from "../../../assets/loadingWhite.svg";

import { ToastContainer, toast } from "react-toastify";

function Form({ empId, loan, setLoan, setEmployee }) {
  const [loading, setLoading] = useState(false);

  const notify = () =>
    toast.success("Advance Approved Successfully!!", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const [formDetails, setFormDetails] = useState({
    date: new Date().toLocaleDateString(),
    monthlyDeduction: "",
    advance: "",
  });
  const handleDate = (d) => {
    const date = new Date(d);
    console.log(date.toLocaleDateString());
    setFormDetails({
      ...formDetails,
      date: date.toLocaleDateString(),
    });
  };
  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value))
      setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    console.log("Submitted");
    console.log(formDetails);
    formDetails.empId = empId;
    formDetails.monthlyDeduction = parseInt(formDetails.monthlyDeduction);
    formDetails.advance = parseInt(formDetails.advance);

    axios({
      method: "post", //you can set what request you want to be
      url: `${process.env.REACT_APP_BASE_URL}/advance/create`,
      data: JSON.stringify(formDetails),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        console.log(data);
        notify();
        setLoading(false);
        setEmployee(null);
        setLoan(0);
        setFormDetails({
          date: new Date().toLocaleDateString(),
          monthlyDeduction: "",
          advance: "",
        });
      })
      .catch((err) => console.log(err.response.data));
  };

  useEffect(() => {
    console.log("loan", loan);
    return () => {};
  }, [empId, loan]);

  return (
    <>
      <Card
        sx={
          {
            // height:"100vh"
          }
        }
      >
        <Box sx={{ fontSize: "14px" }}>
          <ToastContainer />
        </Box>
        <Grid
          container
          p={2}
          py={5}
          sx={{
            gap: "2rem",
            flexDirection: "column",
            width: { md: "100%" },
            height: "100%",
          }}
        >
          <Grid
            item
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box>
              <Typography variant="h5">Advance Status</Typography>
              <Typography
                variant="subtitle2"
                color="#344767"
                fontWeight={"bold"}
                fontSize={"14px"}
              >
                Emp ID: {empId || "search an employee"}
              </Typography>
            </Box>
            {
              <Box>
                <Typography variant="h6" color="green">
                  Curr. Advance Amt: <br /> Rs {loan}{" "}
                </Typography>
              </Box>
            }
          </Grid>

          <Grid
            item
            component={"form"}
            sx={{
              gap: "2rem",
              flexDirection: "column",
            }}
          >
            <Box fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                <DatePicker
                  label="Advance Date"
                  inputFormat="DD/MM/YYYY"
                  defaultValue={new Date()}
                  value={formDetails?.date || new Date()}
                  name="date"
                  onChange={handleDate}
                  renderInput={(params) => <TextField {...params} />}
                  fullWidth
                />
              </LocalizationProvider>
            </Box>
            <Box my={2}>
              <TextField
                id="outlined-basic"
                name="advance"
                onChange={handleChange}
                label="Enter Advance Amount"
                variant="outlined"
                fullWidth
                value={formDetails.advance}
              />
            </Box>
            <Box my={2}>
              <TextField
                id="outlined-basic"
                name="monthlyDeduction"
                onChange={handleChange}
                label="Amt. Deducted Per Month"
                variant="outlined"
                fullWidth
                value={formDetails.monthlyDeduction}
              />
            </Box>

            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={handleSubmit}
              disabled={
                (formDetails.advance && formDetails.monthlyDeduction) == "" ||
                empId == undefined
              }
            >
              {loading ? (
                <img
                  src={loader}
                  style={{ width: "20px", height: "20px" }}
                ></img>
              ) : (
                "Submit"
              )}
            </MDButton>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default Form;
