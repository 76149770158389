import * as Yup from "yup";
const validationSchemaPersonal = Yup.object().shape({
  employeeName: Yup.string()
    .min(3)
    .max(30)
    .required("Name cannot be left blank"),
  employeeTypeId: Yup.string().required("Employee Type cannot be left blank"),
  gender: Yup.string().required("Gender cannot be left blank"),
  adharNo: Yup.string()
    .matches(/^[2-9]{1}[0-9]{11}$/, "Invalid Aadhar number")
    .required("Aadhar number is required"),
  panNo: Yup.string()
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Invalid PAN number"),
  qualification: Yup.string().required("Qualification cannot be left blank"),
    W: Yup.string()
    .matches(/^[6-9]\d{9}$/, 'Contact number must be a 10-digit number starting with 6, 7, 8, or 9')
    .required('Contact number is required'),
  emergencyContact: Yup.string()
    .test('is-different', 'Emergency contact number must be different from contact number', function (value) {
      return value !== this.resolve(Yup.ref('contact'), this.parent);
    })
    .matches(/^[6-9]\d{9}$/, 'Emergency contact number must be a 10-digit number starting with 6, 7, 8, or 9'),
  email: Yup.string().email('Invalid email address'),
  workExp: Yup.number()
    .nullable()
    .test('not-three-digits-or-longer', 'Working exp must not be three digits or longer', (value) => {
        // Validate if the value is not three digits or longer
        return !/^\d{3,}$/.test(value);
    }),
});

export default validationSchemaPersonal;