import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import { Field, FormikProvider, useFormik } from "formik";
import validationSchemaForm from "../validationSchema/ValidationSchemaForm";
import jsPDF from "jspdf";
import axios from "axios";

function PayslipForm() {
  const [monthAndYear, setMonthAndYear] = useState({
    month: "",
    year: "",
  });

  const initialValues = monthAndYear;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaForm,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 3;
  const endYear = currentYear;

  const years = Array.from(
    { length: 2030 - 2020 + 1 },
    (_, index) => 2020 + index
  );

  const [employees, setEmployees] = useState(null);
  console.log(employees);
  const [apiError, setApiError] = useState("");
  const [isData, setIsData] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const getData = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/salary/getDated?dated=${monthAndYear.year}-${monthAndYear.month}`
      )
      .then((res) => {
        console.log(res);
        setEmployees(res.data.salaries);
        setApiError("");
        setIsData(true);
      })
      .catch((error) => {
        setApiError("Something went wrong!!");
        setIsData(false);
      });
  };
  const handleExport = () => {
    getData();
  };
  if (isData) {
    console.log(employees);
    console.log(employees.length);
    if (employees.length != 0) {
      const doc = new jsPDF("p", "mm", "a4");

      const employeesPerPage = 4;
      const pages = Math.ceil(employees.length / employeesPerPage);
      console.log(pages);

      for (let i = 0; i < pages; i++) {
        if (i > 0) {
          doc.addPage();
        }

        const start = i * employeesPerPage;
        const end = start + employeesPerPage;
        const payslips = employees?.slice(start, end);

        payslips?.forEach((employee, index) => {
          const yPos = 25 + index * 70;
          const xPos = 15;
          const payslipWidth = 180;
          const payslipHeight = 50;

          // Draw payslip box
          doc.setLineWidth(0.5);
          doc.rect(xPos, yPos, payslipWidth, payslipHeight);

          // Add principal vendor heading
          doc.setFontSize(9);
          doc.setFont("helvetica", "bold");
          const vendorText = `Principal Vendor: TATA STEEL LTD`;
          const vendorTextWidth = doc.getTextWidth(vendorText);
          const vendorTextXPos = (payslipWidth - vendorTextWidth) / 2;
          doc.text(vendorText, xPos + vendorTextXPos, yPos + 3);

          doc.setFontSize(14);
          doc.setFont("helvetica", "bold");
          const vendorText2 = `S.H Construction CO.`;
          const vendorTextWidth2 = doc.getTextWidth(vendorText2);
          const vendorTextXPos2 = (payslipWidth - vendorTextWidth2) / 2;
          doc.text(vendorText2, xPos + vendorTextXPos2, yPos + 8);

          doc.setFontSize(8);
          doc.setFont("helvetica", "normal");
          const vendorText3 = `G.S Road Jugsalai Jsr 831006`;
          const vendorTextWidth3 = doc.getTextWidth(vendorText3);
          const vendorTextXPos3 = (payslipWidth - vendorTextWidth3) / 2;
          doc.text(vendorText3, xPos + vendorTextXPos3, yPos + 12);

          // Add employee details
          doc.setFont("helvetica", "normal");
          doc.setFontSize(9);
          doc.text(`Employee Id: ${employee.empId}`, xPos + 5, yPos + 10);
          doc.text(
            `Employee Name: ${employee.employeeName}`,
            xPos + 5,
            yPos + 15
          );
          doc.text(
            `Designation: ${employee.employeeDesignation}`,
            xPos + 5,
            yPos + 20
          );
          doc.text(`UAN No: ${employee.pfuanNo}`, xPos + 5, yPos + 25);
          doc.text(`ESIC No: ${employee.esicNo}`, xPos + 5, yPos + 30);
          doc.text(`Account No: ${employee.accountNo}`, xPos + 5, yPos + 35);
          doc.text(`Bank Name: ${employee.bankName}`, xPos + 5, yPos + 40);
          doc.text(`IFSC No: ${employee.ifscCode}`, xPos + 5, yPos + 45);

          //Form no and month
          const dateObj = new Date(employee.salary_date);
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const monthName = monthNames[monthAndYear.month - 1];
          const year = monthAndYear.year;
          console.log(year);
          console.log(monthName);

          doc.text(`Form. no: XIX`, xPos + 135, yPos + 5);
          doc.text(`Month: ${monthName},${year}`, xPos + 135, yPos + 10);
          const employeeTypes = ["663.66", "579.38", "436.14"];
          const type = employeeTypes[employee.employeeTypeId - 1];
          doc.text(`Employee Rate: ${type}`, xPos + 135, yPos + 15);

          //Add working Information
          doc.setFont("helvetica", "normal");
          doc.text(
            `Working days: ${employee.working_days}`,
            xPos + 75,
            yPos + 30
          );
          doc.text(`Gross: ${employee.basic_amount}`, xPos + 75, yPos + 35);
          doc.text(`PF: ${employee.pf.toFixed(2)}`, xPos + 75, yPos + 40);
          doc.text(`ESI: ${employee.esi.toFixed(2)}`, xPos + 75, yPos + 45);

          // Add salary information
          doc.setFont("helvetica", "bold");
          doc.text(`Advance: ${employee.advance}`, xPos + 135, yPos + 40);
          doc.text(
            `Net Salary: ${employee.gross_amount}`,
            xPos + 135,
            yPos + 45
          );
        });
      }

      doc.save("payslips.pdf");
      setIsData(false);
      setErrorMsg(null);
    } else {
      setErrorMsg("No data found for current month and year");
      setIsData(false);
    }
  }
  return (
    <Box px={2} py={3}>
      <Typography variant="h5">Employee Payslip</Typography>
      <Grid container py={3} sx={{ justifyContent: "space-between" }}>
        <Grid item xs={12} sm={12} md={3.2} sx={{ marginInlineEnd: 8 }}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Select Month</InputLabel>
            <FormikProvider value={formik}>
              <Field
                as={Select}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={monthAndYear.month}
                label="Select Month"
                onChange={(e) => {
                  setMonthAndYear({
                    ...monthAndYear,
                    [e.target.name]: e.target.value,
                  });
                  handleChange(e);
                }}
                name="month"
                p={2}
                sx={{
                  height: "35px",
                }}
                error={touched.month && errors.month}
              >
                {months.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.name}
                  </MenuItem>
                ))}
              </Field>
              {errors.month && touched.month && (
                <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                  {errors.month}
                </FormHelperText>
              )}
            </FormikProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={3.2}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Select Year</InputLabel>
            <FormikProvider value={formik}>
              <Field
                as={Select}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={monthAndYear.year}
                label="Select Year"
                onChange={(e) => {
                  setMonthAndYear({
                    ...monthAndYear,
                    [e.target.name]: e.target.value,
                  });
                  handleChange(e);
                }}
                name="year"
                p={2}
                sx={{
                  height: "35px",
                }}
                error={touched.year && errors.year}
              >
                {years.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Field>
              {errors.year && touched.year && (
                <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                  {errors.year}
                </FormHelperText>
              )}
            </FormikProvider>
          </FormControl>
        </Grid>
        <Box sx={{ flex: "1 1 auto" }} />
        <MDButton
          variant="gradient"
          color="info"
          onClick={handleExport}
          sx={{
            color: "white",
            background: "#232526",
            background: "-webkit-linear-gradient(to right, #232526, #414345)",
            background: "linear-gradient(to right, #232526, #414345)",
          }}
        >
          Export
        </MDButton>
        {apiError && (
          <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
            {apiError}
          </FormHelperText>
        )}
      </Grid>
      <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
        {errorMsg}
      </FormHelperText>
    </Box>
  );
}

export default PayslipForm;
