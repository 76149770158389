import { AccountCircle, Edit, Person, Visibility } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Card,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import axios from "axios";

import empty from "../../../assets/search.gif";

import VisibilityIcon from "@mui/icons-material/Visibility";
import loader from "../../../assets/loading.svg";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MaterialReactTable from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmployeesToRedux,
  setEmployeesWithSalary,
} from "redux/actions/employeesActions";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import actions from "redux/actions";

function SalaryHistory() {
  const [search, setSearch] = useState("");
  const [fetching, setFetching] = useState(false);
  const [empType, setEmpType] = useState({});
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [empDetails, setEmpDetails] = useState({
    employeeName: "",
    gatePassNo: "",
    safetyPassNo: "",
    contact: "",
    bankName: "",
    accountNo: "",
    ifsc: "",
  });

  const [salaryDetails, setSalaryDetails] = useState({});

  const [employees, setEmployees] = useState([]);

  const offlineEmployees = useSelector(
    (state) => state.employeesReducer.employees
  );
  const dispatch = useDispatch();

  const [emp, setEmp] = useState({});

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);

  const [fetched, setFetched] = useState(false);
  const setSalary = (data) => setSalaryDetails(data);

  const Employee = ({ name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Person></Person>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="regular">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{id}</MDTypography>
      </MDBox>
    </MDBox>
  );

  let employeesData = [];
  const { sub } = actions;
  const navigate = useNavigate();

  const handleView = (data) => {
    console.log("empId in view", data);
    dispatch(sub(data));
    navigate(`/salary-history/${data?.empId}`);
  };

  const fetchEmployees = (s, l) => {
    axios({
      method: "get", //you can set what request you want to be
      url: `${process.env.REACT_APP_BASE_URL}/employee/all?start=${s}&end=${l}`,
    })
      .then((res) => {
        if (res.data.employees.length == 0) return;
        setEmployees((prev) => prev.concat(res.data.employees));
        console.log(res.data.employees);
        if (true) {
          if (res.data.employees) {
            const employees = res.data.employees;
            employees.map(async (data) => {
              try {
                const res = await axios.get(
                  `${process.env.REACT_APP_BASE_URL}/salary/totalAmount?empId=${data.empId}`
                );

                const employeeData = {
                  ...data,
                  lastDate: "N/A",
                  lastInHandSalary: "N/A",
                };
                employeesData.push(employeeData);
                dispatch(setEmployeesWithSalary(employeesData));
                console.log(res, "Res");
                data.lastDate = res?.data?.data?.lastDate || "N/A";
                data.lastSalary = "N/A";

                setRows((prev) => [
                  ...prev,
                  {
                    name: <Employee name={data.employeeName} />,
                    empId: (
                      <MDTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        {data.empId}
                      </MDTypography>
                    ),
                    type: (
                      <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        {data.employeeType}
                      </MDTypography>
                    ),

                    action: (
                      <MDBox sx={{ fontSize: "20px" }}>
                        <Edit
                          onClick={() => handleDetails(data, true)}
                          sx={{
                            "&:hover": {
                              color: "success.main",
                              cursor: "pointer",
                            },
                          }}
                        />
                      </MDBox>
                    ),
                  },
                ]);
              } catch (err) {
                console.log(err);
                data.lastDate = "N/A";
                data.lastSalary = "N/A";
              }
            });
          }

          //   setRows(emp);
          setSuccess(true);
          // console.log(rows, "rows");
          // console.log("first");
          setFetched(true);

          // recursive call to fetch next 10 employees until no employees are left
          if (res.data.employees.length > 0) fetchEmployees(s + 10, l + 10);
        }
      })
      .catch((err) => {
        console.log(err);
        setSuccess(true);
      });
  };

  const createRows = (employeesData) => {
    setRows([]);
    employeesData.forEach(async (data) => {
      setRows((prev) => createRow(prev, data));

      setSuccess(true);
    });
  };

  const createRow = (prevRows, data) => {
    return [
      ...prevRows,
      {
        name: <Employee name={data.employeeName} />,
        empId: (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="regular"
          >
            {data.empId}
          </MDTypography>
        ),
        type: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            {data.employeeType}
          </MDTypography>
        ),

        action: (
          <MDBox sx={{ fontSize: "20px" }}>
            <VisibilityIcon
              onClick={() => handleView(data)}
              sx={{
                marginRight: "5px",
                "&:hover": {
                  color: "success.main",

                  cursor: "pointer",
                },
              }}
            />
          </MDBox>
        ),
      },
    ];
  };

  const handleDetails = (data, t) => {
    console.log(data);
    setEmp(data);
    setOpen(true);
    console.log("inside open");
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    console.log(offlineEmployees, "offline employees");
    if (offlineEmployees.length > 0) {
      setEmployees(offlineEmployees);
      createRows(offlineEmployees);
      setFetched(true);
      return;
    }
    fetchEmployees(0, 10);
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!success && !fetched && (
        <Box
          sx={{
            marginBottom: "3rem",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            paddingRight: "30px",
          }}
        >
          <img
            src={loader}
            alt="loader"
            style={{ width: "35px", height: "35px" }}
          />
        </Box>
      )}
      {success && (
        <MaterialTable
          columns={[
            {
              title: "Name",
              field: "name",
              customFilterAndSearch: (term, rowData) =>
                rowData.name.props.name
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) > -1,
            },
            {
              title: "Emp_Id",
              field: "empId",
              render: (rowData) => <>{rowData.empId}</>,
              customFilterAndSearch: (term, rowData) =>
                rowData.empId.props.children
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) > -1,
            },

            {
              title: "Type",
              field: "type",
            },

            {
              title: "History",
              field: "action",
            },
          ]}
          data={rows}
          onSelectionChange={(selectedRows) => console.log(selectedRows)}
          options={{
            sorting: true,
            search: true,
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: true,
            paging: true,
            pageSizeOptions: [10, 20, 50, 100],
            pageSize: 10,
            paginationType: "stepped",

            paginationPosition: "both",
            exportButton: true,
            exportAllData: true,
            exportFileName: "items",
            addRowPosition: "first",
            actionsColumnIndex: -1,
            showSelectAllCheckbox: false,
            showTextRowsSelected: false,
            selectionProps: (rowData) => ({
              disabled: rowData.age == null,
            }),
            columnsButton: true,
            rowStyle: (data, index) =>
              index % 2 === 0 ? { background: "#f5f5f5" } : null,
            headerStyle: { background: "#376fd0", color: "#fff" },
          }}
          title="Salary History Table"
        />
      )}
    </DashboardLayout>
  );
}

export default SalaryHistory;
