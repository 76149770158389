import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import EmployeeTypes from "layouts/EmployeeTypes";
import CategoryIcon from "@mui/icons-material/Category";

// @mui icons
import Icon from "@mui/material/Icon";
import RegForm from "layouts/registerForm";
import Employees from "layouts/employees";
import LoanForm from "layouts/loans/form";
import LoanTable from "layouts/loans/table";
import SalaryForm from "layouts/salary/calculation";
import SalaryTable from "layouts/salary/table";
import Details from "layouts/salary/table/Details";
import SiteDetails from "layouts/siteDetails";
import SiteDetail from "layouts/siteDetails/Details";
import Payslip from "layouts/payslip/Payslip";
import MasterTable from "layouts/masterTable/MasterTable";
import BankDetails from "layouts/BankDetails/BankDetails";
import SalaryHistory from "layouts/salary/history";
import DifferenceTable from "layouts/salary/differenceTable";
import AttendanceTable from "layouts/attendance";
import AttendanceHistory from "layouts/attendance/AttendanceHistory";
import DailyAttendance from "layouts/attendance/DailyAttendance";
import Signup from "layouts/attendance/Signup";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  {
    type: "collapse",
    name: "Employees Table",
    key: "employeesTable",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/employeesTable",
    component: <Employees />,
  },
  {
    type: "collapse",
    name: "Registration",
    key: "registration",
    icon: <Icon fontSize="small">how_to_reg</Icon>,
    route: "/registration",
    component: <RegForm />,
  },
  {
    type: "collapse",
    name: "Salary Calculation",
    key: "salary-calculation",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/salary-calculation",
    component: <SalaryForm />,
  },
  {
    type: "collapse",
    name: "Salary Sheet",
    key: "salary-table",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/salary-table",
    component: <SalaryTable />,
  },
  {
    type: "collapse",
    name: "Master Roll Sheet",
    key: "master-table",
    icon: <Icon fontSize="small">pivot_table_chart</Icon>,
    route: "/master-table",
    component: <MasterTable />,
  },
  {
    type: "collapse",
    name: "Payslip",
    key: "payslip",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/payslip",
    component: <Payslip />,
  },
  {
    type: "collapse",
    name: "PF/ESI Sheet",
    key: "bank-details",
    icon: <Icon fontSize="small">grid_view</Icon>,
    route: "/bank-details",
    component: <BankDetails />,
  },
  {
    type: "collapse",
    name: "Difference Table",
    key: "difference-table",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/difference-table",
    component: <DifferenceTable />,
  },
  {
    type: "collapse",
    name: "Salary History",
    key: "salary-history",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/salary-history",
    component: <SalaryHistory />,
  },
  {
    type: "collapse",
    name: "Attendance Sheet",
    key: "attendance-sheet",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/attendance-sheet",
    component: <AttendanceTable />,
  },
  {
    type: "collapse",
    name: "Daily Attendance",
    key: "daily-attendance",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/daily-attendance",
    component: <DailyAttendance />,
  },
  {
    type: "collapse",
    name: "Sign-up Attendance",
    key: "sign-up-attendance",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-up-attendance",
    component: <Signup />,
  },
  {
    type: "collapse",
    name: "Advance Form",
    key: "advance-Form",
    icon: <Icon fontSize="small">account_balance_wallet</Icon>,
    route: "/advance-Form",
    component: <LoanForm />,
  },
  {
    type: "collapse",
    name: "Advance Table",
    key: "advance-Table",
    icon: <Icon fontSize="small">grid_on</Icon>,
    route: "/advance-Table",
    component: <LoanTable />,
  },
  {
    type: "collapse",
    name: "Site Details",
    key: "site-details",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/site-details",
    component: <SiteDetails />,
  },

  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },

  {
    type: "collapse",
    name: "Employee Types",
    key: "employee-types",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/employee-types",
    component: <EmployeeTypes />,
  },
  {
    type: "collapse",
    name: "Site Detail",
    key: "sd2",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/site-details/:site",
    component: <SiteDetail />,
  },
  {
    type: "collapse",
    name: "Salary Details",
    key: "sd",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/salary-history/:empId",
    component: <Details />,
  },

  {
    type: "collapse",
    name: "Attendance History",
    key: "ah",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/attendance-history/:empId",
    component: <AttendanceHistory />,
  },

  // {
  //   id: "Salary-Details",
  //   path: "/salary-table/:empId",
  //   component: <Details/>,
  // },
  // Put sign-in always at the end of the routes array
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/",
    component: <SignIn />,
  },
];

export default routes;
