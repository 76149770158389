import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Card, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import PayslipForm from "./form/form";

function Payslip() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Grid my={4}>
          <Box px={3} sx={{ width: "100%" }}>
            <PayslipForm/>
          </Box>
        </Grid>
      </Card>
    </DashboardLayout>
  );
}

export default Payslip;
