import { SET_EMPLOYEES, SET_EMPLOYEES_WITH_SALARY } from "redux/constants";

export const setEmployeesToRedux = (details) => {
  return {
    type: SET_EMPLOYEES,
    payload: details,
  };
};

export const setEmployeesWithSalary = (details) => {
  return {
    type: SET_EMPLOYEES_WITH_SALARY,
    payload: details,
  };
};
