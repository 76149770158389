import {
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MaterialReactTable from "material-react-table";
import React, { useEffect, useState } from "react";

import loader from "../../assets/loading.svg";
import { Field, FormikProvider, useFormik } from "formik";
import MDButton from "components/MDButton";
import validationSchemaForm from "layouts/payslip/validationSchema/ValidationSchemaForm";

function BankDetails() {
  const [rows, setRows] = useState([]);
  const [success, setSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [err, setErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [monthAndYear, setMonthAndYear] = useState({
    month: "",
    year: "",
  });

  const initialValues = monthAndYear;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaForm,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 10;
  const endYear = currentYear;

  const years = Array.from(
    { length: 2027 - 2019 + 1 },
    (_, index) => 2019 + index
  );

  const [employees, setEmployees] = useState(null);
  console.log(employees);
  const [apiError, setApiError] = useState("");
  const [isData, setIsData] = useState(false);
  const getData = () => {
    setFetched(false);
    setSuccess(false);
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/salary/getDated?dated=${monthAndYear.year}-${monthAndYear.month}`
      )
      .then((res) => {
        console.log(res);
        setEmployees(res.data.salaries);
        setApiError("");
        setIsData(true);

        if (res.data?.salaries) setRows(res.data.salaries);

        //   setRows(emp);
        console.log(res.data?.salaries);
        setSuccess(true);
        setFetched(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setApiError("Something went wrong!!");
        setIsData(false);
        setErr(true);
        setSuccess(true);
        setIsLoading(false);
      });
  };
  const handleExport = () => {
    getData();
  };
  if (isData) {
    setIsData(false);
  }

  const formatTwoDecimal = (value) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) return "";
    return parsedValue.toFixed(2);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          margin: "2rem 0",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  ESI/PF Sheet
                </MDTypography>
              </MDBox>

              <Box px={2} py={3} sx={{ backgroundColor: "#f0f2f5" }}>
                <Grid container py={3} sx={{ justifyContent: "space-between" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3.2}
                    sx={{ marginInlineEnd: 8 }}
                  >
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-label">
                        Select Month
                      </InputLabel>
                      <FormikProvider value={formik}>
                        <Field
                          as={Select}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={monthAndYear.month}
                          label="Select Month"
                          onChange={(e) => {
                            setMonthAndYear({
                              ...monthAndYear,
                              [e.target.name]: e.target.value,
                            });
                            handleChange(e);
                          }}
                          name="month"
                          p={2}
                          sx={{
                            height: "35px",
                          }}
                          error={touched.month && errors.month}
                        >
                          {months.map((category) => (
                            <MenuItem
                              key={category.value}
                              value={category.value}
                            >
                              {category.name}
                            </MenuItem>
                          ))}
                        </Field>
                        {errors.month && touched.month && (
                          <FormHelperText
                            sx={{ color: "red", fontSize: "12px" }}
                          >
                            {errors.month}
                          </FormHelperText>
                        )}
                      </FormikProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3.2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-label">
                        Select Year
                      </InputLabel>
                      <FormikProvider value={formik}>
                        <Field
                          as={Select}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={monthAndYear.year}
                          label="Select Year"
                          onChange={(e) => {
                            setMonthAndYear({
                              ...monthAndYear,
                              [e.target.name]: e.target.value,
                            });
                            handleChange(e);
                          }}
                          name="year"
                          p={2}
                          sx={{
                            height: "35px",
                          }}
                          error={touched.year && errors.year}
                        >
                          {years.map((category) => (
                            <MenuItem key={category} value={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Field>
                        {errors.year && touched.year && (
                          <FormHelperText
                            sx={{ color: "red", fontSize: "12px" }}
                          >
                            {errors.year}
                          </FormHelperText>
                        )}
                      </FormikProvider>
                    </FormControl>
                  </Grid>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleExport}
                    sx={{
                      color: "white",
                      background: "#232526",
                      background:
                        "-webkit-linear-gradient(to right, #232526, #414345)",
                      background: "linear-gradient(to right, #232526, #414345)",
                    }}
                  >
                    GET DATA
                  </MDButton>
                  {apiError && (
                    <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                      {apiError}
                    </FormHelperText>
                  )}
                </Grid>
              </Box>

              {!success && (
                <Box
                  sx={{
                    margin: "2rem 0",
                    display: "flex",
                    justifyContent: "center",
                    pt: 2,
                    paddingRight: "30px",
                  }}
                >
                  {/* <img
                        src={loader}
                        alt="loader"
                        style={{ width: "35px", height: "35px" }}
                      /> */}
                  Please give month and year to see the data
                </Box>
              )}

              {isLoading && (
                <Box
                  sx={{
                    marginBottom: "3rem",
                    display: "flex",
                    justifyContent: "center",
                    pt: 2,
                    paddingRight: "30px",
                  }}
                >
                  <img
                    src={loader}
                    alt="loader"
                    style={{ width: "35px", height: "35px" }}
                  />
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>

      {success && (
        <MaterialReactTable
          sx={{
            fontSize: "10px !important",
            color: "white !important",
            boxShadow: "none",
          }}
          icons={{
            fontSize: "10px !important",
          }}
          localization={{
            toolbar: {
              searchPlaceholder: "Search",
              fontSize: "10px",
            },
          }}
          options={{
            sorting: true,
            search: true,
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            labelRowsPerPage: "",
            paging: true,
            pageSizeOptions: [],
            pageSize: 10,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            // exportButton: true,
            // exportAllData: true,
            // exportFileName: "items",
            addRowPosition: "first",
            actionsColumnIndex: -1,
            showSelectAllCheckbox: false,
            showTextRowsSelected: false,

            columnsButton: false,
            // headerStyle: { fontSize:"14px",textAlign:"center", background:" #00c6ff" ,
            // background:" -webkit-linear-gradient(to right, #0072ff, #00c6ff)"  ,
            // background: "linear-gradient(to right, #0072ff, #00c6ff)" },

            headerStyle: {
              background: "#1F78E9",
              color: "#fff",
              fontSize: "14px",
              textAlign: "left",
            },
            rowStyle: (data, index) =>
              index % 2 === 0
                ? {
                    background: "#f5f5f5",
                    fontSize: "14px",
                    textAlign: "center",
                  }
                : {
                    background: "white",
                    fontSize: "14px",
                    textAlign: "center",
                  },
          }}
          columns={[
            { header: "Name", accessorKey: "employeeName" },
            { header: "UAN No", accessorKey: "pfuanNo" },
            { header: "ESI No", accessorKey: "esicNo" },
            { header: "Basic Amount", accessorKey: "basic_amount" },
            { header: "PF", accessorKey: "pf" },
            { header: "ESI", accessorKey: "esi" },
          ]}
          data={rows}
          title=" "
        />
      )}
    </DashboardLayout>
  );
}

export default BankDetails;
