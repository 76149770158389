import { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";

import loader from "../../assets/loading.svg";

import { TextField } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const sites = [
  {
    label: "Office",
  },
  {
    label: "B12",
  },

  {
    label: "E30",
  },
  {
    label: "A12",
  },
  {
    label: "B11",
  },
  {
    label: "E28",
  },

  {
    label: "EC01 (Batt 10-11)",
  },
  {
    label: "I11",
  },
  {
    label: "EC02 (Batt 8-9)",
  },
  {
    label: "TC03",
  },
  {
    label: "EC02 (Batt-3)",
  },
  {
    label: "E23 (SP1)",
  },
  {
    label: "E15 (SP2)",
  },
  {
    label: "EC02 (NCNP)",
  },
  {
    label: "12 (Road Mill)",
  },
  {
    label: "E22",
  },
  {
    label: "E13",
  },
  {
    label: "E15 (SP3)",
  },
  {
    label: "B12 (RMBB Office)",
  },
  {
    label: "E17 (RMBB2)",
  },
  {
    label: "E27 (HBF)",
  },
  {
    label: "E27 (GBF)",
  },
  {
    label: "E15 (GBF)",
  },
  {
    label: "TS01 (LD)",
  },
  {
    label: "E24 (MM)",
  },
  {
    label: "E31 (LD2)",
  },
  {
    label: "TS02 (GBF)",
  },
  {
    label: "E15 (SP4)",
  },
];

const Signup = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const [creds, setCreds] = useState({
    userId: "",
    password: "",
    siteLocation: "",
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setCreds({ ...creds, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setError(false);
    setLoading(true);
    setSuccess(false);
    e.preventDefault();
    // console.log(creds);
    const userData = JSON.stringify({
      userId: creds.userId,
      password: creds.password,
      siteLocation: creds.siteLocation,
    });

    console.log(userData);
    axios({
      method: "post", //you can set what request you want to be
      url: `${process.env.REACT_APP_ATTENDANCE_BASE_URL}/auth/signup`,
      data: userData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setSuccess(true);
        setLoading(false);
        console.log(res.data, "User Registered");
        toast.success("Registered Successfully!!", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { color: "white", fontSize: "14px" },
          theme: "colored",
        });
      })
      .catch((err) => {
        setError(err);
        setSuccess(true);
        setLoading(false);
        console.log(err, "Error in Registering User");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <Box
        sx={{
          height: "100%",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            height: "80%",
            width: {
              xs: "100%",
              sm: "100%",
              md: "60%",
              lg: "50%",
              xl: "50%",
            },
            marginTop: {
              xs: "20%",
              sm: "20%",
              md: "5%",
              lg: "5%",
              xl: "5%",
            },
          }}
        >
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Register User for Attendance
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-label" required>
                    Site Location
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={formDetails?.employeeType || temp.employeeType}
                    value={creds?.siteLocation}
                    label="Site Location"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    name="siteLocation"
                    p={2}
                    sx={{
                      height: "35px",
                    }}
                  >
                    {sites.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
              <MDBox mb={2}>
                <TextField
                  id="outlined-basic"
                  label="User Id"
                  variant="outlined"
                  sx={{ width: "100%", fontSize: "16px" }}
                  onChange={handleChange}
                  name="userId"
                />
              </MDBox>
              <MDBox mb={2}>
                <TextField
                  id="outlined-basic"
                  label="Password"
                  type={"password"}
                  variant="outlined"
                  sx={{ width: "100%", fontSize: "16px" }}
                  onChange={handleChange}
                  name="password"
                />
              </MDBox>

              <MDBox display="flex" alignItems="center" ml={-1}>
                {error?.message && (
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    color="text"
                    textAlign="center"
                    sx={{ width: "100%", color: "red" }}
                  >
                    {error?.response?.data?.message ||
                      "Networlk Error! Try again.."}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox
                mt={4}
                mb={1}
                sx={{
                  fontSize: "16px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                  disabled={loading}
                  style={{ height: "40px", color: "white", fontSize: "14px" }}
                >
                  {success ? (
                    "Register"
                  ) : (
                    <img
                      src={loader}
                      style={{ width: "20px", height: "20px" }}
                    ></img>
                  )}
                </Button>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Forgot Password? Please Contact Admin
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Box>
    </DashboardLayout>
  );
};

export default Signup;
