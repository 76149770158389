import * as Yup from "yup";
const validationSchemaCompany = Yup.object().shape({
  // pfuanNo: Yup.string()
  //     .matches(/^[A-Za-z]{3}\d{7}[A-Za-z]{1}$/, 'Invalid PFUAN Number')
  //     .required("PFUAN no is required"),
  // esicNo: Yup.string()
  //     .matches(/^[A-Za-z]{2}\d{5}\/\d{5}$/, 'Invalid ESIC Number'),
  // incentive: Yup
  //     .mixed()
  //     .nullable()
  //     .test('is-zero-or-string', 'This field is required', (value) => {
  //         // Custom test function to handle '0' as a valid value
  //         return value !== 0 && value !== undefined && value !== '';
  //     }),
});

export default validationSchemaCompany;
