import {
  Backdrop,
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import loader from "../../assets/loading.svg";
import { Field, FormikProvider, useFormik } from "formik";
import MDButton from "components/MDButton";
import validationSchemaForm from "layouts/payslip/validationSchema/ValidationSchemaForm";
import MaterialTable from "material-table";

import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSunday,
  isWeekend,
} from "date-fns";
import { Visibility } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import useZustandStore from "redux/zustandStore";

function AttendanceTable() {
  const [rows, setRows] = useState([]);
  const [success, setSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [err, setErr] = useState(false);

  const [monthAndYear, setMonthAndYear] = useState({
    month: "",
    year: "",
  });

  const initialValues = monthAndYear;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaForm,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const years = Array.from(
    { length: 2030 - 2022 + 1 },
    (_, index) => 2022 + index
  );

  const [employees, setEmployees] = useState(null);

  const [apiError, setApiError] = useState("");
  const [isData, setIsData] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [editPassword, setEditPassword] = useState("shcEdit@202");
  const navigate = useNavigate();

  const {
    zustandMonthAndYear,
    setZustandMonthAndYear,
    monthlyAttendanceRecords,
    setMonthlyAttendanceRecords,
  } = useZustandStore();

  const getData = () => {
    setSuccess(false);
    setFetched(false);
    setIsLoading(true);
    setRows([]);
    axios
      .get(
        `${process.env.REACT_APP_ATTENDANCE_BASE_URL}/emp/month?month=${monthAndYear.month}&year=${monthAndYear.year}`
      )
      .then((res) => {
        console.log(res);
        setEmployees(res.data.records);
        setApiError("");
        setIsData(true);

        if (res.data?.records) {
          addActionsToRows(res.data.records);
        }

        //   setRows(emp);
        setSuccess(true);
        setFetched(true);
        setIsLoading(false);
        console.log(res.data?.records);
      })
      .catch((error) => {
        setApiError("Something went wrong!!");
        setIsData(false);
        setErr(true);
        setSuccess(true);

        setIsLoading(false);
      });
  };
  const handleExport = () => {
    getData();
  };
  if (isData) {
    setIsData(false);
  }

  let serialNumberCounter = 1;

  const handleDetails = (employee, open) => {
    console.log(employee, "selected employee");
    navigate(`/attendance-history/${employee.empId}`);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const addActionsToRows = (employees) => {
    // Map through the salaries array and add the 'action' prop to each row
    const rowsWithActions = employees.map((item) => ({
      ...item,
      action: (
        <MDBox sx={{ fontSize: "20px" }}>
          <Visibility
            onClick={() => handleDetails(item, true)}
            sx={{
              "&:hover": {
                color: "green",
                cursor: "pointer",
              },
              zIndex: 3,
            }}
          />
        </MDBox>
      ),
    }));

    // Set the updated rows to the state using setRows
    setRows(rowsWithActions);
    setMonthlyAttendanceRecords(rowsWithActions);
  };

  const columns = [
    {
      title: "Emp_Id",
      field: "empId",
      render: (rowData) => <>{rowData?.empId && rowData.empId.toString()}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.empId.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Name",
      field: "employeeName",
      cellStyle: {
        cellWidth: "200px",
      },
      render: (rowData) => (
        <>{rowData?.employeeName && rowData.employeeName.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.employeeName
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Working Days",
      field: "numDays",
      render: (rowData) => <>{rowData.numDays && rowData.numDays.toString()}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.numDays.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "OT Hrs",
      field: "otHours", // Assuming the field name is "EmployeeTypeId" in the response JSON
      render: (rowData) => {
        return <>{rowData?.otHours && rowData.otHours.toString()}</>;
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.otHours.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Dep Site",
      field: "employeeDeploySite", // Assuming the field name is "EmployeeTypeId" in the response JSON
      render: (rowData) => (
        <>
          {rowData?.employeeDeploySite && rowData.employeeDeploySite.toString()}
        </>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.employeeDeploySite
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Good Attn.",
      field: "goodAttendance",
      render: (rowData) => (
        <>{rowData?.goodAttendance && rowData.goodAttendance.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.goodAttendance
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },

    {
      title: "Action/View More",
      field: "action",
    },
  ];

  useEffect(() => {
    if (
      zustandMonthAndYear?.month &&
      zustandMonthAndYear?.year &&
      monthlyAttendanceRecords?.length > 0
    ) {
      setMonthAndYear({
        month: zustandMonthAndYear.month,
        year: zustandMonthAndYear.year,
      });

      setRows(monthlyAttendanceRecords);
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card
        sx={{
          margin: "2rem 0",
        }}
      >
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Attendance Table
          </MDTypography>
        </MDBox>

        <Box px={2} py={3} sx={{ backgroundColor: "#f0f2f5" }}>
          <Grid container py={3} sx={{ justifyContent: "space-between" }}>
            <Grid item xs={12} sm={12} md={3.2} sx={{ marginInlineEnd: 8 }}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-label">
                  Select Month
                </InputLabel>
                <FormikProvider value={formik}>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={monthAndYear.month}
                    label="Select Month"
                    onChange={(e) => {
                      setMonthAndYear({
                        ...monthAndYear,
                        [e.target.name]: e.target.value,
                      });

                      setZustandMonthAndYear({
                        ...zustandMonthAndYear,
                        [e.target.name]: e.target.value,
                      });

                      handleChange(e);
                    }}
                    name="month"
                    p={2}
                    sx={{
                      height: "35px",
                    }}
                    error={touched.month && errors.month}
                  >
                    {months.map((category) => (
                      <MenuItem key={category.value} value={category.value}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Field>
                  {errors.month && touched.month && (
                    <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                      {errors.month}
                    </FormHelperText>
                  )}
                </FormikProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3.2}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-label">
                  Select Year
                </InputLabel>
                <FormikProvider value={formik}>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={monthAndYear.year}
                    label="Select Year"
                    onChange={(e) => {
                      setMonthAndYear({
                        ...monthAndYear,
                        [e.target.name]: e.target.value,
                      });
                      setZustandMonthAndYear({
                        ...zustandMonthAndYear,
                        [e.target.name]: e.target.value,
                      });
                      handleChange(e);
                    }}
                    name="year"
                    p={2}
                    sx={{
                      height: "35px",
                    }}
                    error={touched.year && errors.year}
                  >
                    {years.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Field>
                  {errors.year && touched.year && (
                    <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                      {errors.year}
                    </FormHelperText>
                  )}
                </FormikProvider>
              </FormControl>
            </Grid>
            <Box sx={{ flex: "1 1 auto" }} />
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleExport}
              sx={{
                color: "white",
                background: "#232526",
                background:
                  "-webkit-linear-gradient(to right, #232526, #414345)",
                background: "linear-gradient(to right, #232526, #414345)",
              }}
            >
              GET DATA
            </MDButton>
            {apiError && (
              <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                {apiError}
              </FormHelperText>
            )}
          </Grid>
        </Box>
        {isloading && (
          <Box
            sx={{
              marginBottom: "3rem",
              display: "flex",
              justifyContent: "center",
              pt: 2,
              paddingRight: "30px",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              src={loader}
              alt="loader"
              style={{ width: "35px", height: "35px" }}
            />
            <Typography variant="caption">Loading, please wait...</Typography>
          </Box>
        )}
      </Card>

      <MaterialTable
        tableH
        muiTableBodyProps={{
          sx: {
            //stripe the rows, make odd rows a darker color
            "& tr:nth-of-type(odd)": {
              backgroundColor: "#f5f5f5",
            },
          },
        }}
        sx={{
          fontSize: "10px !important",
          color: "white !important",
          borderRadius: "20px !important",
          boxShadow: "none",
        }}
        icons={{
          fontSize: "10px !important",
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Search",
            fontSize: "10px",
          },
        }}
        options={{
          sorting: true,
          search: true,
          searchFieldAlignment: "right",
          searchAutoFocus: true,
          searchFieldVariant: "standard",
          filtering: true,
          paging: true,
          pageSizeOptions: [10, 20, 50, 100],
          pageSize: 20,
          paginationType: "stepped",

          paginationPosition: "both",
          exportButton: true,
          exportAllData: true,
          exportFileName:
            "Attendance Table - " +
            (months[monthAndYear.month - 1]?.name || "") +
            " " +
            monthAndYear.year.toString(),
          addRowPosition: "first",
          actionsColumnIndex: -1,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          selectionProps: (rowData) => ({
            disabled: rowData.age == null,
          }),
          columnsButton: true,
          rowStyle: (data, index) =>
            index % 2 === 0
              ? { background: "#f5f5f5", fontSize: "14px" }
              : { fontSize: "14px" },
          headerStyle: { background: "#376fd0", color: "#fff" },
        }}
        columns={columns}
        data={rows}
        title={
          "Monthly Attendance - " +
          (months[monthAndYear.month - 1]?.name || "") +
          " " +
          monthAndYear.year.toString()
        }
        style={{
          borderRadius: "20px !important",
        }}
      />
    </DashboardLayout>
  );
}

export default AttendanceTable;
