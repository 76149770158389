import { Card, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import axios from "axios";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Cross from "@mui/icons-material/Cancel";
import Tick from "@mui/icons-material/CheckBox";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PersonIcon from "@mui/icons-material/Person";

import loader from "../../assets/loading.svg";
import Fade from "@mui/material/Fade";
import { StylesProvider } from "@material-ui/core/styles";
import "./index.css";

// import data from "./tableData";
import Details from "./Details";
import MaterialReactTable from "material-react-table";
import { Delete, Print } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import EditForm from "./EditForm";
import RegForm from "layouts/registerForm";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmployeesToRedux,
  setEmployeesWithSalary,
} from "redux/actions/employeesActions";
import MaterialTable from "material-table";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "red",
  border: "none",
  boxShadow: 24,
  padding: "4rem",
  // p: 4,
};

const Employee = ({ name, id }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <PersonIcon></PersonIcon>
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="regular">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{id}</MDTypography>
    </MDBox>
  </MDBox>
);

const Job = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="caption"
      color="text"
      fontWeight="regular"
    >
      {title}
    </MDTypography>
    <MDTypography variant="caption">{description}</MDTypography>
  </MDBox>
);

function Employees() {
  const columns = [
    {
      title: "Name",
      field: "employeeName",
    },
    {
      title: "Emp_Id",
      field: "empId",
      // width:"0%",
    },

    {
      title: "Type",
      field: "employeeType",
    },

    {
      title: "Contact",
      field: "contact",
    },

    {
      title: "Sfty Pass No",
      field: "safetyPassNo",
    },

    {
      title: "JNTVTI Cert. No",
      field: "jntvtiCertNo",
    },
  ];

  const [rows, setRows] = useState([]);
  const [success, setSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [employees, setEmployees] = useState([]);

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [loadingDelete, setLoadingDelete] = useState(false);
  const dispatch = useDispatch();
  const [isEditLoading, setIsEditLoading] = useState(false);

  const offlineEmployees = useSelector(
    (state) => state.employeesReducer.employees
  );

  const handleDelete = (empId, name) => {
    const response = window.confirm(
      `You want to Delete?? \n Emp Id: ${empId} \n Name: ${name}`
    );
    console.log(response);

    if (response) handleDeleteConfirm(empId);
  };

  function showToast(message, options) {
    return new Promise((resolve, reject) => {
      toast(message, {
        position: "top-right",
        autoClose: options?.autoClose || 3000,
        hideProgressBar: options?.hideProgressBar || false,
        closeOnClick: options?.closeOnClick || true,
        pauseOnHover: options?.pauseOnHover || true,
        draggable: options?.draggable || true,
        progress: undefined,
        theme: "colored",
        onClose: resolve,
        onError: reject,
      });
    });
  }

  const handleDeleteConfirm = (empId) => {
    console.log("Deleting, please wait...");
    setLoadingDelete(true);
    console.log(empId);
    const payload = { empId };

    const deletingToastId = toast.info("Deleting...", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    axios({
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}/employee/delete`,
      data: payload,
    })
      .then((data) => {
        console.log(data);
        setLoadingDelete(false);
        toast.dismiss(deletingToastId); // Remove the "Deleting..." toast
        toast.success("Deleted Successfully!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        setLoadingDelete(false);
        console.log(err);
        toast.error("Error Deleting!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleOpen = () => {
    // setDetails(d)
  };
  const handleClose = () => setOpen(false);
  const handleCloseEdit = () => setOpenEdit(false);
  // const [data, setdata] = useState(initialState);

  const handleDetails = (emp, edit) => {
    console.log("inside click", emp);

    setIsEditLoading(true);

    // console.log("okeyyyy");
    if (edit) setOpenEdit(true);
    else {
      setOpen(true);
      return;
    }

    console.log("Edit", edit);
    console.log(open);

    axios({
      method: "get", //you can set what request you want to be
      url: `${process.env.REACT_APP_BASE_URL}/employee?empId=${emp.empId}`,
    }).then((res) => {
      setDetails(res.data.employees[0]);
      console.log(res.data.employees[0], "details for edit after fetch");
      setIsEditLoading(false);
    });

    // handleOpen();
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    doc.text("Employee Details", 15, 10);
    doc.autoTable({
      theme: "striped",
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: employees,
    });
    doc.save("employeesDetails.pdf");
  };

  let employeesData = [];
  const fetchEmployees = (s, l) => {
    axios({
      method: "get", //you can set what request you want to be
      url: `${process.env.REACT_APP_BASE_URL}/employee/all?start=${s}&end=${l}`,
    })
      .then((res) => {
        if (res.data.employees.length === 0) return;
        // dispatch(setEmployeesToRedux(res.data.employees));
        setEmployees((prev) => prev.concat(res.data.employees));
        console.log(res.data.employees);
        if (true) {
          if (res.data.employees)
            res.data.employees.map(async (data) => {
              // const res = await axios.get(
              //   `${process.env.REACT_APP_BASE_URL}/salary/lastDate?empId=${data.empId}`
              // );

              const employeeData = {
                ...data,
              };

              employeesData.push(employeeData);
              dispatch(setEmployeesWithSalary(employeesData));

              data.lastDate = res?.data?.last_date || "N/A";
              data.lastSalary = "N/A";

              setRows((prev) => [
                ...prev,
                {
                  name: data.employeeName,
                  empId: data.empId,
                  type: data.employeeType,
                  designnation: data.employeeDesignation,
                  contact: data?.contact && data?.contact?.toString(),
                  jntvtiCertNo: data.jntvtiCertNo,
                  accountNo: data.accountNo,

                  // status: (
                  //   <MDBox ml={-1}>
                  //     <MDBadge
                  //       badgeContent="Available"
                  //       color="success"
                  //       variant="gradient"
                  //       size="sm"
                  //     />
                  //   </MDBox>
                  // ),

                  action: (
                    <MDBox sx={{ fontSize: "20px" }}>
                      <VisibilityIcon
                        onClick={() => handleDetails(data.empId)}
                        sx={{
                          marginRight: "5px",
                          "&:hover": {
                            color: "success.main",

                            cursor: "pointer",
                          },
                        }}
                      />
                      <EditIcon
                        onClick={() => handleDetails(data, true)}
                        sx={{
                          "&:hover": {
                            color: "success.main",
                            cursor: "pointer",
                          },
                        }}
                      />

                      <Delete
                        onClick={() =>
                          handleDelete(data.empId, data?.employeeName)
                        }
                        sx={{
                          marginLeft: "30px",
                          "&:hover": {
                            color: "#ED213A",
                            cursor: "pointer",
                          },
                        }}
                      ></Delete>
                    </MDBox>
                  ),
                },
              ]);
              console.log("first inside");
            });

          //   setRows(emp);
          setSuccess(true);
          console.log(rows, "rows");
          console.log("first");
          setFetched(true);

          // recursive call to fetch next 10 employees until no employees are left
          if (res.data.employees.length > 0) fetchEmployees(s + 30, l + 30);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        setSuccess(true);
      });
  };

  const createRow = (prevRows, data) => {
    return [
      ...prevRows,
      {
        name: data.employeeName,
        empId: data.empId,
        type: data.employeeType,
        designnation: data.employeeDesignation,
        contact: data?.contact && data?.contact?.toString(),
        jntvtiCertNo: data.jntvtiCertNo,
        accountNo: data.accountNo,

        // status: (
        //   <MDBox ml={-1}>
        //     <MDBadge
        //       badgeContent="Available"
        //       color="success"
        //       variant="gradient"
        //       size="sm"
        //     />
        //   </MDBox>
        // ),

        action: (
          <MDBox sx={{ fontSize: "20px" }}>
            <VisibilityIcon
              onClick={() => handleDetails(data.empId)}
              sx={{
                marginRight: "5px",
                "&:hover": {
                  color: "success.main",

                  cursor: "pointer",
                },
              }}
            />
            <EditIcon
              onClick={() => handleDetails(data, true)}
              sx={{
                "&:hover": {
                  color: "success.main",
                  cursor: "pointer",
                },
              }}
            />

            <Delete
              onClick={() => handleDelete(data.empId, data?.employeeName)}
              sx={{
                marginLeft: "30px",
                "&:hover": {
                  color: "#ED213A",
                  cursor: "pointer",
                },
              }}
            ></Delete>
          </MDBox>
        ),
      },
    ];
  };

  const createRows = (employeesData) => {
    setRows([]);
    employeesData.forEach(async (data) => {
      setRows((prev) => createRow(prev, data));

      setSuccess(true);
    });
  };
  useEffect(() => {
    console.log("employees", employees);
    if (offlineEmployees.length > 0) {
      createRows(offlineEmployees);
      setFetched(true);
      setSuccess(true);
      return;
    }
    fetchEmployees(0, 30);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <StylesProvider injectFirst>
        <ToastContainer
          style={{
            fontSize: "1rem",
          }}
        />

        <Card>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            sx={{
              border: "none",
              position: "relative",
            }}
          >
            <Details empId={details}></Details>
          </Modal>
          {openEdit && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openEdit}
            >
              {isEditLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                    background: "#f0f2f5",
                    opacity: "0.8",
                    width: "100%",
                    height: "100%",

                    zIndex: "100",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <img
                    src={loader}
                    alt="loader"
                    style={{ width: "35px", height: "35px" }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      color: "black !important",
                      zIndex: "100",
                      fontSize: "1rem",
                    }}
                  >
                    Loading employee details, please wait...
                  </Typography>
                </Box>
              ) : (
                <RegForm
                  empDetails={details}
                  isEdit
                  handleCloseEdit={handleCloseEdit}
                ></RegForm>
              )}
            </Backdrop>
          )}

          {!success && (
            <Box
              sx={{
                marginBottom: "3rem",
                display: "flex",
                justifyContent: "center",
                pt: 2,
                paddingRight: "30px",
              }}
            >
              <img
                src={loader}
                alt="loader"
                style={{ width: "35px", height: "35px" }}
              />
            </Box>
          )}
        </Card>

        {success && (
          <MaterialTable
            columns={[
              {
                title: "Name",
                field: "name",
                render: (rowData) => <>{rowData.name}</>,
                customFilterAndSearch: (term, rowData) =>
                  rowData.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
              },
              {
                title: "Emp_Id",
                field: "empId",
                render: (rowData) => <>{rowData.empId}</>,
                customFilterAndSearch: (term, rowData) =>
                  rowData.empId.toLowerCase().indexOf(term.toLowerCase()) > -1,
              },
              {
                title: "Type",
                field: "type",
                render: (rowData) => <>{rowData.type}</>,
              },
              {
                title: "Designation",
                field: "designnation",
                render: (rowData) => <>{rowData.designnation}</>,
              },
              {
                title: "Contact",
                field: "contact",
                render: (rowData) => <>{rowData.contact}</>,
                customFilterAndSearch: (term, rowData) =>
                  rowData.contact &&
                  rowData.contact
                    .toString()
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1,
              },
              {
                title: "Jntvti Cert No.",
                field: "jntvtiCertNo",
                render: (rowData) => <>{rowData.jntvtiCertNo}</>,
              },
              {
                title: "Acc. No.",
                field: "accountNo",
                render: (rowData) => <>{rowData.accountNo}</>,
              },
              {
                title: "Action",
                field: "action",
              },
            ]}
            data={rows}
            onSelectionChange={(selectedRows) => console.log(selectedRows)}
            options={{
              sorting: true,
              search: true,
              searchFieldAlignment: "right",
              searchAutoFocus: true,
              searchFieldVariant: "standard",
              filtering: true,
              paging: true,
              pageSizeOptions: [10, 20, 50, 100],
              pageSize: 10,
              paginationType: "stepped",

              paginationPosition: "both",
              exportButton: true,
              exportAllData: true,
              exportFileName: "Employees",

              addRowPosition: "first",
              actionsColumnIndex: -1,
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              selectionProps: (rowData) => ({
                disabled: rowData.age == null,
              }),
              columnsButton: true,
              rowStyle: (data, index) =>
                index % 2 === 0
                  ? { background: "#f5f5f5", fontSize: "14px" }
                  : { fontSize: "14px" },
              headerStyle: { background: "#376fd0", color: "#fff" },
            }}
            title="Employees"
          />
        )}
      </StylesProvider>
    </DashboardLayout>
  );
}

export default Employees;
