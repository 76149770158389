import {
  Backdrop,
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import loader from "../../assets/loading.svg";
import { Field, FormikProvider, useFormik } from "formik";
import MDButton from "components/MDButton";
import validationSchemaForm from "layouts/payslip/validationSchema/ValidationSchemaForm";
import MaterialTable from "material-table";

import { Visibility } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment/moment";
import useZustandStore from "redux/zustandStore";

function DailyAttendance() {
  const [rows, setRows] = useState([]);
  const [success, setSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [err, setErr] = useState(false);

  const [searchDate, setSearchDate] = useState();
  const [dummyDate, setDummyDate] = useState();

  const initialValues = searchDate;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaForm,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const [employees, setEmployees] = useState([]);
  const [apiError, setApiError] = useState("");
  const [isData, setIsData] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [editPassword, setEditPassword] = useState("shcEdit@202");
  const navigate = useNavigate();

  const {
    dailyAttendanceRecords,
    setDailyAttendanceRecords,
    dailyDate,
    setDailyDate,
  } = useZustandStore();

  const getData = (s, e) => {
    setSuccess(false);
    setFetched(false);
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_ATTENDANCE_BASE_URL}/emp/bydate?date=${searchDate}&start=${s}&end=${e}`
      )
      .then((res) => {
        console.log(res);
        setEmployees((prev) => prev.concat(res.data.records));

        setApiError("");
        setIsData(true);

        if (res.data?.records) {
          updateDatesOfRows(res.data.records, s, e);
        }

        //   setRows(emp);
        setSuccess(true);
        setFetched(true);
        setIsLoading(false);
        console.log(res.data?.records);
      })
      .catch((error) => {
        setApiError("No Data Found!!");
        setIsData(false);
        setErr(true);
        setSuccess(true);

        setIsLoading(false);
      });
  };
  const handleFetch = () => {
    setRows([]);
    setEmployees([]);
    setDailyAttendanceRecords([]);

    getData(0, 50);
  };
  if (isData) {
    setIsData(false);
  }

  let serialNumberCounter = 1;

  const handleDetails = (employee, open) => {
    console.log(employee, "selected employee");
    navigate(`/attendance-history/${employee.empId}`);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const calculateDay = (work_hours) => {
    if (work_hours) {
      const wh = parseFloat(work_hours);

      if (wh < 3) return 0;
      else if (wh >= 3 && wh < 6) return 0.5;
      else if (wh >= 6 && wh < 8) return 1;
      else return 1;
    } else {
      return "N/A";
    }
  };
  const updateDatesOfRows = (employees, s, e) => {
    const updatedEmployees = employees.map((employee) => ({
      ...employee,
      punch_in:
        employee.punch_in != "N/A"
          ? new Date(employee.punch_in).toLocaleTimeString("en-IN")
          : "N/A",
      punch_out:
        employee.punch_out != "N/A" && employee.punch_out != null
          ? new Date(employee.punch_out).toLocaleTimeString("en-IN")
          : "N/A",
      site_location:
        employee.site_location != "N/A" ? employee.site_location : "N/A",
      work_hours:
        employee.work_hours != "N/A" && employee.work_hours != null
          ? employee.work_hours + " hr"
          : "N/A",
      ot_hours:
        employee.ot_hours != "N/A" && employee.ot_hours != null
          ? employee.ot_hours + " hr"
          : "N/A",
      deploy_site: employee.deploy_site ? employee.deploy_site : "N/A",
    }));

    setDailyAttendanceRecords(updatedEmployees);
    console.log(rows, "prev");
    setRows((prev) => prev.concat(updatedEmployees));
    if (employees.length > 0) getData(s + 50, e + 50);
  };

  const handleDate = (d) => {
    const date = new Date(d);
    setSearchDate(moment(date).format("MM/DD/YYYY"));
    setDummyDate(date);
    setDailyDate(date);
  };

  function toBase64(arr) {
    // arr = new Uint8Array(arr); //if it's an ArrayBuffer
    return btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), "")
    );
  }

  const returnPhoto = (photo) => {
    if (!photo) return;

    if (typeof photo?.data !== "undefined") {
      const Aphoto = photo;
      const stringPhoto = toBase64(Aphoto.data);
      return "data:image/png;base64," + stringPhoto;
    }
  };

  const columns = [
    {
      title: "Emp_Id",
      field: "emp_id",

      render: (rowData) => <>{rowData?.emp_id && rowData.emp_id.toString()}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.emp_id.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Name",
      field: "employee_name",
      cellStyle: {
        cellWidth: "200px",
      },
      render: (rowData) => (
        <>{rowData?.employee_name && rowData.employee_name.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.employee_name
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Site",
      field: "site_location",
      cellStyle: {
        cellWidth: "200px",
      },
      render: (rowData) => (
        <>{rowData?.site_location && rowData.site_location.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.site_location
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },

    {
      title: "Dep. Site",
      field: "deploy_site",
      cellStyle: {
        cellWidth: "150px",
      },
      render: (rowData) => <>{rowData.deploy_site || "N/A"}</>,
    },

    {
      title: "Punch-In",
      field: "punch_in",
      cellStyle: {
        cellWidth: "200px",
      },
      render: (rowData) => <>{rowData?.punch_in || "N/A"}</>,
    },
    {
      title: "Punch-Out",
      field: "punch_out",
      cellStyle: {
        cellWidth: "200px",
      },
      render: (rowData) => <>{rowData.punch_out || "N/A"}</>,
    },
    {
      title: "Work Hrs",
      field: "work_hours",
      cellStyle: {
        cellWidth: "150px",
      },
      render: (rowData) => <>{rowData.work_hours || "N/A"}</>,
    },
    {
      title: "OT Hrs",
      field: "ot_hours",
      cellStyle: {
        cellWidth: "150px",
      },
      render: (rowData) => <>{rowData.ot_hours || "N/A"}</>,
    },

    {
      title: "Photo",
      field: "photo",
      render: (rowData) => (
        <>
          {rowData?.group_photo && (
            <img
              src={returnPhoto(rowData.group_photo)}
              alt="group_photo"
              style={{ width: "70px", height: "70px" }}
            />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (
      dailyAttendanceRecords &&
      dailyAttendanceRecords.length > 0 &&
      dailyDate
    ) {
      setRows(dailyAttendanceRecords);
      setDummyDate(dailyDate);
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card
        sx={{
          margin: "2rem 0",
        }}
      >
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Daily Attendance Table
          </MDTypography>
        </MDBox>

        <Box px={2} py={3} sx={{ backgroundColor: "#f0f2f5" }}>
          <Grid container py={3} sx={{ justifyContent: "space-between" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
              <DatePicker
                label="Attendance Date"
                inputFormat="DD/MM/YYYY"
                defaultValue={new Date()}
                value={dummyDate || new Date()}
                onChange={handleDate}
                renderInput={(params) => <TextField {...params} />}
                fullWidth
              />
            </LocalizationProvider>
            <Box sx={{ flex: "1 1 auto" }} />
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleFetch}
              sx={{
                color: "white",
                background: "#232526",
                background:
                  "-webkit-linear-gradient(to right, #232526, #414345)",
                background: "linear-gradient(to right, #232526, #414345)",
              }}
            >
              GET DATA
            </MDButton>
            {apiError && (
              <FormHelperText
                sx={{
                  color: "red",
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "0.5rem",
                }}
              >
                {apiError}
              </FormHelperText>
            )}
          </Grid>
        </Box>
        {isloading && (
          <Box
            sx={{
              marginBottom: "3rem",
              display: "flex",
              justifyContent: "center",
              pt: 2,
              paddingRight: "30px",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              src={loader}
              alt="loader"
              style={{ width: "35px", height: "35px" }}
            />
            <Typography variant="caption">Loading, please wait...</Typography>
          </Box>
        )}
      </Card>

      <MaterialTable
        tableH
        muiTableBodyProps={{
          sx: {
            //stripe the rows, make odd rows a darker color
            "& tr:nth-of-type(odd)": {
              backgroundColor: "#f5f5f5",
            },
          },
        }}
        sx={{
          fontSize: "10px !important",
          color: "white !important",
          borderRadius: "20px !important",
          boxShadow: "none",
        }}
        icons={{
          fontSize: "10px !important",
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Search",
            fontSize: "10px",
          },
        }}
        options={{
          sorting: true,
          search: true,
          searchFieldAlignment: "right",
          searchAutoFocus: true,
          searchFieldVariant: "standard",
          filtering: true,
          paging: true,
          pageSizeOptions: [10, 20, 50, 100],
          pageSize: 20,
          paginationType: "stepped",

          paginationPosition: "both",
          exportButton: true,
          exportAllData: true,
          exportFileName:
            "Daily Attendance Table - " +
            (dummyDate
              ? moment(dummyDate).format("DD/MM/YYYY")
              : new Date().toLocaleDateString("en-IN")),

          addRowPosition: "first",
          actionsColumnIndex: -1,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          selectionProps: (rowData) => ({
            disabled: rowData.age == null,
          }),
          columnsButton: true,
          rowStyle: (data, index) =>
            index % 2 === 0
              ? { background: "#f5f5f5", fontSize: "14px" }
              : { fontSize: "14px" },
          headerStyle: { background: "#376fd0", color: "#fff" },
        }}
        columns={columns}
        data={rows}
        title={
          "Daily Attendance - " +
          (dummyDate
            ? moment(dummyDate).format("DD/MM/YYYY")
            : new Date().toLocaleDateString("en-IN"))
        }
        style={{
          borderRadius: "20px !important",
        }}
      />
    </DashboardLayout>
  );
}

export default DailyAttendance;
