import {
  Button,
  Card,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import axios from "axios";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";

import loader from "../../../assets/loading.svg";
import Fade from "@mui/material/Fade";
import { StylesProvider } from "@material-ui/core/styles";
import "../../employees/index.css";
import MaterialReactTable from "material-react-table";
import Details from "./Details";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/actions";
import { Link, useNavigate } from "react-router-dom";
import { setEmployeesToRedux } from "redux/actions/employeesActions";
import MDButton from "components/MDButton";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import MaterialTable from "material-table";

function SalaryTable() {
  const [rows, setRows] = useState([]);
  const [success, setSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [employees, setEmployees] = useState([]);

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [start, setStart] = useState(0);
  const [last, setLast] = useState(10);
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [formDetails, setFormDetails] = useState({
    month: "",
    year: "",
  });

  const handleOpen = () => {
    // setDetails(d)
  };
  const handleClose = () => setOpen(false);
  const handleCloseEdit = () => setOpenEdit(false);

  const offlineEmployees = useSelector(
    (state) => state.employeesReducer.employees
  );

  const { sub } = actions;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleView = (data) => {
    console.log("empId in view", data);
    dispatch(sub(data));
    navigate(`/salary-table/${data?.empId}`);
  };

  /*
  const columns = [
    {
      title: "Date",
      field: "date",
      render: (rowData) => <>{rowData.date}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.date.props.children.toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    // {
    //   header: "Designation",
    //   accessorKey: "designation",
    // },
    {
      title: "Emp_Id",
      field: "empId",
      render: (rowData) => <>{rowData.empId}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.empId.props.children.toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Working Days",
      field: "workingDays",
      customFilterAndSearch: (term, rowData) =>
        rowData.workingDays
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Wage Rate",
      field: "dailyWage",
      customFilterAndSearch: (term, rowData) =>
        rowData.dailyWage.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Total Wage",
      field: "totalWage",
      customFilterAndSearch: (term, rowData) =>
        rowData.totalWage.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Other Allowance",
      field: "goodAttendance",
      customFilterAndSearch: (term, rowData) =>
        rowData.goodAttendance
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Total Gross",
      field: "totalGross",
      customFilterAndSearch: (term, rowData) =>
        rowData.totalGross
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "ESI",
      field: "esi",
      customFilterAndSearch: (term, rowData) =>
        rowData.esi.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "PF",
      field: "pf",
      customFilterAndSearch: (term, rowData) =>
        rowData.pf.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Advance Deduc.",
      field: "advance",
      customFilterAndSearch: (term, rowData) =>
        rowData.pf.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Net Salary",
      field: "netSalary",
      customFilterAndSearch: (term, rowData) =>
        rowData.netSalary.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Action",
      field: "action",
      render: (rowData) => <>{rowData.action}</>,
    },
  ];
*/

  function getMonth(monthNumber) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[monthNumber - 1];
  }
  const columns = [
    {
      title: "Date",
      field: "date",
      render: (rowData) => <>{rowData.date}</>,
      export: (rowData) => <>{rowData.date}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.date.props.children.toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    // {
    //   header: "Designation",
    //   accessorKey: "designation",
    // },
    {
      title: "Emp_Id",
      field: "empId",
      render: (rowData) => <>{rowData.empId}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.empId.props.children.toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Name",
      field: "employeeName",
      render: (rowData) => <>{rowData.employeeName}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.employeeName.props.children
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Bank Account",
      field: "accountNo",
      render: (rowData) => <>{rowData.accountNo}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.accountNo.props.children
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: `Salary\n${getMonth(formDetails.month)} ${formDetails.year}`,
      field: "salary",
      render: (rowData) => <>{rowData.salary}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.salary.props.children
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
  ];

  const Employee = ({ name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <PersonIcon></PersonIcon>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="regular">
          {name}
        </MDTypography>
        <MDTypography variant="subtitle2" fontSize="12px">
          {id}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="subtitle2"
        fontSize="12px"
        color="text"
        fontWeight="regular"
      >
        {title}
      </MDTypography>
      <MDTypography variant="subtitle2" fontSize="12px">
        {description}
      </MDTypography>
    </MDBox>
  );

  const createRow = (prevRows, data) => {
    return [
      ...prevRows,
      {
        date: data?.salary_date,

        empId: data?.empId,

        employeeName: data?.employeeName && data?.employeeName.toString(),
        accountNo: data?.accountNo && data?.accountNo.toString(),
        salary: data?.grand_total && data?.grand_total.toFixed(2).toString(),
      },
    ];
  };

  const createRows = (employeesData) => {
    setRows([]);
    employeesData.forEach(async (data) => {
      setRows((prev) => createRow(prev, data));

      setSuccess(true);
    });
  };

  const handleChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    setSuccess(false);
    e.preventDefault();
    if (!formDetails.month && !formDetails.year) {
      return;
    }
    const queryString = `${formDetails.year}-${formDetails.month}`;
    console.log(queryString);

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/salary/getDated?dated=${queryString}`
      )
      .then((res) => {
        console.log(res.data);
        setEmployees(res.data.salaries);
        createRows(res.data.salaries);
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err.response.data);
        setSuccess(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportToPDF = () => {
    const input = document.getElementById("divId"); // Replace 'divId' with the ID of your div element

    html2canvas(input)
      .then((canvas) => {
        const pdf = new jsPDF();
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210; // Width of the PDF document (A4 size)
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("document.pdf");
      })
      .catch((error) => {
        console.log("Error exporting PDF:", error);
      });
  };

  useEffect(() => {}, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {showDetails ? (
        <Details details={details}></Details>
      ) : (
        <StylesProvider injectFirst>
          <div id="divId">
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                mb: 5,
                flexDirection: { xs: "column", md: "row" },
                margin: "2rem 0",
              }}
            >
              <Box
                my={1}
                sx={{
                  width: {
                    xs: "100%",
                    md: "25%",
                    lg: "25%",
                  },
                }}
                key="1"
              >
                <TextField
                  id="outlined-basic"
                  name="month"
                  onChange={handleChange}
                  label="Month"
                  variant="outlined"
                  select
                  required
                  fullWidth
                  InputProps={{
                    style: {
                      height: "45px", // Custom height styling
                    },
                  }}
                  value={formDetails.month}
                >
                  <MenuItem value={"1"}>January</MenuItem>
                  <MenuItem value={"2"}>February</MenuItem>
                  <MenuItem value={"3"}>March</MenuItem>
                  <MenuItem value={"4"}>April</MenuItem>
                  <MenuItem value={"5"}>May</MenuItem>
                  <MenuItem value={"6"}>June</MenuItem>
                  <MenuItem value={"7"}>July</MenuItem>
                  <MenuItem value={"8"}>August</MenuItem>
                  <MenuItem value={"9"}>September</MenuItem>
                  <MenuItem value={"10"}>October</MenuItem>
                  <MenuItem value={"11"}>November</MenuItem>
                  <MenuItem value={"12"}>December</MenuItem>
                </TextField>
              </Box>
              <Box
                my={1}
                sx={{
                  width: {
                    xs: "100%",
                    md: "25%",
                    lg: "25%",
                  },
                }}
                key="2"
              >
                <TextField
                  id="outlined-basic"
                  name="year"
                  onChange={handleChange}
                  label="Year"
                  variant="outlined"
                  select
                  required
                  fullWidth
                  InputProps={{
                    style: {
                      height: "45px", // Custom height styling
                    },
                  }}
                  value={formDetails.year}
                >
                  <MenuItem value={"2021"}>2021</MenuItem>
                  <MenuItem value={"2022"}>2022</MenuItem>

                  <MenuItem value={"2023"}>2023</MenuItem>
                  <MenuItem value={"2024"}>2024</MenuItem>
                  <MenuItem value={"2025"}>2025</MenuItem>
                  <MenuItem value={"2026"}>2026</MenuItem>
                  <MenuItem value={"2027"}>2027</MenuItem>
                  <MenuItem value={"2028"}>2028</MenuItem>
                  <MenuItem value={"2029"}>2029</MenuItem>
                  <MenuItem value={"2030"}>2030</MenuItem>
                </TextField>
              </Box>
              <Button
                variant="gradient"
                color="info"
                disabled={formDetails.month && formDetails.year ? false : true}
                onClick={handleSubmit}
                sx={{
                  color: "white",
                  marginLeft: "1rem",
                  border: "1px solid gray",
                  transition: "all 0.1s ease-in-out",
                  padding: "0.8rem 2rem",
                  "&:hover": {
                    color: "white !important",
                    background: "#232526",
                    background:
                      "-webkit-linear-gradient(to right, #232526, #414345)",
                    background: "linear-gradient(to right, #232526, #414345)",
                  },
                }}
              >
                {loading ? (
                  <img
                    src={loader}
                    style={{ width: "20px", height: "20px" }}
                  ></img>
                ) : (
                  "Search"
                )}
              </Button>
            </Box>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Salary Table
                </MDTypography>
              </MDBox>
              {!success && loading && (
                <Box
                  sx={{
                    marginBottom: "3rem",
                    display: "flex",
                    justifyContent: "center",
                    pt: 2,
                    paddingRight: "30px",
                  }}
                >
                  <img
                    src={loader}
                    alt="loader"
                    style={{ width: "35px", height: "35px" }}
                  />
                </Box>
              )}
              {/* {success && (
                        <MaterialReactTable
                          tableH
                          muiTableBodyProps={{
                            sx: {
                              //stripe the rows, make odd rows a darker color
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: "#f5f5f5",
                              },
                            },
                          }}
                          sx={{
                            fontSize: "10px !important",
                            color: "white !important",
                          }}
                          icons={{
                            fontSize: "10px !important",
                          }}
                          localization={{
                            toolbar: {
                              searchPlaceholder: "Search",
                              fontSize: "10px",
                            },
                          }}
                          options={{
                            sorting: true,
                            search: true,
                            searchFieldAlignment: "right",
                            searchAutoFocus: true,
                            searchFieldVariant: "standard",
                            filtering: true,
                            labelRowsPerPage: "",
                            paging: true,
                            pageSizeOptions: [],
                            pageSize: 10,
                            paginationType: "stepped",
                            showFirstLastPageButtons: false,
                            // exportButton: true,
                            // exportAllData: true,
                            // exportFileName: "items",
                            addRowPosition: "first",
                            actionsColumnIndex: -1,
                            showSelectAllCheckbox: false,
                            showTextRowsSelected: false,
                            columnsButton: false,
                            rowStyle: (data, index) =>
                              index % 2 === 0
                                ? { background: "#f5f5f5" }
                                : null,
                            headerStyle: {
                              background: "red",
                              color: "#fff",
                              fontSize: "14px",
                            },
                          }}
                          columns={columns}
                          data={rows}
                          title=" "
                          enableColumnFilters
                          enableFilters
                          enableColumnFilterModes
                          enableGlobalFilter
                        />
                      )} */}
              {/* {!success && (
        <Box
          sx={{
            marginBottom: "3rem",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            paddingRight: "30px",
          }}
        >
          <img
            src={loader}
            alt="loader"
            style={{ width: "35px", height: "35px" }}
          />
        </Box>
      )} */}
            </Card>

            {
              <MaterialTable
                tableH
                muiTableBodyProps={{
                  sx: {
                    //stripe the rows, make odd rows a darker color
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#f5f5f5",
                    },
                  },
                }}
                sx={{
                  fontSize: "10px !important",
                  color: "white !important",
                  borderRadius: "20px !important",
                }}
                icons={{
                  fontSize: "10px !important",
                }}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Search",
                    fontSize: "10px",
                  },
                }}
                options={{
                  sorting: true,
                  search: true,
                  searchFieldAlignment: "right",
                  searchAutoFocus: true,
                  searchFieldVariant: "standard",
                  filtering: true,
                  paging: true,
                  pageSizeOptions: [10, 20, 50, 100],
                  pageSize: 10,
                  paginationType: "stepped",

                  paginationPosition: "both",
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: `Salary Sheet - ${getMonth(
                    formDetails.month
                  )} ${formDetails.year}`,
                  addRowPosition: "first",
                  actionsColumnIndex: -1,
                  showSelectAllCheckbox: false,
                  showTextRowsSelected: false,
                  selectionProps: (rowData) => ({
                    disabled: rowData.age == null,
                  }),
                  columnsButton: true,
                  rowStyle: (data, index) =>
                    index % 2 === 0
                      ? { background: "#f5f5f5", fontSize: "14px" }
                      : { fontSize: "14px" },
                  headerStyle: {
                    background: "#376fd0",
                    color: "#fff",
                  },
                }}
                columns={columns}
                data={rows}
                title=" "
                enableColumnFilters
                enableFilters
                enableColumnFilterModes
                enableGlobalFilter
                style={{
                  borderRadius: "20px !important",
                }}
              />
            }
          </div>
        </StylesProvider>
      )}
    </DashboardLayout>
  );
}

export default SalaryTable;
