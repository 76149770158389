export const LOGIN = "LOGIN";
export const EMPLOYEE_TYPES_FORM = "EMPLOYEE_TYPES_FORM ";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const SALARY_DETAILS = "SALARY_DETAILS";
export const SITE = "SITE";
export const RESET = "RESET";

export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const SET_EMPLOYEES_WITH_SALARY = "SET_EMPLOYEES_WITH_SALARY";

// types.js

export const FETCH_EMPLOYEES_REQUEST = "FETCH_EMPLOYEES_REQUEST";
export const FETCH_EMPLOYEES_SUCCESS = "FETCH_EMPLOYEES_SUCCESS";
export const FETCH_EMPLOYEES_FAILURE = "FETCH_EMPLOYEES_FAILURE";

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
