import { Box, Card, Grid, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MaterialReactTable from "material-react-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import loader from "../../../assets/loading.svg";
import MDTypography from "components/MDTypography";
import MaterialTable from "material-table";

function Details() {
  const [salary, setSalary] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [history, setHistory] = useState([]); // [
  const [employee, setEmployee] = useState();
  const [image, setImage] = useState(
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu1ie-UGlSW8lxja48eVV_WZoQtPgDISzkPKAf308qWiCGHfnDaNiB6Fb2c10zMEBVX8w&usqp=CAU"
  );

  const jan = new Date().getMonth() === 0 ? true : false;
  const apr = new Date().getMonth() === 3 ? true : false;

  const details = useSelector((state) => state.salaryDetails.empDetails);
  console.log(details, "details");
  // let { empId } = useParams();

  const columns = [
    {
      title: "Date",
      field: "date",
      render: (rowData) => <>{rowData.date}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.date.props.children.toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    // {
    //   header: "Designation",
    //   accessorKey: "designation",
    // },
    {
      title: "Emp_Id",
      field: "empId",
      render: (rowData) => <>{rowData.empId}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.empId.props.children.toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Working Days",
      field: "workingDays",
      customFilterAndSearch: (term, rowData) =>
        rowData.workingDays
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "OT Hours",
      field: "otHours",
      customFilterAndSearch: (term, rowData) =>
        rowData.otHours.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },

    {
      title: "OT Amount",
      field: "otAmount",
      customFilterAndSearch: (term, rowData) =>
        rowData.otAmount.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Total Wage",
      field: "basicAmount",
      customFilterAndSearch: (term, rowData) =>
        rowData.totalWage.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Other Allowance",
      field: "goodAttendance",
      customFilterAndSearch: (term, rowData) =>
        rowData.goodAttendance
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Total Gross",
      field: "totalGross",
      customFilterAndSearch: (term, rowData) =>
        rowData.totalGross
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "ESI",
      field: "esi",
      customFilterAndSearch: (term, rowData) =>
        rowData.esi.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "PF",
      field: "pf",
      customFilterAndSearch: (term, rowData) =>
        rowData.pf.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Advance Deduc.",
      field: "advance",
      customFilterAndSearch: (term, rowData) =>
        rowData.pf.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Extra Salary",
      field: "extraSalary",
      customFilterAndSearch: (term, rowData) =>
        rowData.actualRate.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Net Salary",
      field: "netSalary",
      customFilterAndSearch: (term, rowData) =>
        rowData.netSalary.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Total Salary",
      field: "totalSalary",
      customFilterAndSearch: (term, rowData) =>
        rowData.netSalary.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
  ];

  const createRow = (prevRows, data) => {
    return [
      ...prevRows,
      {
        date: data?.salary_date,
        empId: data?.empId,

        workingDays: data?.working_days && data?.working_days.toString(),
        otHours: `${data?.ot_hours && data?.ot_hours.toString()} hr`,
        otAmount: data?.ot_amount && data?.ot_amount.toString(),

        basicAmount: data?.basic_amount && data?.basic_amount.toString(),
        goodAttendance:
          (data?.good_attendance && data?.good_attendance.toString()) ||
          (data?.good_attendence && data?.good_attendence?.toString()) ||
          "0",
        extraSalary:
          (data?.extra_salary && data?.extra_salary.toString()) ||
          (data?.extra_salary && data?.extra_salary?.toString()) ||
          "0",

        totalGross:
          data?.gross_amount && data?.gross_amount?.toFixed()?.toString(),
        esi: data?.esi && data?.esi?.toFixed(2)?.toString(),
        pf: data?.pf && data?.pf?.toFixed(2)?.toString(),
        advance: data?.advance ? data?.advance?.toFixed(2)?.toString() : "0",
        netSalary: data?.net_salary && data?.net_salary?.toFixed()?.toString(),
        totalSalary:
          data?.total_salary && data?.total_salary?.toFixed()?.toString(),

        action: <MDBox sx={{ fontSize: "20px" }}></MDBox>,
      },
    ];
  };

  const createRows = (employeesData) => {
    setRows([]);
    employeesData.forEach(async (data) => {
      setRows((prev) => createRow(prev, data));

      setSuccess(true);
    });
  };

  const getSalaryHistroy = async () => {
    console.log("fetching");
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/salary/getSalaryHistory?empId=${details.empId}`
      )
      .then((res) => {
        if (res?.data?.salaries) {
          //  / setSalary(res.data.salaryHistory);
          console.log(res.data.salaries, "salaryHistory");
          setHistory(res.data.salaries);
          createRows(res.data.salaries);
          setSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setSuccess(false);
    setLoading(true);
    setEmployee(details);
    getSalaryHistroy();
  }, [details]);
  // useEffect(() => {
  //   setLoading(true);
  //   if (details?.empId) {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_BASE_URL}/salary/all?empId=${details.empId}`
  //       )
  //       .then((res) => {
  //         if (res.data?.salaries) {
  //           res.data.salaries.map((item) => {
  //             // console.log(new Date(item.date).getMonth())
  //             const month = new Date(item.date).getMonth();
  //             const salary =
  //               month === 0
  //                 ? item.janInHandSalary
  //                 : month === 3
  //                 ? item.aprilInHandSalary
  //                 : item.inHandSalary;
  //             const lb =
  //               month === 0
  //                 ? item.leaveCalculation
  //                 : month === 3
  //                 ? item.bonusCalculation
  //                 : "N/A".leaveBonus;

  //             const esi = item?.esiCalculation?.toFixed(2);
  //             setRows((prev) => [
  //               ...prev,
  //               {
  //                 date: item.date,
  //                 incentive: item.incentive || "N/A",
  //                 otHours: item.otHours || "N/A",
  //                 monthlyDeduction: item.monthlyDeduction || "N/A",
  //                 pfCalculation: item.pfCalculation || "N/A",
  //                 esiCalculation: esi || "N/A",
  //                 inHandSalary: salary?.toFixed(2) || "N/A",
  //                 leaveBonus: lb?.toFixed(2) || "N/A",
  //               },
  //             ]);
  //           });
  //           setLoading(false);
  //           // console.log("rows",rows)
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar></DashboardNavbar>
      <Card
        sx={{
          padding: "1rem",
          marginBottom: "2rem",
        }}
      >
        <Typography variant="h5">Employee Details</Typography>
        <Grid
          container
          // justifyContent={""}
          spacing={{ xs: 2, sm: 3, md: 5 }}
          padding="1rem 0rem"
        >
          <>
            <Grid
              item
              sx={{
                width: "150px",
              }}
            >
              <img
                style={{
                  height: "100px",
                  width: "100px",
                }}
                src={image}
                alt=""
              />
            </Grid>
            <Grid item display="flex" flexDirection="column" gap="5px">
              <Typography variant="caption" fontSize="14px" fontWeight="bold">
                Name : {employee?.employeeName}
              </Typography>
              <Typography variant="caption" fontSize="14px" fontWeight="bold">
                Emp ID. : {employee?.empId}
              </Typography>
              <Typography variant="caption" fontSize="14px" fontWeight="bold">
                Designation : {employee?.employeeDesignation}
              </Typography>
              <Typography variant="caption" fontSize="14px" fontWeight="bold">
                Safety Pass No.: {employee?.safetyPassNo}
              </Typography>
              <Typography variant="caption" fontSize="14px" fontWeight="bold">
                Contact No. : {employee?.contact}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="column" gap="5px">
              <Typography variant="caption" fontSize="14px" fontWeight="bold">
                Bank : {employee?.bankName}
              </Typography>
              <Typography variant="caption" fontSize="14px" fontWeight="bold">
                Account No. : {employee?.accountNo}
              </Typography>
              <Typography variant="caption" fontSize="14px" fontWeight="bold">
                IFSC Code: {employee?.ifscCode}
              </Typography>
            </Grid>
          </>
        </Grid>
      </Card>
      {/* <Box sx={{ margin: "1rem 0" }}>
        {success ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="account_balance"
                  title="Total Bonus Amt"
                  count={
                    (salary?.totalBonusAmount == undefined
                      ? salary?.totalBonusAmount
                      : salary?.totalBonusAmount.toFixed(2)) || 0
                  }
                  percentage={{
                    color: "success",
                    amount: "Jan - Dec",
                    label: "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="payments"
                  title="Total Leave Amt"
                  count={
                    (salary?.totalLeaveAmount == undefined
                      ? salary?.totalLeaveAmount
                      : salary?.totalLeaveAmount.toFixed(2)) || 0
                  }
                  percentage={{
                    color: "success",
                    amount: "Apr - Apr",
                    label: "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="credit_score"
                  title="Total PF Amt"
                  count={
                    (salary?.totalPF == undefined
                      ? salary?.totalPF
                      : salary?.totalPF.toFixed(2)) || 0
                  }
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="credit_score"
                  title="Total ESI Amt"
                  count={
                    (salary?.totalESI == undefined
                      ? salary?.totalESI
                      : salary?.totalESI.toFixed(2)) || 0
                  }
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              marginBottom: "3rem",
              display: "flex",
              justifyContent: "center",
              pt: 2,
              paddingRight: "30px",
            }}
          >
            <img
              src={loader}
              alt="loader"
              style={{ width: "35px", height: "35px" }}
            />
          </Box>
        )}
      </Box> */}
      {!success && (
        <Box
          sx={{
            marginBottom: "3rem",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            paddingRight: "30px",
          }}
        >
          <img
            src={loader}
            alt="loader"
            style={{ width: "35px", height: "35px" }}
          />
        </Box>
      )}
      {success && (
        <MaterialTable
          tableH
          muiTableBodyProps={{
            sx: {
              //stripe the rows, make odd rows a darker color
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#f5f5f5",
              },
            },
          }}
          sx={{
            fontSize: "10px !important",
            color: "white !important",
            borderRadius: "20px !important",
          }}
          icons={{
            fontSize: "10px !important",
          }}
          localization={{
            toolbar: {
              searchPlaceholder: "Search",
              fontSize: "10px",
            },
          }}
          options={{
            sorting: true,
            search: true,
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: true,
            labelRowsPerPage: "",
            paging: true,
            pageSizeOptions: [],
            pageSize: 10,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            exportButton: true,
            exportAllData: true,
            exportFileName: `Salary History: ${employee?.employeeName} - ${employee?.empId}`,
            addRowPosition: "first",
            actionsColumnIndex: -1,
            showSelectAllCheckbox: false,
            showTextRowsSelected: false,
            columnsButton: false,
            rowStyle: (data, index) =>
              index % 2 === 0
                ? { background: "#f5f5f5", fontSize: "14px" }
                : { fontSize: "14px" },
            headerStyle: {
              background: "#3D3D44",
              color: "#fff",
              fontSize: "14px",
            },
          }}
          columns={columns}
          data={rows}
          title="Salary History"
          enableColumnFilters
          enableFilters
          enableColumnFilterModes
          enableGlobalFilter
          style={{
            borderRadius: "20px !important",
          }}
        />
      )}
    </DashboardLayout>
  );
}

export default Details;
