import { CloudUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/actions";
import validationSchemaCompany from "./validationSchema/CompanyValidationSchema";
import { Field, FormikProvider, useFormik } from "formik";

function CompanyDetails({
  editForm,
  back,
  next,
  step,
  formDetails,
  setFormDetails,
}) {
  const categories = ["Platinum", "Gold", "Silver"];
  const designations = [
    { id: 1, val: "Prop" },
    { id: 2, val: "General Manager" },
    { id: 3, val: "Management Representative" },
    { id: 4, val: "Manager" },
    { id: 5, val: "Site Incharge" },
    { id: 6, val: "Supervisor" },
    { id: 7, val: "Fitter" },
    { id: 8, val: "Gas cutter welder" },
    { id: 9, val: "Reger" },
    { id: 10, val: "Helper" },
    { id: 11, val: "Safety Incharge" },
    { id: 12, val: "Site Safety Officer" },
    { id: 13, val: "Safety Incharge" },
    { id: 14, val: "Store Incharge" },
    { id: 15, val: "Store Keeper" },
    { id: 16, val: "DCIC" },
    { id: 17, val: "Driver" },
  ];

  const otType = [1, 1.5, 2];
  const payment_type = ["Actual", "Fixed"];

  const workingHours = [8, 12];
  const sites = [
    "COKE PLANT",
    "RMM",
    "RMBB",
    "RMBB2",
    "GBF",
    "SP#3,4",
    "SP#1,2",
    "MM",
    "LD#1",
    "PP",
    "MRSPP",
    "LINE PLANT",
  ];

  const [error, setError] = useState(false);

  const myForm = React.useRef(null);

  const initialValues = formDetails;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaCompany,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const dispatch = useDispatch();
  const [uploads, setUploads] = useState({
    policeVerificationCertPhoto: false,
    jntvtiCertPhoto: false,
  });

  const storeFormData = useSelector((state) => state.regForm.details);

  const checkValidity = () => {
    console.log(formDetails, "formDetails in CompanyDetails");
    if (!myForm.current.checkValidity()) {
      setError(true);
      return;
    }
    setFormDetails({ ...formDetails });
    dispatch(actions.editRegForm(formDetails));
    next();
  };

  const handleInput = (e) => {
    if (e.target.name === "incentive") {
      const re = /^[0-9\b]+$/;

      if (e.target.value === "" || re.test(e.target.value)) {
        const value = parseInt(e.target.value);
        setFormDetails({ ...formDetails, [e.target.name]: value });
      }
    } else {
      setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
    }
  };
  const handleFileInput = (e) => {
    console.log(e.target.files[0]);

    setFormDetails({ ...formDetails, [e.target.name]: e.target.files[0] });
    if (e.target.files.length > 0)
      setUploads({ ...uploads, [e.target.name]: true });
  };

  useEffect(() => {
    if (storeFormData) {
      setFormDetails(storeFormData);
    }
  }, []);

  return (
    <Box px={2} py={3}>
      <form ref={myForm}>
        {error && (
          <Typography variant="caption" sx={{ color: "red", fontSize: "14px" }}>
            *Please fill all the necessary fields
          </Typography>
        )}
        <Typography variant="h5">Company Details</Typography>
        <Grid container py={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="PF No."
              name="pfNo"
              variant="standard"
              value={formDetails?.pfNo ? formDetails.pfNo : ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              onBlur={handleBlur}
              error={touched.pfNo && !!errors.pfNo}
              helperText={touched.pfNo && errors.pfNo}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              required
              id="standard-basic"
              label="PFUAN No."
              name="pfuanNo"
              variant="standard"
              value={formDetails?.pfuanNo ? formDetails.pfuanNo : ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              onBlur={handleBlur}
              error={touched.pfuanNo && !!errors.pfuanNo}
              helperText={touched.pfuanNo && errors.pfuanNo}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="ESIC No."
              name="esicNo"
              variant="standard"
              value={formDetails?.esicNo ? formDetails.esicNo : ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              onBlur={handleBlur}
              error={touched.esicNo && !!errors.esicNo}
              helperText={touched.esicNo && errors.esicNo}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container py={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="Incentive"
              name="incentive"
              variant="standard"
              value={formDetails?.incentive || ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              onBlur={handleBlur}
              error={touched.incentive && !!errors.incentive}
              helperText={touched.incentive && errors.incentive}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="Safety Pass No."
              name="safetyPassNo"
              value={formDetails?.safetyPassNo ? formDetails.safetyPassNo : ""}
              variant="standard"
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              onBlur={handleBlur}
              error={touched.safetyPassNo && !!errors.safetyPassNo}
              helperText={touched.safetyPassNo && errors.safetyPassNo}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="JNTVTI Cert No."
              name="jntvtiCertNo"
              variant="standard"
              value={formDetails?.jntvtiCertNo ? formDetails.jntvtiCertNo : ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              onBlur={handleBlur}
              error={touched.jntvtiCertNo && !!errors.jntvtiCertNo}
              helperText={touched.jntvtiCertNo && errors.jntvtiCertNo}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container py={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={3.2}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                JNTVTI Category
              </InputLabel>
              <FormikProvider value={formik}>
                <Field
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    formDetails?.jntvtiCategory
                      ? formDetails.jntvtiCategory
                      : ""
                  }
                  label="JNTVTI Category"
                  onChange={(event) => {
                    handleChange(event);
                    handleInput(event);
                  }}
                  name="jntvtiCategory"
                  p={2}
                  sx={{
                    height: "35px",
                  }}
                  error={touched.jntvtiCategory && errors.jntvtiCategory}
                >
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Field>
                {errors.jntvtiCategory && touched.jntvtiCategory && (
                  <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                    {errors.jntvtiCategory}
                  </FormHelperText>
                )}
              </FormikProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Emp. Designation
              </InputLabel>
              <FormikProvider value={formik}>
                <Field
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    formDetails?.employeeDesignation
                      ? formDetails.employeeDesignation
                      : ""
                  }
                  label="Emp. Designation"
                  onChange={(event) => {
                    handleChange(event);
                    handleInput(event);
                  }}
                  name="employeeDesignation"
                  p={2}
                  sx={{
                    height: "35px",
                  }}
                  error={
                    touched.employeeDesignation && errors.employeeDesignation
                  }
                >
                  {designations.map((category) => (
                    <MenuItem key={category.id} value={category.val}>
                      {category.val}
                    </MenuItem>
                  ))}
                </Field>
                {errors.employeeDesignation && touched.employeeDesignation && (
                  <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                    {errors.employeeDesignation}
                  </FormHelperText>
                )}
              </FormikProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            {/* <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Emp. Deploy Site
              </InputLabel>
              <FormikProvider value={formik}>
                <Field
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    formDetails?.employeeDeploySite
                      ? formDetails.employeeDeploySite
                      : ""
                  }
                  label="Emp. Deploy Site"
                  onChange={(event) => {
                    handleChange(event);
                    handleInput(event);
                  }}
                  name="employeeDeploySite"
                  p={2}
                  sx={{
                    height: "35px",
                  }}
                  error={
                    touched.employeeDeploySite && errors.employeeDeploySite
                  }
                >
                  {sites.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Field>
                {errors.employeeDeploySite && touched.employeeDeploySite && (
                  <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                    {errors.employeeDeploySite}
                  </FormHelperText>
                )}
              </FormikProvider>
            </FormControl> */}

            <TextField
              id="standard-basic"
              label="Emp. Deploy Site"
              name="employeeDeploySite"
              variant="standard"
              value={formDetails?.employeeDeploySite || ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              fullWidth
              onBlur={handleBlur}
              // error={touched.employeeDeploySite && !!errors.employeeDeploySite}
              // helperText={touched.actualRate && errors.actualRate}
            />
          </Grid>
        </Grid>
        <Grid container py={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={3.2} sx={{ marginBottom: 5 }}>
            <TextField
              id="standard-basic"
              label="Actual amount"
              name="actualRate"
              variant="standard"
              value={formDetails?.actualRate || ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              fullWidth
              onBlur={handleBlur}
              error={touched.actualRate && !!errors.actualRate}
              helperText={touched.actualRate && errors.actualRate}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Payment Type
              </InputLabel>
              <FormikProvider value={formik}>
                <Field
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    formDetails?.payment_type ? formDetails.payment_type : ""
                  }
                  onChange={(event) => {
                    handleChange(event);
                    handleInput(event);
                  }}
                  name="payment_type"
                  p={2}
                  sx={{
                    height: "35px",
                  }}
                  error={touched.payment_type && errors.payment_type}
                >
                  {payment_type.map((ot) => (
                    <MenuItem key={ot} value={ot}>
                      {ot}
                    </MenuItem>
                  ))}
                </Field>
                {errors.payment_type && touched.payment_type && (
                  <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                    {errors.payment_type}
                  </FormHelperText>
                )}
              </FormikProvider>
            </FormControl>
          </Grid>
          {formDetails?.payment_type === "Fixed" && (
            <Grid item xs={12} sm={12} md={3.2} sx={{ marginBottom: 6 }}>
              <TextField
                id="standard-basic"
                label="Fixed Salary"
                name="fixed_rate"
                variant="standard"
                value={formDetails?.fixed_rate || ""}
                onChange={(event) => {
                  handleChange(event);
                  handleInput(event);
                }}
                fullWidth
                onBlur={handleBlur}
                error={touched.fixed_rate && !!errors.fixed_rate}
                helperText={touched.fixed_rate && errors.fixed_rate}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={3.2}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">OT Type</InputLabel>
              <FormikProvider value={formik}>
                <Field
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formDetails?.otType ? formDetails.otType : ""}
                  onChange={(event) => {
                    handleChange(event);
                    handleInput(event);
                  }}
                  name="otType"
                  p={2}
                  sx={{
                    height: "35px",
                  }}
                  error={touched.otType && errors.otType}
                >
                  {otType.map((ot) => (
                    <MenuItem key={ot} value={ot}>
                      {ot}
                    </MenuItem>
                  ))}
                </Field>
                {errors.otType && touched.otType && (
                  <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                    {errors.otType}
                  </FormHelperText>
                )}
              </FormikProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Working Hours
              </InputLabel>
              <FormikProvider value={formik}>
                <Field
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    formDetails?.workingHours ? formDetails.workingHours : ""
                  }
                  label="Working Hours"
                  onChange={(event) => {
                    handleChange(event);
                    handleInput(event);
                  }}
                  name="workingHours"
                  p={2}
                  sx={{
                    height: "35px",
                  }}
                  error={touched.workingHours && errors.workingHours}
                >
                  {workingHours.map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </Field>
                {errors.workingHours && touched.workingHours && (
                  <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                    {errors.workingHours}
                  </FormHelperText>
                )}
              </FormikProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          py={3}
          sx={{
            justifyContent: "center",
            gap: "1rem",
            flexDirection: "row",
          }}
        >
          <Grid item xs={12} sm={12} md={3} sx={{ justifyContent: "center" }}>
            <Button
              variant="standard"
              component="label"
              sx={
                uploads.jntvtiCertPhoto
                  ? {
                      border: "1px solid #348DED",
                      color: "white !important",
                      backgroundColor: "#348DED !important",
                      textTransform: "none",
                    }
                  : {
                      border: "1px solid #348DED",
                      color: "#348DED",
                      textTransform: "none",
                    }
              }
            >
              <CloudUpload
                sx={{ marginRight: "4px", fontSize: "20px !important" }}
              ></CloudUpload>{" "}
              Upload JNTVTI Cft. Photo
              <input
                type="file"
                hidden
                accept="image"
                name="jntvtiCertPhoto"
                onChange={handleFileInput}
              />
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={3.2} sx={{ justifyContent: "center" }}>
            <Button
              variant="standard"
              component="label"
              sx={
                uploads.policeVerificationCertPhoto
                  ? {
                      border: "1px solid #348DED",
                      color: "white !important",
                      backgroundColor: "#348DED !important",
                      textTransform: "none",
                    }
                  : {
                      border: "1px solid #348DED",
                      color: "#348DED",
                      textTransform: "none",
                    }
              }
            >
              <CloudUpload
                sx={{ marginRight: "4px", fontSize: "20px !important" }}
              ></CloudUpload>{" "}
              Upload Police Verif Cft. Photo
              <input
                type="file"
                hidden
                accept="image"
                name="policeVerificationCertPhoto"
                onChange={handleFileInput}
              />
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <MDButton
            disabled={step === 0}
            variant="gradient"
            color="info"
            onClick={back}
            sx={{
              mr: "1",
              color: "white",
              background: "#232526",
              background: "-webkit-linear-gradient(to right, #232526, #414345)",
              background: "linear-gradient(to right, #232526, #414345)",
            }}
          >
            Back
          </MDButton>
          <Box sx={{ flex: "1 1 auto" }} />

          <MDButton
            variant="gradient"
            color="info"
            onClick={checkValidity}
            sx={{
              color: "white",
              //   background: "#232526",
              //   background: "-webkit-linear-gradient(to right, #232526, #414345)",
              //   background: "linear-gradient(to right, #232526, #414345)",
            }}
          >
            {step === 3 - 1 ? "Submit" : "Next"}
          </MDButton>
        </Box>
      </form>
    </Box>
  );
}

export default CompanyDetails;
