import { AccountCircle, Edit, Person, Visibility } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Form from "./Form";
import Details from "./Details";
import axios from "axios";

import empty from "../../../assets/search.gif";

import loader from "../../../assets/loading.svg";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MaterialReactTable from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmployeesToRedux,
  setEmployeesWithSalary,
} from "redux/actions/employeesActions";
import MaterialTable from "material-table";
import useZustandStore from "redux/zustandStore";
import { Field, FormikProvider, useFormik } from "formik";
import validationSchemaForm from "layouts/payslip/validationSchema/ValidationSchemaForm";
import MDButton from "components/MDButton";

const months = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
];

const years = Array.from(
  { length: 2030 - 2021 + 1 },
  (_, index) => 2021 + index
);

function Salary() {
  const [search, setSearch] = useState("");
  const [fetching, setFetching] = useState(false);
  const [empType, setEmpType] = useState({});
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [empDetails, setEmpDetails] = useState({
    employeeName: "",
    gatePassNo: "",
    safetyPassNo: "",
    contact: "",
    bankName: "",
    accountNo: "",
    ifsc: "",
  });

  const [salaryDetails, setSalaryDetails] = useState({});

  const [employees, setEmployees] = useState([]);

  const offlineEmployees = useSelector(
    (state) => state.employeesReducer.employees
  );
  const dispatch = useDispatch();

  const [emp, setEmp] = useState({});

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);

  const {
    employeeLastMonthAttendance,
    setEmployeeLastMonthAttendance,
    setAttendanceMonthAndYear,
    attendanceMonthAndYear,
  } = useZustandStore();

  const [fetched, setFetched] = useState(false);

  const [monthAndYear, setMonthAndYear] = useState({
    month: attendanceMonthAndYear?.month || "",
    year: attendanceMonthAndYear?.year || "",
  });

  const [isloading, setIsLoading] = useState(false);

  const initialValues = monthAndYear;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaForm,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const setSalary = (data) => setSalaryDetails(data);

  const Employee = ({ name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Person></Person>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="regular">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{id}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const searchEmployee = () => {
    setSuccess(false);
    setFetching(true);
    console.log("searching");

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/employee?empId=${search}`)
      .then((res) => {
        setEmpDetails(res.data.employee);

        setFetching(false);
        setError(false);
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err.response);
        setError("Employee not found");
        setFetching(false);
      });

    // axios({
    //   method: "get", //you can set what request you want to be
    //   url: `${process.env.REACT_APP_BASE_URL}/employee?empId=${search}`,
    // })
    //   .then((res) => {
    //     console.log(res.data.employee);
    //     setEmployee(res.data.employee);
    //     setError(false);
    //     //   window.location.reload();
    //   })
  };

  let employeesData = [];

  const fetchEmployees = (s, l) => {
    axios({
      method: "get", //you can set what request you want to be
      url: `${process.env.REACT_APP_BASE_URL}/employee/all?start=${s}&end=${l}`,
    })
      .then((res) => {
        if (res.data.employees.length == 0) return;
        setEmployees((prev) => prev.concat(res.data.employees));
        console.log(res.data.employees);
        if (true) {
          if (res.data.employees) {
            const employees = res.data.employees;
            employees.map(async (data) => {
              try {
                const res = await axios.get(
                  `${process.env.REACT_APP_BASE_URL}/salary/lastDate?empId=${data.empId}`
                );

                const employeeData = {
                  ...data,
                  lastDate: res?.data?.last_date || "N/A",
                  lastInHandSalary: "N/A",
                };
                employeesData.push(employeeData);
                dispatch(setEmployeesWithSalary(employeesData));
                // console.log(res, "Res");
                data.lastDate = res?.data?.last_date || "N/A";
                data.lastSalary = "N/A";

                setRows((prev) => [
                  ...prev,
                  {
                    name: <Employee name={data.employeeName} />,
                    empId: (
                      <MDTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        {data.empId}
                      </MDTypography>
                    ),
                    type: (
                      <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        {data.employeeType}
                      </MDTypography>
                    ),

                    lastDate: (
                      <Typography
                        variant="caption"
                        color="green"
                        fontWeight="regular"
                      >
                        {data.lastDate}
                      </Typography>
                    ),

                    action: (
                      <MDBox sx={{ fontSize: "20px" }}>
                        <Edit
                          onClick={() => handleDetails(data, true)}
                          sx={{
                            "&:hover": {
                              color: "success.main",
                              cursor: "pointer",
                            },
                          }}
                        />
                      </MDBox>
                    ),
                  },
                ]);
              } catch (err) {
                console.log(err);
                data.lastDate = "N/A";
                data.lastSalary = "N/A";
              }
            });
          }

          //   setRows(emp);
          setSuccess(true);
          // console.log(rows, "rows");
          // console.log("first");
          setFetched(true);

          // recursive call to fetch next 50 employees until no employees are left
          if (res.data.employees.length > 0) fetchEmployees(s + 30, l + 30);
        }
      })
      .catch((err) => {
        console.log(err);
        setSuccess(true);
      });
  };

  const createRows = (employeesData) => {
    setRows([]);
    employeesData.forEach(async (data) => {
      setRows((prev) => createRow(prev, data));

      setSuccess(true);
    });
  };

  const createRow = (prevRows, data) => {
    return [
      ...prevRows,
      {
        name: <Employee name={data.employeeName} />,
        empId: (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="regular"
          >
            {data.empId}
          </MDTypography>
        ),
        type: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            {data.employeeType}
          </MDTypography>
        ),

        lastDate: (
          <Typography variant="caption" color="green" fontWeight="regular">
            {data.lastDate}
          </Typography>
        ),

        action: (
          <MDBox sx={{ fontSize: "20px" }}>
            <Edit
              onClick={() => handleDetails(data, true)}
              sx={{
                "&:hover": {
                  color: "success.main",
                  cursor: "pointer",
                },
              }}
            />
          </MDBox>
        ),
      },
    ];
  };

  const handleDetails = (data, t) => {
    console.log(data);
    setEmp(data);
    setOpen(true);
    console.log("inside open");
  };

  const handleClose = () => setOpen(false);

  const getAttendanceData = () => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_ATTENDANCE_BASE_URL}/emp/month?month=${monthAndYear.month}&year=${monthAndYear.year}`
      )
      .then((res) => {
        setEmployeeLastMonthAttendance(res?.data?.records);

        console.log(res?.data?.records, "Attendance");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log(offlineEmployees, "offline employees");
    if (offlineEmployees.length > 0) {
      setEmployees(offlineEmployees);
      createRows(offlineEmployees);
      setFetched(true);
      return;
    }
    fetchEmployees(0, 30);
  }, []);
  /*
  useEffect(() => {
    const fetchLastDate = async () => {
      setRows([]);
      try {
        offlineEmployees.forEach(async (data) => {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/salary/totalAmount?empId=${data.empId}`
          );
          data.lastDate = res?.data?.data?.lastDate || "N/A";
          data.lastSalary = "N/A";

          setRows((prev) => createRow(prev, data));
        });
      } catch (err) {
        console.log(err);
      }
    };

    if (offlineEmployees.length > 0) fetchLastDate();
  });

  */
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          border: "none",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        <Form details={emp} setSalary={setSalary} close={handleClose}></Form>
      </Modal>

      <Card
        sx={{
          margin: "2rem 0",
        }}
      >
        <Box px={2} py={3} sx={{ backgroundColor: "#f0f2f5" }}>
          <Grid container py={3} sx={{ justifyContent: "space-between" }}>
            <Grid item xs={12} sm={12} md={3.2} sx={{ marginInlineEnd: 8 }}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-label">
                  Select Month
                </InputLabel>
                <FormikProvider value={formik}>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={monthAndYear.month}
                    label="Select Month"
                    onChange={(e) => {
                      setMonthAndYear({
                        ...monthAndYear,
                        [e.target.name]: e.target.value,
                      });

                      setAttendanceMonthAndYear({
                        ...attendanceMonthAndYear,
                        [e.target.name]: e.target.value,
                      });

                      handleChange(e);
                    }}
                    name="month"
                    p={2}
                    sx={{
                      height: "35px",
                    }}
                    error={touched.month && errors.month}
                  >
                    {months.map((category) => (
                      <MenuItem key={category.value} value={category.value}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Field>
                  {errors.month && touched.month && (
                    <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                      {errors.month}
                    </FormHelperText>
                  )}
                </FormikProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3.2}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-label">
                  Select Year
                </InputLabel>
                <FormikProvider value={formik}>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={monthAndYear.year}
                    label="Select Year"
                    onChange={(e) => {
                      setMonthAndYear({
                        ...monthAndYear,
                        [e.target.name]: e.target.value,
                      });
                      setAttendanceMonthAndYear({
                        ...attendanceMonthAndYear,
                        [e.target.name]: e.target.value,
                      });
                      handleChange(e);
                    }}
                    name="year"
                    p={2}
                    sx={{
                      height: "35px",
                    }}
                    error={touched.year && errors.year}
                  >
                    {years.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Field>
                  {errors.year && touched.year && (
                    <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                      {errors.year}
                    </FormHelperText>
                  )}
                </FormikProvider>
              </FormControl>
            </Grid>
            <Box sx={{ flex: "1 1 auto" }} />
            <MDButton
              variant="gradient"
              color="info"
              onClick={getAttendanceData}
              sx={{
                color: "white",
                background: "#232526",
                background:
                  "-webkit-linear-gradient(to right, #232526, #414345)",
                background: "linear-gradient(to right, #232526, #414345)",
              }}
            >
              GET DATA
            </MDButton>
          </Grid>
        </Box>
      </Card>

      <Box
        sx={{
          position: "relative",
        }}
      >
        {success && (
          <MaterialTable
            columns={[
              {
                title: "Name",
                field: "name",
                customFilterAndSearch: (term, rowData) =>
                  rowData.name.props.name
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1,
              },
              {
                title: "Emp_Id",
                field: "empId",
                render: (rowData) => <>{rowData.empId}</>,
                customFilterAndSearch: (term, rowData) =>
                  rowData.empId.props.children
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1,
              },

              {
                title: "Type",
                field: "type",
              },

              {
                title: "Last Date",
                field: "lastDate",
                customFilterAndSearch: (term, rowData) =>
                  rowData?.lastDate?.props?.children
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1,
              },
              {
                title: "Action",
                field: "action",
              },
            ]}
            data={rows}
            onSelectionChange={(selectedRows) => console.log(selectedRows)}
            options={{
              sorting: true,
              search: true,
              searchFieldAlignment: "right",
              searchAutoFocus: true,
              searchFieldVariant: "standard",
              filtering: true,
              paging: true,
              pageSizeOptions: [10, 20, 50, 100],
              pageSize: 10,
              paginationType: "stepped",

              paginationPosition: "both",
              exportAllData: true,

              exportFileName: "items",
              addRowPosition: "first",
              actionsColumnIndex: -1,
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              selectionProps: (rowData) => ({
                disabled: rowData.age == null,
              }),
              columnsButton: true,
              rowStyle: (data, index) =>
                index % 2 === 0 ? { background: "#f5f5f5" } : null,
              headerStyle: { background: "#376fd0", color: "#fff" },
            }}
            title="Salary Calculation"
          />
        )}

        {(isloading || employeeLastMonthAttendance === null) && (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              background: "#f0f2f5",
              opacity: "0.8",
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: "100",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {isloading ? (
              <>
                <img
                  src={loader}
                  alt="loader"
                  style={{ width: "35px", height: "35px" }}
                />
                <Typography variant="caption">
                  Loading, please wait...
                </Typography>
              </>
            ) : (
              <Typography
                variant="caption"
                sx={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Please Select Month & Year
              </Typography>
            )}
          </Box>
        )}
      </Box>

      {/* {success && (
        <MaterialReactTable
          sx={{
            fontSize: "10px !important",
            color: "white !important",
          }}
          icons={{
            fontSize: "10px !important",
          }}
          localization={{
            toolbar: { searchPlaceholder: "Search", fontSize: "10px" },
          }}
          options={{
            sorting: true,
            search: true,
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            labelRowsPerPage: "",
            paging: true,
            pageSizeOptions: [],
            pageSize: 10,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            // exportButton: true,
            // exportAllData: true,
            // exportFileName: "items",
            addRowPosition: "first",
            actionsColumnIndex: -1,
            showSelectAllCheckbox: false,
            showTextRowsSelected: false,

            columnsButton: false,
            rowStyle: (data, index) =>
              index % 2 === 0 ? { background: "#f5f5f5" } : null,
            headerStyle: {
              background: "#1F78E9",
              color: "#fff",
              fontSize: "13px",
            },
          }}
          columns={[
            {
              header: "Name",
              accessorKey: "name",
              customFilterAndSearch: (term, rowData) =>
                rowData.name.props.name
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) > -1,
            },
            {
              header: "Emp_Id",
              accessorKey: "empId",
              render: (rowData) => <>{rowData.empId}</>,
              customFilterAndSearch: (term, rowData) =>
                rowData.empId.props.children
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) > -1,
            },

            {
              header: "Type",
              accessorKey: "type",
            },

            {
              header: "Last Salary",
              accessorKey: "lastSalary",
            },
            {
              header: "Last Date",
              accessorKey: "lastDate",
            },
            {
              header: "Action",
              accessorKey: "action",
            },
          ]}
          data={rows}
          title="Salary Calculation Table"
        />
      )} */}

      {/* <Grid container gap="2rem" alignItems={"start"} paddingTop="2rem">
         
         <Grid item md="5" sm="12" xs="12">

      <Form details={empDetails} setSalary={setSalary}></Form>
         </Grid>
         <Grid item md="6" sm="12" xs="12">
            <Details data={{...empDetails}} salary={salaryDetails}></Details>
         </Grid>
      </Grid> */}
    </DashboardLayout>
  );
}

export default Salary;
