import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import { Grid } from "@mui/material";
import axios from "axios";

import loader from "../../../assets/loadingWhite.svg";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { TextField } from "@mui/material";
import { convertLength } from "@mui/material/styles/cssUtils";
import actions from "redux/actions";
import { useDispatch } from "react-redux";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [creds, setCreds] = useState({ email: "", password: "" });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(true);
  const {login} = actions;
  const dispatch = useDispatch();


  const handleChange = (e) => {
    setCreds({ ...creds, [e.target.name]: e.target.value });
  };

  const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log(process.env.REACT_APP_BASE_URL);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    setSuccess(false)
    e.preventDefault();
    // console.log(creds);
    const userData = JSON.stringify({
      username: creds.email,
      password: creds.password,
    });

    // console.log(userData);
    axios({
      method: 'post', //you can set what request you want to be
      url: `${process.env.REACT_APP_BASE_URL}/user/signin`,
      data: userData,
      headers: {
        'Content-Type': 'application/json',
      }
    })  
    .then((res) => {
           dispatch(login(JSON.parse(userData)));
           setSuccess(true)
           sessionStorage.setItem("user", JSON.stringify(userData));
           navigate("/dashboard");
           

    })
      .catch((err) => {
        setError(true)
        setSuccess(true)});
  };
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <MDTypography
                component={"a"}
                href="#"
                variant="body1"
                color="white"
              >
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={"a"}
                href="#"
                variant="body1"
                color="white"
              >
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={"a"}
                href="#"
                variant="body1"
                color="white"
              >
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={handleChange}
                name="email"
              />
            </MDBox>
            <MDBox mb={2}>
              <TextField
                id="outlined-basic"
                label="Password"
                type={"password"}
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={handleChange}
                name="password"
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              {error && (
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  color="text"
                  textAlign="center"
                  sx={{ width: "100%", color: "red" }}
                >
                  *Invalid Credentials*
                </MDTypography>
              )}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit}
              >
                {success ? "Sign in":  <img
                  src={loader}
                  style={{ width: "20px", height: "20px" }}
                ></img>}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Forgot Password? Please Contact Admin
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
