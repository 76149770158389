import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Cross from "@mui/icons-material/Cancel";
import Tick from "@mui/icons-material/CheckBox";
import { Card, Grid } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

import loader from "../../assets/loading.svg";
import axios from "axios";
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "80%",
    sm: "80%",
    md: "60%",
    lg: "60%",
    xl: "60%",
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  padding: "4rem",
  overflow: {
    sm: "scroll !important",
    md: "scroll !important",
    lg: "scroll !important",
    xl: "scroll !important",
    xs: "scroll !important",
  },
  height: {
    xs: "70%",
    sm: "70%",
    md: "100%",
  },

  // p: 4,
};
const style1 = {
  bgcolor: "background.paper",
  border: "none",
  // boxShadow: 24,
  padding: "2rem",
  overflowY: " scroll !important",
  // p: 4,
};

const containerStyle = {};

function Details({ empId, show, isLoanDetails }) {
  const [open, setOpen] = useState(show);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  // const handleOpen = () => {

  //   setOpen(true);

  // };
  const handleClose = () => setOpen(false);
  console.log(details, "okey");

  useEffect(() => {
    const fetchEmployee = async () => {
      console.log("fetching data for employee", empId);
      setLoading(true);
      axios({
        method: "get", //you can set what request you want to be
        url: `${process.env.REACT_APP_BASE_URL}/employee?empId=${empId}`,
      })
        .then((res) => {
          console.log("response", res);
          setDetails(res.data.employees[0]);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    };

    if (empId) fetchEmployee();
  }, [empId]);

  return (
    <Card sx={isLoanDetails ? style1 : style2}>
      {loading && (
        <Box
          sx={{
            marginBottom: "3rem",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            paddingRight: "30px",
          }}
        >
          <img
            src={loader}
            alt="loader"
            style={{ width: "35px", height: "35px" }}
          />
        </Box>
      )}
      {!loading && (
        <>
          <Grid
            container
            justifyContent="space-between"
            sx={isLoanDetails && containerStyle}
          >
            <Grid item>
              <Grid container alignItems="center" gap="1.5rem">
                {!isLoanDetails && (
                  <Grid item>
                    <MDAvatar
                      src={details?.photo}
                      size="xl"
                      sx={{
                        border: "1px solid gray",
                        objectFit: "fill",
                      }}
                    ></MDAvatar>
                  </Grid>
                )}
                <Grid item>
                  <Typography
                    variant="h3"
                    fontWeight={"bold"}
                    sx={{ color: "#344767" }}
                  >
                    {details?.employeeName}
                  </Typography>

                  <Typography variant="overline" fontWeight={"bold"}>
                    {details?.employeeType}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container flexDirection={"column"} gap="0.7rem">
                <Grid item alignItems="center" display={"flex"} gap="10px">
                  <CallIcon
                    fontSize="small"
                    sx={{ color: "#344767" }}
                  ></CallIcon>
                  <Typography variant="caption" fontWeight={"bold"}>
                    {details?.contact}
                  </Typography>
                </Grid>
                <Grid item alignItems="center" display={"flex"} gap="10px">
                  <EmailIcon sx={{ color: "#344767" }}></EmailIcon>
                  <Typography variant="caption" fontWeight={"bold"}>
                    {details?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container flexDirection={""} justifyContent="space-between">
            <Grid item>
              <Grid container flexDirection={"column"} gap="4px">
                <Typography
                  variant="h6"
                  color="#1A73E8"
                  sx={{
                    margin: "1rem 0",

                    fontSize: "18px",
                  }}
                >
                  Personal Information
                </Typography>

                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    Date of Birth : {details?.dob}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="#344767"
                    fontWeight={"bold"}
                    fontSize={"14px"}
                  >
                    Gender : {details?.gender}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    fontSize={"14px"}
                    color="#344767"
                  >
                    Qualification : {details?.qualification}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    Work Exp : {details?.workExp} yrs
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    Emergency Contact : {details?.emergencyContact}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="#344767"
                    fontWeight={"bold"}
                    fontSize={"14px"}
                  >
                    Aadhaar No. : {details?.adharNo}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    Pan No. : {details?.panNo}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container flexDirection={"column"} gap="4px">
                <Typography
                  variant="h6"
                  color="#1A73E8"
                  sx={{ margin: "1rem 0", fontSize: "18px" }}
                >
                  Company Information
                </Typography>

                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    PF No. : {details?.pfNo}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="#344767"
                    fontWeight={"bold"}
                    fontSize={"14px"}
                  >
                    PFUAN No. :{details?.pfuanNo}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    ESIC No. : {details?.esicNo}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    Incentive : {details?.incentive}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    Safety Pass No. : {details?.safetyPassNo}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="#344767"
                    fontWeight={"bold"}
                    fontSize={"14px"}
                  >
                    JNTVTI Cert. No. : {details?.jntvtiCertNo}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    JNTVTI Category. : {details?.jntvtiCategory}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    Emp. Designation : {details?.employeeDesignation}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    Emp. Deploy Site : {details?.employeeDeploySite}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    Emp. Id : {details?.empId}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container flexDirection={"column"} gap="4px">
                <Typography
                  variant="h6"
                  color="#1A73E8"
                  sx={{ margin: "1rem 0", fontSize: "18px" }}
                >
                  Bank Information
                </Typography>

                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="#344767"
                    fontWeight={"bold"}
                    fontSize={"14px"}
                  >
                    Bank Name : {details?.bankName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="#344767"
                    fontWeight={"bold"}
                    fontSize={"14px"}
                  >
                    Account No. : {details?.accountNo}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    color="#344767"
                    fontSize={"14px"}
                  >
                    IFSC Code : {details?.ifscCode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!isLoanDetails && (
            <Grid container>
              <ImageList
                sx={{ width: "100%", height: "280px", marginTop: "1rem" }}
              >
                <ImageListItem key="Subheader" cols={4}></ImageListItem>

                <ImageListItem
                  key={details?.adharPhoto}
                  sx={{
                    width: {
                      xs: "200px !important",
                      md: "220px !important",
                      lg: "220px !important",
                      xl: "300px !important",
                    },
                    height: "100%",
                  }}
                >
                  <img
                    src={`${details?.adharPhoto}`}
                    srcSet={`${details?.adharPhoto}`}
                    alt={details?.employeeName}
                    loading="lazy"
                    style={{ objectFit: "cover" }}
                  />
                  <ImageListItemBar
                    title={"Aadhaar Card"}
                    subtitle={details?.employeeName}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${details?.employeeName}`}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>

                <ImageListItem
                  key={details?.panPhoto}
                  sx={{
                    width: {
                      xs: "200px !important",
                      md: "220px !important",
                      lg: "220px !important",
                      xl: "300px !important",
                    },
                    height: "100%",
                  }}
                >
                  <img
                    src={`${details?.panPhoto}`}
                    srcSet={`${details?.panPhoto}`}
                    alt={details?.employeeName}
                    loading="lazy"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                  <ImageListItemBar
                    title={"Pan Card"}
                    subtitle={details?.employeeName}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${details?.employeeName}`}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>

                <ImageListItem
                  key={details?.jntvtiCertPhoto}
                  sx={{
                    width: {
                      xs: "200px !important",
                      md: "220px !important",
                      lg: "220px !important",
                      xl: "300px !important",
                    },
                    height: "100%",
                  }}
                >
                  <img
                    src={`${details?.jntvtiCertPhoto}`}
                    srcSet={`${details?.jntvtiCertPhoto}`}
                    alt={details?.employeeName}
                    loading="lazy"
                    style={{ objectFit: "cover" }}
                  />
                  <ImageListItemBar
                    title={"JNTVTI Cert"}
                    subtitle={details?.employeeName}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${details?.employeeName}`}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
                <ImageListItem
                  key={details?.policeVerificationCertPhoto}
                  sx={{
                    width: {
                      xs: "220px",
                      md: "220px ",
                      lg: "300px ",
                      xl: "300px !important",
                    },
                    height: "100%",
                  }}
                >
                  <img
                    src={`${details?.policeVerificationCertPhoto}`}
                    srcSet={`${details?.policeVerificationCertPhoto}`}
                    alt={details?.employeeName}
                    loading="lazy"
                    style={{ objectFit: "cover" }}
                  />
                  <ImageListItemBar
                    title={"Police Verif Photo"}
                    subtitle={details?.employeeName}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${details?.employeeName}`}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              </ImageList>
            </Grid>
          )}
        </>
      )}
    </Card>
  );
}

export default Details;

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
    author: "@bkristastucchio",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
    author: "@rollelflex_graphy726",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
    author: "@helloimnik",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
    author: "@nolanissac",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
    author: "@hjrc33",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
    author: "@tjdragotta",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
    author: "@katie_wasserman",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
    author: "@silverdalex",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
    author: "@shelleypauls",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
    author: "@peterlaster",
  },
  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: "Bike",
    author: "@southside_customs",
    cols: 2,
  },
];
