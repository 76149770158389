import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import Form from "./Form";
import axios from "axios";

// Billing page components
import TypeItem from "./TypeItem";

function Table() {

  //  const editable= {category:"Multi-Skilled",wage:1000,ot:100}};
   const [editable, setEditable] = useState({category:"",wage:"",ot:""});
   const [types, setTypes] = useState(null);

   const compareWage = (a, b) =>{

    return b?.dailyWage - a?.dailyWage
}

   useEffect(()=>
   {
   


    axios({
      method: 'get', //you can set what request you want to be
      url: `${process.env.REACT_APP_BASE_URL}/employee/getTypes`,
      headers: {
        'Content-Type': 'application/json',
      }
    }) 
    .then(res=>{
      // console.log(res.data.types)

     
     setTypes(res.data.types.sort(compareWage));
      
    })
    .catch(err=>console.log(err))

   },[]);


  return (
    <DashboardLayout>
      {/* <DashboardNavbar absolute isMini /> */}
      <DashboardNavbar />

      <Grid container sx={{
        
        flexDirection: {
          xs: "column",
          md: "row",
        },
        gap: "15px",

        }
      }>
        <Grid item md={6.5} xs={12} >
        <Card>

          <MDBox pt={3} px={2} my={2}>
            <MDTypography variant="h5" fontWeight="medium">
              Employee Types Information
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={2} px={2}>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
            >
              {types && types.map((item) => ( <TypeItem key={item.employeeType} type={item.employeeType} wage={item.dailyWage} ot={item.otHours} editItem={setEditable}/>)) 
              }

              {/* { !types && <>
              <TypeItem type="Multi-Skilled" wage={1000} ot={10} editItem={setEditable}/>
              <TypeItem type="Semi-Skilled" wage={800} ot={10} editItem={setEditable}/>
              <TypeItem type="Skilled" wage={500} ot={10} editItem={setEditable}/>
              <TypeItem type="Non-Skilled" wage={300} ot={10} noGutter editItem={setEditable}/>
              </>
              } */}
            </MDBox>
          </MDBox>
              </Card>
        </Grid>
        <Grid item md={5.3} xs={12}  my={0} sx={{
          // height:"100vh"
        }}>
          <Form item={editable}></Form>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Table;
