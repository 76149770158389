import {
  Backdrop,
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import loader from "../../assets/loading.svg";
import { Field, FormikProvider, useFormik } from "formik";
import MDButton from "components/MDButton";
import validationSchemaForm from "layouts/payslip/validationSchema/ValidationSchemaForm";
import MaterialTable from "material-table";

import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSunday,
  isWeekend,
} from "date-fns";
import { Visibility } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import PhotoComponent from "./PhotoComponent";

function AttendanceHistory() {
  const [rows, setRows] = useState([]);
  const [success, setSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [err, setErr] = useState(false);

  const [monthAndYear, setMonthAndYear] = useState({
    month: "",
    year: "",
  });

  const initialValues = monthAndYear;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaForm,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const years = Array.from(
    { length: 2030 - 2021 + 1 },
    (_, index) => 2021 + index
  );

  const [employees, setEmployees] = useState(null);
  const [apiError, setApiError] = useState("");
  const [isData, setIsData] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [image, setImage] = useState(null);
  const { empId } = useParams();

  console.log(process.env.REACT_APP_ATTENDANCE_BASE_URL, "base url");

  const getData = () => {
    setSuccess(false);
    setFetched(false);
    setIsLoading(true);
    setRows([]);
    axios
      .get(
        `${process.env.REACT_APP_ATTENDANCE_BASE_URL}/emp/employee?empId=${empId}`
      )
      .then((res) => {
        console.log(res);
        setEmployees(res.data.records);
        setApiError("");
        setIsData(true);

        if (res.data?.records) {
          addActionsToRows(res.data.records);
        }

        //   setRows(emp);
        setSuccess(true);
        setFetched(true);
        setIsLoading(false);
        console.log(res.data?.records);
      })
      .catch((error) => {
        setApiError("Something went wrong!!");
        setIsData(false);
        setErr(true);
        setSuccess(true);

        setIsLoading(false);
      });
  };

  if (isData) {
    setIsData(false);
  }

  const handleDetails = (employee) => {
    console.log(employee, "selected employee");
    setImage(employee?.group_photo);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const addActionsToRows = (employees) => {
    // Map through the salaries array and add the 'action' prop to each row
    const rowsWithActions = employees.map((item) => ({
      ...item,
      action: (
        <MDBox sx={{ fontSize: "20px" }}>
          <Visibility
            onClick={() => handleDetails(item, true)}
            sx={{
              "&:hover": {
                color: "green",
                cursor: "pointer",
              },
              zIndex: 3,
            }}
          />
        </MDBox>
      ),
    }));

    // Set the updated rows to the state using setRows
    setRows(rowsWithActions);
  };

  const columns = [
    {
      title: "Dates",
      field: "date",
      render: (rowData) => <>{rowData?.date && rowData.date.toString()}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.date.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Punch-In Time",
      field: "punch_in",
      cellStyle: {
        cellWidth: "200px",
      },
      render: (rowData) => (
        <>
          {rowData?.punch_in &&
            new Date(rowData.punch_in).toLocaleTimeString("en-IN")}
        </>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.employeeName
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Punch-Out Time",
      field: "punch_out",
      render: (rowData) => (
        <>
          {rowData.punch_out &&
            new Date(rowData.punch_out).toLocaleTimeString("en-IN")}
        </>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.numDays.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },

    {
      title: "View Photo",
      field: "action",
    },
  ];

  useEffect(() => {
    if (empId) getData();
  }, [empId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Modal
        open={openModal}
        onClose={handleClose}
        sx={{
          border: "none",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        <PhotoComponent data={image} close={handleClose}></PhotoComponent>
      </Modal>
      {isloading && (
        <Box
          sx={{
            marginBottom: "3rem",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            paddingRight: "30px",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            src={loader}
            alt="loader"
            style={{ width: "35px", height: "35px" }}
          />
          <Typography variant="caption" textAlign={"center"}>
            Loading history data, please wait. <br />
            {"\n"}This may take a while...
          </Typography>
        </Box>
      )}
      <MaterialTable
        tableH
        muiTableBodyProps={{
          sx: {
            //stripe the rows, make odd rows a darker color
            "& tr:nth-of-type(odd)": {
              backgroundColor: "#f5f5f5",
            },
          },
        }}
        sx={{
          fontSize: "10px !important",
          color: "white !important",
          borderRadius: "20px !important",
          boxShadow: "none",
        }}
        icons={{
          fontSize: "10px !important",
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Search",
            fontSize: "10px",
          },
        }}
        options={{
          sorting: true,
          search: true,
          searchFieldAlignment: "right",
          searchAutoFocus: true,
          searchFieldVariant: "standard",
          filtering: true,
          paging: true,
          pageSizeOptions: [10, 20, 50, 100],
          pageSize: 10,
          paginationType: "stepped",

          paginationPosition: "both",
          exportButton: true,
          exportAllData: true,
          exportFileName: "Attendance History - " + empId,
          addRowPosition: "first",
          actionsColumnIndex: -1,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          selectionProps: (rowData) => ({
            disabled: rowData.age == null,
          }),
          columnsButton: true,
          rowStyle: (data, index) =>
            index % 2 === 0
              ? { background: "#f5f5f5", fontSize: "14px" }
              : { fontSize: "14px" },
          headerStyle: { background: "#376fd0", color: "#fff" },
        }}
        columns={columns}
        data={rows}
        title={"Attendance History - " + empId}
        style={{
          borderRadius: "20px !important",
        }}
      />
    </DashboardLayout>
  );
}

export default AttendanceHistory;
