import {EMPLOYEE_TYPES_FORM} from '../constants'

const initialState = {
    details: null,
}

const employeeTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYEE_TYPES_FORM:
            return {
                ...state,
                details: action.payload
            }
        default:
            return state;
    }
}

export default employeeTypeReducer;