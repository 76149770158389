import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useSelector } from "react-redux";
import axios from "axios";

import Card from "@mui/material/Card";

function Form(editable) {
  const details = useSelector((state) => state.employeeTypesForm.details);

  console.log(details);
  const categories = ["High-Skilled", "Semi-Skilled", "Skilled"];
  const [formDetails, setFormDetails] = useState({
    category: "",
    wage: "",
    ot: "",
  });

  useEffect(() => {
    if (details) setFormDetails(details);
  }, [details]);

  const handleSubmit = (e) => {
    console.log("Submitted");
    console.log(formDetails);

    const data = {
      employeeType: formDetails.category,
      dailyWage: parseInt(formDetails.wage),
      otHours: parseInt(formDetails.ot),
    };

    axios({
      method: "put", //you can set what request you want to be
      url: `${process.env.REACT_APP_BASE_URL}/employee/editType`,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        console.log(data);
        window.location.reload();
      })
      .catch((err) => console.log(err.response.data));
  };

  const handleChange = (e) => {
    if (e.target.name === "category")
      setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
    else {
      // console.log("inside check");
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
      }
    }
    // console.log(e.target.value);
  };

  return (
    <Card
      sx={
        {
          // height:"100vh"
        }
      }
    >
      <Grid
        container
        p={2}
        py={5}
        sx={{
          gap: "2rem",
          flexDirection: "column",
          width: { md: "100%" },
          height: "100%",
        }}
      >
        <Grid item>
          <Typography variant="h5">Edit Categories</Typography>
        </Grid>

        <Grid
          item
          component={"form"}
          sx={{
            gap: "2rem",
            flexDirection: "column",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formDetails.category}
              label="Category"
              onChange={handleChange}
              name="category"
              p={2}
              sx={{
                height: "45px",
              }}
            >
              {categories.map((category) => (
                <MenuItem value={category}>{category}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box my={2}>
            <TextField
              id="outlined-basic"
              name="wage"
              onChange={handleChange}
              label="Daily Wage"
              variant="outlined"
              fullWidth
              value={formDetails.wage}
            />
          </Box>
          <Box my={2}>
            <TextField
              id="outlined-basic"
              name="ot"
              onChange={handleChange}
              label="OT Hours"
              variant="outlined"
              fullWidth
              value={formDetails.ot}
            />
          </Box>

          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={handleSubmit}
            sx={{
              color: "white",
              background: "#232526",
              background: "-webkit-linear-gradient(to right, #232526, #414345)",
              background: "linear-gradient(to right, #232526, #414345)",
            }}
          >
            Submit
          </MDButton>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Form;
