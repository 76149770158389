import { create } from "zustand";

const useZustandStore = create((set) => ({
  dailyAttendanceRecords: null,
  setDailyAttendanceRecords: (dailyAttendanceRecords) =>
    set({ dailyAttendanceRecords }),

  dailyDate: null,
  setDailyDate: (dailyDate) => set({ dailyDate }),

  monthlyAttendanceRecords: null,
  setMonthlyAttendanceRecords: (monthlyAttendanceRecords) =>
    set({ monthlyAttendanceRecords }),

  zustandMonthAndYear: null,
  setZustandMonthAndYear: (zustandMonthAndYear) => set({ zustandMonthAndYear }),

  // for employee attendance
  employeeLastMonthAttendance: null,
  setEmployeeLastMonthAttendance: (employeeLastMonthAttendance) =>
    set({ employeeLastMonthAttendance }),

  attendanceMonthAndYear: null,
  setAttendanceMonthAndYear: (attendanceMonthAndYear) =>
    set({ attendanceMonthAndYear }),
}));

export default useZustandStore;
