import {SALARY_DETAILS} from '../constants'

const initialState = {
    empDetails: null,
}

const salaryDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SALARY_DETAILS:
            return {
                ...state,
                empDetails: action.payload
            }
        default:
            return state;
    }
}

export default salaryDetailsReducer;