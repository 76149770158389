import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { customAlphabet } from "nanoid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/actions";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import dayjs from "dayjs";
import { next } from "stylis";
import MDButton from "components/MDButton";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import validationSchemaPersonal from "./validationSchema/PersonalValidationSchema";

function PersonalDetails({
  editForm,
  back,
  next,
  step,
  formDetails,
  setFormDetails,
}) {
  const nanoid = customAlphabet("1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ", 10);
  const [temp, setTemp] = useState({ gender: "", employeeTypeId: "", dob: "" });
  const uid = nanoid();
  const storeFormData = useSelector((state) => state.regForm.details);
  const [uploads, setUploads] = useState({
    adharPhoto: false,
    panPhoto: false,
    photo: false,
  });
  const [error, setError] = useState(false);

  const uploadedFiles = [];

  // const [value, setValue] = useState(ne);
  // const categories = ["High-Skilled", "Semi-Skilled", "Skilled"];
  const categories = [
    { label: "High Skilled", value: 1 },
    { label: "Skilled", value: 2 },
    { label: "Semi-Skilled", value: 3 },
  ];

  const initialValues = formDetails;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaPersonal,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const [selectedOption, setSelectedOption] = useState(1);
  const handleOptionChange = (event) => {
    let option = categories.filter(
      (category) => category.value === event.target.value
    )[0].label;
    setSelectedOption(event.target.value);
  };

  const dispatch = useDispatch();
  const myForm = React.useRef(null);

  const checkValidity = () => {
    console.log("valid");
    console.log(formDetails, "formDetails in start");
    if (!myForm.current.checkValidity()) {
      setError(true);
      return;
    }
    console.log("valid");
    setFormDetails({ ...formDetails, empId: uid });
    dispatch(actions.editRegForm(formDetails));
    next();
  };
  const handleDate = (d) => {
    const date = new Date(d);
    console.log(date.toLocaleString());
    console.log(date.toLocaleString().split(" ")[0].replace(",", ""));
    setFormDetails({
      ...formDetails,
      dob: date.toLocaleString().split(",")[0].replace(",", ""),
    });
  };

  const handleInput = (e) => {
    console.log("Checking name", e.target.name);
    if (
      e.target.name === "contact" ||
      e.target.name === "emergencyContact" ||
      e.target.name === "workExp" ||
      e.target.name === "adharNo"
    ) {
      const re = /^(0|[1-9][0-9]*)$/;

      if (e.target.value === "" || re.test(e.target.value)) {
        const value = parseInt(e.target.value);
        setFormDetails({ ...formDetails, [e.target.name]: value });
      }
    } else {
      setFormDetails({ ...formDetails, [e.target.name]: e.target.value });

      if (e.target.name === "gender" || e.target.name === "employeeTypeId") {
        setTemp({ ...temp, [e.target.name]: e.target.value });
      }
    }
  };

  const handleFileInput = (e) => {
    console.log(e.target.files[0]);

    setFormDetails({ ...formDetails, [e.target.name]: e.target.files[0] });

    if (e.target.files.length > 0)
      setUploads({ ...uploads, [e.target.name]: true });
  };

  function formatDate(dateString) {
    if (!dateString) return ""; // Handle empty or undefined values

    const [day, month, year] = dateString.split("/");
    const formattedDate = `${year}-${addLeadingZero(month)}-${addLeadingZero(
      day
    )}`;
    return formattedDate;
  }

  function addLeadingZero(value) {
    return value?.length === 1 ? `0${value}` : value;
  }

  useEffect(() => {
    console.log("run", formDetails);
    if (storeFormData) {
      setFormDetails(storeFormData);
    }
  }, []);

  return (
    <Box px={2} py={3}>
      <form ref={myForm}>
        {error && (
          <Typography variant="caption" sx={{ color: "red", fontSize: "14px" }}>
            *Please fill all the necessary fields
          </Typography>
        )}

        <Typography variant="h5">Personal Details</Typography>
        <Grid container py={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="Employee ID"
              name="empId"
              onChange={handleInput}
              component="div"
              variant="standard"
              required
              value={formDetails?.empId || ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              required
              id="standard-basic"
              label="Employee Name"
              name="employeeName"
              variant="standard"
              fullWidth
              value={formDetails?.employeeName || ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              onBlur={handleBlur}
              error={touched.employeeName && !!errors.employeeName}
              helperText={touched.employeeName && errors.employeeName}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label" required>
                Employee Type
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={formDetails?.employeeType || temp.employeeType}
                value={formDetails?.employeeTypeId || temp.employeeTypeId}
                label="Employee Type"
                onChange={(event) => {
                  handleChange(event);
                  handleInput(event);
                }}
                name="employeeTypeId"
                onBlur={handleBlur}
                error={touched.employeeTypeId && !!errors.employeeTypeId}
                helperText={touched.employeeTypeId && errors.employeeTypeId}
                p={2}
                sx={{
                  height: "35px",
                }}
              >
                {/* {categories.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
                <MenuItem key={nanoid()} value={1}>
                  High-Skilled
                </MenuItem>
                <MenuItem key={nanoid()} value={3}>
                  Semi-Skilled
                </MenuItem>
                <MenuItem key={nanoid()} value={2}>
                  Skilled
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container py={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={3.2}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <label htmlFor="employee_dob">
                <Typography variant="caption" fontSize={14}>
                  Date of Birth
                </Typography>
              </label>
              <input
                id="employee_dob"
                name="dob"
                type="date"
                value={formatDate(formDetails?.dob || "")}
                onChange={(e) => handleDate(e.target.value)}
                style={{
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid #e0e0e0",
                  fontSize: "16px",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <FormControl
              variant="standard"
              fullWidth
              sx={{ textAlign: "left" }}
            >
              <InputLabel id="demo-simple-select-label" required>
                Gender
              </InputLabel>
              <FormikProvider value={formik}>
                <Field
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  required
                  value={
                    formDetails?.gender?.toUpperCase() ||
                    temp?.gender?.toUpperCase()
                  }
                  label="Gender"
                  onChange={(event) => {
                    handleChange(event);
                    handleInput(event);
                  }}
                  name="gender"
                  py={2}
                  SelectDisplayProps={{
                    style: {
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: 0,
                    },
                  }}
                  sx={{
                    height: "30px",
                    //  textAlign:"left"
                  }}
                  error={touched.gender && errors.gender}
                >
                  <MenuItem key={nanoid()} value={"MALE"}>
                    MALE
                  </MenuItem>
                  <MenuItem key={nanoid()} value={"FEMALE"}>
                    FEMALE
                  </MenuItem>
                </Field>
                {errors.gender && touched.gender && (
                  <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                    {errors.gender}
                  </FormHelperText>
                )}
              </FormikProvider>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="Qualification"
              name="qualification"
              variant="standard"
              value={formDetails?.qualification || ""}
              
              onChange={handleInput}
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={3.2}>
            <FormControl
              variant="standard"
              fullWidth
              sx={{ textAlign: "left" }}
            >
              <InputLabel id="demo-simple-select-label" required>
                Qualification
              </InputLabel>
              <FormikProvider value={formik}>
                <Field
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  required
                  value={formDetails?.qualification || ""}
                  label="Qualification"
                  onChange={(event) => {
                    handleChange(event);
                    handleInput(event);
                  }}
                  name="qualification"
                  py={2}
                  SelectDisplayProps={{
                    style: {
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: 0,
                    },
                  }}
                  sx={{
                    height: "30px",
                    //  textAlign:"left"
                  }}
                  error={touched.qualification && errors.qualification}
                >
                  <MenuItem key={nanoid()} value={"Matriculation"}>
                    Matriculation
                  </MenuItem>
                  <MenuItem key={nanoid()} value={"Intermediate"}>
                    Intermediate
                  </MenuItem>
                  <MenuItem key={nanoid()} value={"Graduation"}>
                    Graduation
                  </MenuItem>
                </Field>
                {errors.qualification && touched.qualification && (
                  <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                    {errors.qualification}
                  </FormHelperText>
                )}
              </FormikProvider>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container py={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              required
              id="standard-basic"
              label="Contact"
              name="contact"
              variant="standard"
              value={formDetails?.contact || ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              fullWidth
              onBlur={handleBlur}
              error={touched.contact && !!errors.contact}
              helperText={touched.contact && errors.contact}
              inputProps={{ maxLength: 12, minLength: 9 }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="Emergency Contact"
              name="emergencyContact"
              value={formDetails?.emergencyContact || ""}
              variant="standard"
              inputProps={{ maxLength: 12, minLength: 9 }}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              fullWidth
              onBlur={handleBlur}
              error={touched.emergencyContact && !!errors.emergencyContact}
              helperText={touched.emergencyContact && errors.emergencyContact}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="Email"
              name="email"
              variant="standard"
              value={formDetails?.email || ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              fullWidth
              onBlur={handleBlur}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
          </Grid>
        </Grid>
        <Grid container py={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              required
              id="standard-basic"
              label="Aadhaar No."
              name="adharNo"
              variant="standard"
              value={formDetails?.adharNo || ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              fullWidth
              onBlur={handleBlur}
              error={touched.adharNo && !!errors.adharNo}
              helperText={touched.adharNo && errors.adharNo}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="Pan No."
              name="panNo"
              variant="standard"
              value={formDetails?.panNo || ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              fullWidth
              onBlur={handleBlur}
              error={touched.panNo && !!errors.panNo}
              helperText={touched.panNo && errors.panNo}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3.2}>
            <TextField
              id="standard-basic"
              label="Work Exp (in Years)"
              name="workExp"
              variant="standard"
              value={formDetails?.workExp || ""}
              onChange={(event) => {
                handleChange(event);
                handleInput(event);
              }}
              fullWidth
              onBlur={handleBlur}
              error={touched.workExp && !!errors.workExp}
              helperText={touched.workExp && errors.workExp}
            />
          </Grid>
        </Grid>
        <Grid container py={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={3.2}>
            <Button
              component="label"
              sx={
                uploads.photo
                  ? {
                      border: "1px solid #348DED",
                      color: "white !important",
                      backgroundColor: "#348DED !important",
                      textTransform: "none",
                    }
                  : {
                      border: "1px solid #348DED",
                      color: "#348DED",
                      textTransform: "none",
                    }
              }
            >
              <CloudUploadIcon
                sx={{ marginRight: "4px", fontSize: "20px !important" }}
              ></CloudUploadIcon>{" "}
              Upload Photo
              <input
                type="file"
                hidden
                accept="image"
                name="photo"
                onChange={handleFileInput}
              />
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <Button
              component="label"
              sx={
                uploads.adharPhoto
                  ? {
                      border: "1px solid #348DED",
                      color: "white !important",
                      backgroundColor: "#348DED !important",
                      textTransform: "none",
                    }
                  : {
                      border: "1px solid #348DED",
                      color: "#348DED",
                      textTransform: "none",
                    }
              }
            >
              <CloudUploadIcon
                sx={{ marginRight: "4px", fontSize: "20px !important" }}
              ></CloudUploadIcon>{" "}
              Upload Aadhaar Photo
              <input
                type="file"
                hidden
                accept="image"
                name="adharPhoto"
                onChange={handleFileInput}
              />
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={3.2}>
            <Button
              component="label"
              sx={
                uploads.panPhoto
                  ? {
                      border: "1px solid #348DED",
                      color: "white !important",
                      backgroundColor: "#348DED !important",
                      textTransform: "none",
                    }
                  : {
                      border: "1px solid #348DED",
                      color: "#348DED",
                      textTransform: "none",
                    }
              }
            >
              <CloudUploadIcon
                sx={{ marginRight: "4px", fontSize: "20px !important" }}
              ></CloudUploadIcon>{" "}
              Upload Pan Photo
              <input
                type="file"
                hidden
                accept="image"
                name="panPhoto"
                onChange={handleFileInput}
              />
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />

          <MDButton
            variant="gradient"
            color="info"
            onClick={checkValidity}
            sx={{
              color: "white",
              background: "#232526",
              background: "-webkit-linear-gradient(to right, #232526, #414345)",
              background: "linear-gradient(to right, #232526, #414345)",
            }}
          >
            {step === 3 - 1 ? "Submit" : "Next"}
          </MDButton>
        </Box>
      </form>
    </Box>
  );
}

export default PersonalDetails;
