// reducer.js

import { SET_EMPLOYEES, SET_EMPLOYEES_WITH_SALARY } from "../constants";

const initialState = {
  employees: [],
};

const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYEES:
      return {
        employees: state.employees.concat(action.payload),
      };
    case SET_EMPLOYEES_WITH_SALARY:
      return {
        employees: action.payload,
      };

    default:
      return state;
  }
};

export default employeesReducer;
