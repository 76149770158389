import { Box, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";

const PhotoComponent = ({ data: group_photo, close }) => {
  const [base64Image, setBase64Image] = useState(null);

  function toBase64(arr) {
    // arr = new Uint8Array(arr); //if it's an ArrayBuffer
    return btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), "")
    );
  }

  useEffect(() => {
    if (!group_photo) return;

    console.log("group_photo", group_photo);

    if (typeof group_photo?.data !== "undefined") {
      const photo = group_photo;
      const stringPhoto = toBase64(photo.data);
      setBase64Image("data:image/png;base64," + stringPhoto);
    }
  }, [group_photo]);

  return (
    <>
      <img
        src={base64Image}
        alt="group_photo"
        style={{
          maxWidth: "85%",
          maxHeight: "85%",
          objectFit: "contain",
          borderRadius: "10px",
          backgroundColor: "white",
        }}
      />
      <MDButton
        color="info"
        variant="gradient"
        onClick={close}
        sx={{
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
      >
        Close
      </MDButton>
    </>
  );
};

export default PhotoComponent;
