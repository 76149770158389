import { Card, Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import PersonalDetails from "./PersonalDetails";
import CompanyDetails from "./CompanyDetails";
import PaymentDetails from "./PaymentDetails";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import AccountIcon from "@mui/icons-material/AccountBalanceWallet";
import { startOfMonth, endOfMonth, eachDayOfInterval, isSunday, isWeekend } from "date-fns";

import { StepIcon } from "@mui/material";
import MDButton from "components/MDButton";
import { ToastContainer, toast } from "react-toastify";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];
const icons = ["PersonIcon", "BusinessIcon", "AccountIcon"];

export function RegForm({ isEdit, empDetails, handleCloseEdit }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [editForm, setEditForm] = useState(empDetails);
  const [formDetails, setFormDetails] = useState({
    empId: "",
    employeeName: "",
    employeeDesignation: "",
    employeeDeploySite: "",
    gender: "",
    dob: "",
    qualification: "",
    contact: 0,
    email: "",
    emergencyContact: 0,
    pfNo: "",
    pfuanNo: "",
    esicNo: "",
    adharNo: 0,
    panNo: "",
    safetyPassNo: "",
    bankName: "",
    accountNo: "",
    incentive: 0,
    ifscCode: "",
    jntvtiCertNo: "",
    jntvtiCategory: "",
    workExp: 0,
    employeeTypeId: 1,
    actualRate:"",
    otType:"",
    workingHours:"",
    fixed_rate:'',
    payment_type:'',
  });

  //Includes Saturdays and Sundays
  // const getWorkingDaysInMonth = () => {
  //   const currentDate = new Date();
  //   const startOfMonthDate = startOfMonth(currentDate);
  //   const endOfMonthDate = endOfMonth(currentDate);
  //   const allDaysInMonth = eachDayOfInterval({ start: startOfMonthDate, end: endOfMonthDate });
    
  //   // Filter out Sundays (you can add more conditions for other holidays if needed)
  //   const workingDays = allDaysInMonth.filter((day) => !isSunday(day) && !isWeekend(day));
  //   return workingDays.length;
  // };

  //Includes only Sundays
  const getWorkingDaysInMonth = () => {
    const currentDate = new Date();
    const startOfMonthDate = startOfMonth(currentDate);
    const endOfMonthDate = endOfMonth(currentDate);
    const allDaysInMonth = eachDayOfInterval({ start: startOfMonthDate, end: endOfMonthDate });

    // Filter out Sundays
    const workingDays = allDaysInMonth.filter((day) => !isSunday(day));
    return workingDays.length;
  };
  const handleSalaryDivision = (salary) => {
    const workingDays = getWorkingDaysInMonth();
    if (workingDays === 0) {
      return 0;
    }
    const salaryPerDay = salary / workingDays;
    return salaryPerDay.toFixed(2);
  };

  if(formDetails?.fixed_rate!=''&& formDetails?.payment_type ==='Fixed Salary' ){
    handleSalaryDivision(formDetails?.fixed_rate);
  }

  useEffect(() => {
    if (isEdit) { 
      setEditForm(empDetails);
      setFormDetails(empDetails);
    }
  }, [empDetails]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log(formDetails, "Form Value");
    console.log(activeStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (data) => {
    if (isEdit) setEditForm({ ...editForm, ...data });
    else setFormDetails({ ...formDetails, ...data });
    console.log("final",editForm)
  };

  const notify = () =>
    toast.success("Employee Registered Successfully!!", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    console.log("Check edit",isEdit)
  if (isEdit)
    return (
      <Card
        style={{
          color: "white",
          width: "88%",
          height: "98%",
        }}
      >
        <Box
          style={{
            padding: "1rem 2.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              color: "green",
            }}
            variant="h4"
          >
            Edit Details of EmpId - {empDetails?.empId}
          </Typography>

          <MDButton color="info" variant="gradient" onClick={handleCloseEdit}>
            Cancel
          </MDButton>
        </Box>
        <Grid my={1}>
          <Box
            px={3}
            sx={{ width: "100%", position: "relative", bottom: "3rem" }}
          >
            {activeStep === 0 ? (
              <>
                <PersonalDetails
                  editForm={handleChange}
                  step={activeStep}
                  setFormDetails={setEditForm}
                  formDetails={editForm}
                  next={handleNext}
                  back={handleBack}
                ></PersonalDetails>
              </>
            ) : activeStep === 1 ? (
              <>
                <CompanyDetails
                  setFormDetails={setEditForm}
                  formDetails={editForm}
                  step={activeStep}
                  next={handleNext}
                  back={handleBack}
                ></CompanyDetails>
              </>
            ) : (
              <>
                <PaymentDetails
                  reset={handleReset}
                  setFormDetails={setEditForm}
                  formDetails={editForm}
                  step={activeStep}
                  next={handleNext}
                  back={handleBack}
                  notifyPop={notify}
                  isEdit={isEdit}
                  handleCloseEdit={handleCloseEdit}
                ></PaymentDetails>
              </>
            )}
          </Box>
        </Grid>
      </Card>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card>
        <Grid my={4}>
          <Box px={3} sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} sx={{ padding: "2rem 1rem" }}>
              <Step key={steps[0]}>
                <StepLabel
                  icon={<PersonIcon color={"white"} fontSize={"large"} />}
                  style={{ color: "white" }}
                ></StepLabel>
                {/* <StepIcon active={true} icon={<PersonIcon />}></StepIcon> */}
              </Step>
              <Step key={steps[1]}>
                <StepLabel
                  icon={<BusinessIcon color={"white"} fontSize={"large"} />}
                  style={{ color: "white" }}
                ></StepLabel>
              </Step>
              <Step key={steps[2]}>
                <StepLabel
                  icon={<AccountIcon color={"white"} fontSize={"large"} />}
                  style={{ color: "white" }}
                ></StepLabel>
              </Step>
            </Stepper>

            {activeStep === 0 ? (
              <>
                <PersonalDetails
                  editForm={handleChange}
                  step={activeStep}
                  setFormDetails={setFormDetails}
                  formDetails={formDetails}
                  next={handleNext}
                  back={handleBack}
                ></PersonalDetails>
              </>
            ) : activeStep === 1 ? (
              <>
                <CompanyDetails
                  formDetails={formDetails}
                  setFormDetails={setFormDetails}
                  step={activeStep}
                  next={handleNext}
                  back={handleBack}
                ></CompanyDetails>
              </>
            ) : (
              <>
                <PaymentDetails
                  reset={handleReset}
                  formDetails={formDetails}
                  setFormDetails={setFormDetails}
                  step={activeStep}
                  next={handleNext}
                  back={handleBack}
                  notifyPop={notify}
                  isEdit={isEdit}
                  handleCloseEdit={handleCloseEdit}
                ></PaymentDetails>
              </>
            )}
          </Box>
        </Grid>
      </Card>
    </DashboardLayout>
  );
}

export default RegForm;
