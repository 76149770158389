import { login } from "./userActions";
import { editTypesForm } from "./employeeTypeFormActions";
import { editRegForm } from "./regFormActions";
import { sub } from "./salaryDetailsActions";
import { setSite } from "./siteAction";
import { setEmployeesToRedux } from "./employeesActions";

const actions = {
  login,
  editTypesForm,
  editRegForm,
  sub,
  setSite,
  setEmployeesToRedux,
};

export default actions;
