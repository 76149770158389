import {
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";

import loader from "../../assets/loading.svg";
import { Field, FormikProvider, useFormik } from "formik";
import MDButton from "components/MDButton";
import validationSchemaForm from "layouts/payslip/validationSchema/ValidationSchemaForm";
import "./index.css";
import MaterialTable from "material-table";

function MasterTable() {
  const [rows, setRows] = useState([]);
  const [success, setSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [err, setErr] = useState(false);
  const [rateText, setRateText] = useState("");

  const [monthAndYear, setMonthAndYear] = useState({
    month: "",
    year: "",
  });

  const initialValues = monthAndYear;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaForm,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const years = Array.from(
    { length: 2027 - 2019 + 1 },
    (_, index) => 2019 + index
  );

  const [employees, setEmployees] = useState(null);
  console.log(employees);
  const [apiError, setApiError] = useState("");
  const [isData, setIsData] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const getData = () => {
    setSuccess(false);
    setFetched(false);
    setIsLoading(true);
    setRows([]);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/salary/getDated?dated=${monthAndYear.year}-${monthAndYear.month}`
      )
      .then((res) => {
        console.log(res);
        setEmployees(res.data.salaries);
        setApiError("");
        setIsData(true);

        if (res.data?.salaries) setRows(res.data.salaries);

        //   setRows(emp);
        setSuccess(true);
        setFetched(true);
        setIsLoading(false);
        console.log(res.data?.salaries);
      })
      .catch((error) => {
        setApiError("Something went wrong!!");
        setIsData(false);
        setErr(true);
        setSuccess(true);

        setIsLoading(false);
      });
  };
  const handleExport = () => {
    getData();
  };
  if (isData) {
    setIsData(false);
  }
  // useEffect(() => {
  //   axios({
  //     method: "get", //you can set what request you want to be
  //     url: `${process.env.REACT_APP_BASE_URL}/advance/all`,
  //   })
  //     .then((res) => {
  //       if (!fetched) {
  //         if (res.data?.advances) setRows(res.data.advances);

  //         //   setRows(emp);
  //         console.log(res.data?.advances);
  //         setSuccess(true);
  //         setFetched(true);
  //       }
  //     })
  //     .catch((err) => {
  //       setErr(true);
  //       setSuccess(true);
  //     });
  // }, []);

  // useEffect(() => {
  //   // Get the current date
  //   const currentDate = new Date();
  //   const currentMonth = currentDate.getMonth();
  //   const humanReadableMonth = currentMonth + 1;

  //   const currentYear = currentDate.getFullYear();
  //   console.log(humanReadableMonth,currentYear);
  //   setMonthAndYear({
  //     ...monthAndYear,
  //     month: humanReadableMonth,
  //     year: currentYear,
  //     });
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/salary/getDated?dated=${currentYear}-${humanReadableMonth}`
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setEmployees(res.data.salaries);
  //       setApiError("");
  //       setIsData(true);
  //       if (!fetched) {
  //         if (res.data?.salaries) setRows(res.data.salaries);

  //         //   setRows(emp);
  //         console.log(res.data?.salaries);
  //         setSuccess(true);
  //         setFetched(true);
  //       }
  //     })
  //     .catch((error) => {
  //       setApiError("Something went wrong!!");
  //       setIsData(false);
  //       setErr(true);
  //       setSuccess(true);
  //     });

  //   setEmployees('')

  // },[]);
  console.log(monthAndYear);
  let serialNumberCounter = 1;
  const columns = [
    {
      title: "SNo",
      render: () => serialNumberCounter++,
      width: "50px",
    },
    {
      title: "Emp_Id",
      field: "empId",
      render: (rowData) => <>{rowData.empId}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.empId.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Name",
      field: "employeeName",
      customFilterAndSearch: (term, rowData) =>
        rowData.employeeName
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "No. of Working days",
      field: "working_days",
      customFilterAndSearch: (term, rowData) =>
        rowData.working_days
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "MR Days",
      field: "mr_days",
      customFilterAndSearch: (term, rowData) => {
        if (rowData.mr_days)
          return (
            rowData.mr_days
              .toString()
              .toLowerCase()
              .indexOf(term.toLowerCase()) > -1
          );
      },
    },
    {
      title: "Rate",
      field: "employeeTypeId", // Assuming the field name is "EmployeeTypeId" in the response JSON
      render: (rowData) => {
        const employeeTypeId = rowData.employeeTypeId;
        switch (employeeTypeId) {
          case 1:
            setRateText("663.66");
            break;
          case 2:
            setRateText("579.38");
            break;
          case 3:
            setRateText("436.14");
            break;
          default:
            setRateText("");
            break;
        }
        return <>{rateText}</>;
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.employeeTypeId
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },

    {
      title: "Basic + DA",
      field: "basic_amount",
    },
    {
      title: "Overtime",
      field: "overtime",
      render: () => 0,
    },
    {
      title: "Other Cash Payment",
      field: "good_attendance",
    },
    {
      title: "Total Amount",
      field: "gross_amount",
    },
    {
      title: "PF",
      field: "pf",
      render: (rowData) => <>{rowData.pf.toFixed(2).toString()}</>,
    },
    {
      title: "ESI",
      field: "esi",
      render: (rowData) => <>{rowData.esi.toFixed(2).toString()}</>,
    },
    {
      title: "Other Amt.",
      field: "mr_advance",
      render: (rowData) => (
        <>
          {rowData.mr_advance ? rowData.mr_advance.toFixed(2).toString() : "0"}
        </>
      ),
    },
    {
      title: "Sign of Workmen",
      field: "sign_workmen",
    },
    {
      title: "Initial of Contract",
      field: "initial_contract",
    },
    {
      title: "Sign of Contractor",
      field: "sign_contractor",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          margin: "2rem 0",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Master Table
                </MDTypography>
              </MDBox>

              <Box px={2} py={3} sx={{ backgroundColor: "#f0f2f5" }}>
                <Grid container py={3} sx={{ justifyContent: "space-between" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3.2}
                    sx={{ marginInlineEnd: 8 }}
                  >
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-label">
                        Select Month
                      </InputLabel>
                      <FormikProvider value={formik}>
                        <Field
                          as={Select}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={monthAndYear.month}
                          label="Select Month"
                          onChange={(e) => {
                            setMonthAndYear({
                              ...monthAndYear,
                              [e.target.name]: e.target.value,
                            });
                            handleChange(e);
                          }}
                          name="month"
                          p={2}
                          sx={{
                            height: "35px",
                          }}
                          error={touched.month && errors.month}
                        >
                          {months.map((category) => (
                            <MenuItem
                              key={category.value}
                              value={category.value}
                            >
                              {category.name}
                            </MenuItem>
                          ))}
                        </Field>
                        {errors.month && touched.month && (
                          <FormHelperText
                            sx={{ color: "red", fontSize: "12px" }}
                          >
                            {errors.month}
                          </FormHelperText>
                        )}
                      </FormikProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3.2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-label">
                        Select Year
                      </InputLabel>
                      <FormikProvider value={formik}>
                        <Field
                          as={Select}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={monthAndYear.year}
                          label="Select Year"
                          onChange={(e) => {
                            setMonthAndYear({
                              ...monthAndYear,
                              [e.target.name]: e.target.value,
                            });
                            handleChange(e);
                          }}
                          name="year"
                          p={2}
                          sx={{
                            height: "35px",
                          }}
                          error={touched.year && errors.year}
                        >
                          {years.map((category) => (
                            <MenuItem key={category} value={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Field>
                        {errors.year && touched.year && (
                          <FormHelperText
                            sx={{ color: "red", fontSize: "12px" }}
                          >
                            {errors.year}
                          </FormHelperText>
                        )}
                      </FormikProvider>
                    </FormControl>
                  </Grid>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleExport}
                    sx={{
                      color: "white",
                      background: "#232526",
                      background:
                        "-webkit-linear-gradient(to right, #232526, #414345)",
                      background: "linear-gradient(to right, #232526, #414345)",
                    }}
                  >
                    GET DATA
                  </MDButton>
                  {apiError && (
                    <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                      {apiError}
                    </FormHelperText>
                  )}
                </Grid>
              </Box>
              {isloading && (
                <Box
                  sx={{
                    marginBottom: "3rem",
                    display: "flex",
                    justifyContent: "center",
                    pt: 2,
                    paddingRight: "30px",
                  }}
                >
                  <img
                    src={loader}
                    alt="loader"
                    style={{ width: "35px", height: "35px" }}
                  />
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>

      <MaterialTable
        tableH
        muiTableBodyProps={{
          sx: {
            //stripe the rows, make odd rows a darker color
            "& tr:nth-of-type(odd)": {
              backgroundColor: "#f5f5f5",
            },
          },
        }}
        sx={{
          fontSize: "10px !important",
          color: "white !important",
          borderRadius: "20px !important",
          boxShadow: "none",
        }}
        icons={{
          fontSize: "10px !important",
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Search",
            fontSize: "10px",
          },
        }}
        options={{
          sorting: true,
          search: true,
          searchFieldAlignment: "right",
          searchAutoFocus: true,
          searchFieldVariant: "standard",
          filtering: true,
          labelRowsPerPage: "",
          paging: true,
          pageSizeOptions: [],
          pageSize: 10,
          paginationType: "stepped",
          showFirstLastPageButtons: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: "Master Roll Table",
          enableGlobalFilter: true,
          addRowPosition: "first",
          actionsColumnIndex: -1,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          columnsButton: false,
          rowStyle: (data, index) =>
            index % 2 === 0
              ? {
                  background: "#f5f5f5",
                  fontSize: "14px",
                  textAlign: "center",
                }
              : {
                  background: "white",
                  fontSize: "14px",
                  textAlign: "center",
                },
          headerStyle: {
            background: "#376FD0",
            color: "#fff",
            fontSize: "16px",
          },
        }}
        columns={columns}
        data={rows}
        title=" "
        enableColumnFilters
        enableFilters
        enableColumnFilterModes
        enableGlobalFilter
        style={{
          borderRadius: "20px !important",
        }}
      />
    </DashboardLayout>
  );
}

export default MasterTable;
