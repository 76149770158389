import { Box, Typography } from "@mui/material";
import Details from "layouts/employees/Details";
import React, { useEffect } from "react";

function EmployeeDetails({ details, isLoanDetails }) {
  useEffect(() => {
    return () => {
      console.log("unmounting");
    };
  }, [details]);

  return (
    <>
      <Details
        empId={details?.empId}
        details={details}
        isLoanDetails={isLoanDetails}
      ></Details>
    </>
  );
}

export default React.memo(EmployeeDetails);
