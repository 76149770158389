import { ADD_EMPLOYEE } from "../constants";
import { UPDATE_EMPLOYEE } from "../constants";

export const editRegForm = (details) => {
  console.log("Action",details)
  return {
    type: ADD_EMPLOYEE,
    payload: details,
  };
};

export const updateEmployee = (employeeData) => ({
  type: UPDATE_EMPLOYEE,
  payload: employeeData,
});
