import { ADD_EMPLOYEE } from "../constants";
import { UPDATE_EMPLOYEE } from "../constants";

const initialState = {
  details: null,
};

const regFromReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMPLOYEE:
      console.log(action.payload)
      console.log("States",{...state})
      return {
        ...state,
        details: action.payload,
      };

    case UPDATE_EMPLOYEE:
      const updatedEmployees = state.employees.map((emp) =>
        emp.empid === action.payload.empid ? action.payload : emp
      );
      return {
        ...state,
        details: updatedEmployees,
      };

    default:
      return state;
  }
};

export default regFromReducer;
