import userReducer from "./userReducer";
import employeeTypesFormReducer from "./employeeTypesFormReducer";
import regFromReducer from "./regFormReducer";
import salaryDetailsReducer from "./salaryDetailsReducer";
import siteReducer from "./siteReducer";
import employeesReducer from "./employeesReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  user: userReducer,
  employeeTypesForm: employeeTypesFormReducer,
  regForm: regFromReducer,
  salaryDetails: salaryDetailsReducer,
  siteReducer,
  employeesReducer,
});

export default rootReducer;
