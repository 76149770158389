import {
  Backdrop,
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import loader from "../../../assets/loading.svg";
import { Field, FormikProvider, useFormik } from "formik";
import MDButton from "components/MDButton";
import validationSchemaForm from "layouts/payslip/validationSchema/ValidationSchemaForm";
import MaterialTable from "material-table";

import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSunday,
  isWeekend,
} from "date-fns";
import Form from "../calculation/Form";
import EditForm from "./EditForm";

function DifferenceTable() {
  const [rows, setRows] = useState([]);
  const [success, setSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [err, setErr] = useState(false);

  const [monthAndYear, setMonthAndYear] = useState({
    month: "",
    year: "",
  });

  const initialValues = monthAndYear;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaForm,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const years = Array.from(
    { length: 2027 - 2019 + 1 },
    (_, index) => 2019 + index
  );

  const [employees, setEmployees] = useState(null);
  console.log(employees);
  const [apiError, setApiError] = useState("");
  const [isData, setIsData] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [editPassword, setEditPassword] = useState("shcEdit@202");

  const handleAlert = () => {
    const userInput = prompt("Enter Password to Edit Salary.");
    if (userInput === editPassword) return true;
    else return false;
  };

  const getWorkingDaysInMonth = () => {
    const date = new Date(`${monthAndYear.month}/3/${monthAndYear.year}`);
    const startOfMonthDate = startOfMonth(date);
    const endOfMonthDate = endOfMonth(date);
    const allDaysInMonth = eachDayOfInterval({
      start: startOfMonthDate,
      end: endOfMonthDate,
    });

    // Filter out Sundays
    const workingDays = allDaysInMonth.filter((day) => !isSunday(day));
    console.log(
      "month",
      workingDays.length,
      monthAndYear.month,
      monthAndYear.year
    );
    return workingDays.length;
  };

  const getData = () => {
    setSuccess(false);
    setFetched(false);
    setIsLoading(true);
    setRows([]);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/salary/getDated?dated=${monthAndYear.year}-${monthAndYear.month}`
      )
      .then((res) => {
        console.log(res);
        setEmployees(res.data.salaries);
        setApiError("");
        setIsData(true);

        if (res.data?.salaries) {
          addActionsToRows(res.data.salaries);
        }

        //   setRows(emp);
        setSuccess(true);
        setFetched(true);
        setIsLoading(false);
        console.log(res.data?.salaries);
      })
      .catch((error) => {
        setApiError("Something went wrong!!");
        setIsData(false);
        setErr(true);
        setSuccess(true);

        setIsLoading(false);
      });
  };
  const handleExport = () => {
    getData();
  };
  if (isData) {
    setIsData(false);
  }
  // useEffect(() => {
  //   axios({
  //     method: "get", //you can set what request you want to be
  //     url: `${process.env.REACT_APP_BASE_URL}/advance/all`,
  //   })
  //     .then((res) => {
  //       if (!fetched) {
  //         if (res.data?.advances) setRows(res.data.advances);

  //         //   setRows(emp);
  //         console.log(res.data?.advances);
  //         setSuccess(true);
  //         setFetched(true);
  //       }
  //     })
  //     .catch((err) => {
  //       setErr(true);
  //       setSuccess(true);
  //     });
  // }, []);

  // useEffect(() => {
  //   // Get the current date
  //   const currentDate = new Date();
  //   const currentMonth = currentDate.getMonth();
  //   const humanReadableMonth = currentMonth + 1;

  //   const currentYear = currentDate.getFullYear();
  //   console.log(humanReadableMonth,currentYear);
  //   setMonthAndYear({
  //     ...monthAndYear,
  //     month: humanReadableMonth,
  //     year: currentYear,
  //     });
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/salary/getDated?dated=${currentYear}-${humanReadableMonth}`
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setEmployees(res.data.salaries);
  //       setApiError("");
  //       setIsData(true);
  //       if (!fetched) {
  //         if (res.data?.salaries) setRows(res.data.salaries);

  //         //   setRows(emp);
  //         console.log(res.data?.salaries);
  //         setSuccess(true);
  //         setFetched(true);
  //       }
  //     })
  //     .catch((error) => {
  //       setApiError("Something went wrong!!");
  //       setIsData(false);
  //       setErr(true);
  //       setSuccess(true);
  //     });

  //   setEmployees('')

  // },[]);
  console.log(monthAndYear);
  let serialNumberCounter = 1;

  const handleDetails = (salary, open) => {
    if (!handleAlert()) {
      alert("Wrong Password! Please try again.");
      return;
    }

    console.log(salary, "selected salary");
    setDetails(salary);
    setOpenModal(open);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const addActionsToRows = (salaries) => {
    // Map through the salaries array and add the 'action' prop to each row
    const rowsWithActions = salaries.map((salary) => ({
      ...salary,
      action: (
        <MDBox sx={{ fontSize: "20px" }}>
          <EditIcon
            onClick={() => handleDetails(salary, true)}
            sx={{
              "&:hover": {
                color: "success.main",
                cursor: "pointer",
              },
            }}
          />
        </MDBox>
      ),
    }));

    // Set the updated rows to the state using setRows
    setRows(rowsWithActions);
  };

  const columns = [
    {
      title: "Emp_Id",
      field: "empId",
      render: (rowData) => <>{rowData?.empId && rowData.empId.toString()}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.empId.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Name",
      field: "employeeName",
      cellStyle: {
        cellWidth: "200px",
      },
      render: (rowData) => (
        <>{rowData.employeeName && rowData.employeeName.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.employeeName
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Dep. Site",
      field: "employeeDeploySite",
      render: (rowData) => (
        <>
          {(rowData.employeeDeploySite &&
            rowData.employeeDeploySite.toString()) ||
            "N/A"}
        </>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.employeeDeploySite &&
        rowData.employeeDeploySite
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },

    {
      title: "Attn.",
      field: "working_days",
      render: (rowData) => (
        <>
          {rowData.working_days && rowData.working_days.toString()}
          {" D"} <br /> {rowData.ot_hours && rowData.ot_hours.toString()} {" H"}
        </>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.working_days
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Actual Rate",
      field: "actualRate", // Assuming the field name is "EmployeeTypeId" in the response JSON
      render: (rowData) => {
        if (rowData?.payment_type === "Actual")
          return <>{rowData.actualRate && rowData.actualRate.toString()}</>;
        else {
          const totalDays = getWorkingDaysInMonth();
          const rate = (rowData?.fixed_rate / totalDays).toFixed(2);
          return <>{rate?.toString()}</>;
        }
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.actualRate
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Attn. Amt",
      field: "attendance_amount",
      render: (rowData) => (
        <>{rowData.attendance_amount && rowData.attendance_amount.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.attendance_amount
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "OT Amt.",
      field: "ot_amount",
      render: (rowData) => (
        <>{rowData.ot_amount && rowData.ot_amount.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.ot_amount.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Total AR",
      field: "total_ar_amount",
      render: (rowData) => (
        <>{rowData.total_ar_amount && rowData.total_ar_amount.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.total_ar_amount
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Total Advance",
      field: "total_advance",
      render: (rowData) => (
        <>
          {rowData.total_advance
            ? rowData.total_advance.toString()
            : rowData.advance
            ? rowData.advance.toString()
            : "0"}{" "}
        </>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.advance.toString().toLowerCase().indexOf(term.toLowerCase()) >
        -1,
    },
    {
      title: "Basic Amt.",
      field: "basic_amount",
      render: (rowData) => (
        <>{rowData.basic_amount && rowData.basic_amount.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.basic_amount
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    // {
    //   title: "OT Hrs",
    //   field: "ot_hours",
    //   render: (rowData) => (
    //     <>{rowData.ot_hours && rowData.ot_hours.toString()}</>
    //   ),
    //   customFilterAndSearch: (term, rowData) =>
    //     rowData.ot_hours.toString().toLowerCase().indexOf(term.toLowerCase()) >
    //     -1,
    // },

    // {
    //   title: "Good Attendance",
    //   field: "good_attendance",
    //   render: (rowData) => (
    //     <>{rowData.good_attendance && rowData.good_attendance.toString()}</>
    //   ),
    //   customFilterAndSearch: (term, rowData) =>
    //     rowData.good_attendance
    //       .toString()
    //       .toLowerCase()
    //       .indexOf(term.toLowerCase()) > -1,
    // },

    // // {
    // //   title: "National Holiday.",
    // //   field: "national_holiday",
    // //   render: (rowData) => (
    // //     <>{rowData.national_holiday && rowData.national_holiday.toString()}</>
    // //   ),
    // //   customFilterAndSearch: (term, rowData) =>
    // //     rowData.national_holiday
    // //       .toString()
    // //       .toLowerCase()
    // //       .indexOf(term.toLowerCase()) > -1,
    // // },

    {
      title: "ESI",
      field: "esi",
      render: (rowData) => <>{rowData.esi && rowData.esi.toString()}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.esi.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "PF",
      field: "pf",
      render: (rowData) => <>{rowData.pf && rowData.pf.toString()}</>,
      customFilterAndSearch: (term, rowData) =>
        rowData.pf.toString().toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "MR Amt.",
      field: "gross_amount",
      render: (rowData) => (
        <>{rowData.gross_amount && rowData.gross_amount.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.gross_amount
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Difference Amt",
      field: "difference_amount",
      render: (rowData) => (
        <>{rowData.difference_amount && rowData.difference_amount.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.difference_amount
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "INC Rate",
      field: "incentive",
      render: (rowData) => (
        <>{rowData.incentive && rowData.incentive.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.incentive_amount
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "INC Amt",
      field: "incentive_amount",
      render: (rowData) => (
        <>{rowData.incentive_amount && rowData.incentive_amount.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.incentive_amount
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },

    {
      title: "Grand Total",
      field: "grand_total",
      render: (rowData) => (
        <>{rowData.grand_total && rowData.grand_total.toString()}</>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.grand_total
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1,
    },

    {
      title: "Action/Edit",
      field: "action",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          border: "none",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        <EditForm details={details} close={handleClose}></EditForm>
      </Modal>

      <Card
        sx={{
          margin: "2rem 0",
        }}
      >
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Difference Table
          </MDTypography>
        </MDBox>

        <Box px={2} py={3} sx={{ backgroundColor: "#f0f2f5" }}>
          <Grid container py={3} sx={{ justifyContent: "space-between" }}>
            <Grid item xs={12} sm={12} md={3.2} sx={{ marginInlineEnd: 8 }}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-label">
                  Select Month
                </InputLabel>
                <FormikProvider value={formik}>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={monthAndYear.month}
                    label="Select Month"
                    onChange={(e) => {
                      setMonthAndYear({
                        ...monthAndYear,
                        [e.target.name]: e.target.value,
                      });
                      handleChange(e);
                    }}
                    name="month"
                    p={2}
                    sx={{
                      height: "35px",
                    }}
                    error={touched.month && errors.month}
                  >
                    {months.map((category) => (
                      <MenuItem key={category.value} value={category.value}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Field>
                  {errors.month && touched.month && (
                    <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                      {errors.month}
                    </FormHelperText>
                  )}
                </FormikProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3.2}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-label">
                  Select Year
                </InputLabel>
                <FormikProvider value={formik}>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={monthAndYear.year}
                    label="Select Year"
                    onChange={(e) => {
                      setMonthAndYear({
                        ...monthAndYear,
                        [e.target.name]: e.target.value,
                      });
                      handleChange(e);
                    }}
                    name="year"
                    p={2}
                    sx={{
                      height: "35px",
                    }}
                    error={touched.year && errors.year}
                  >
                    {years.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Field>
                  {errors.year && touched.year && (
                    <FormHelperText sx={{ color: "red", fontSize: "12px" }}>
                      {errors.year}
                    </FormHelperText>
                  )}
                </FormikProvider>
              </FormControl>
            </Grid>
            <Box sx={{ flex: "1 1 auto" }} />
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleExport}
              sx={{
                color: "white",
                background: "#232526",
                background:
                  "-webkit-linear-gradient(to right, #232526, #414345)",
                background: "linear-gradient(to right, #232526, #414345)",
              }}
            >
              GET DATA
            </MDButton>
            {apiError && (
              <FormHelperText
                sx={{
                  color: "red",
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "0.5rem",
                }}
              >
                {apiError}
              </FormHelperText>
            )}
          </Grid>
        </Box>
        {isloading && (
          <Box
            sx={{
              marginBottom: "3rem",
              display: "flex",
              justifyContent: "center",
              pt: 2,
              paddingRight: "30px",
            }}
          >
            <img
              src={loader}
              alt="loader"
              style={{ width: "35px", height: "35px" }}
            />
          </Box>
        )}
      </Card>

      <MaterialTable
        tableH
        muiTableBodyProps={{
          sx: {
            //stripe the rows, make odd rows a darker color
            "& tr:nth-of-type(odd)": {
              backgroundColor: "#f5f5f5",
            },
          },
        }}
        sx={{
          fontSize: "10px !important",
          color: "white !important",
          borderRadius: "20px !important",
          boxShadow: "none",
        }}
        icons={{
          fontSize: "10px !important",
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Search",
            fontSize: "10px",
          },
        }}
        options={{
          sorting: true,
          search: true,
          searchFieldAlignment: "right",
          searchAutoFocus: true,
          searchFieldVariant: "standard",
          filtering: true,
          paging: true,
          pageSizeOptions: [10, 20, 50, 100],
          pageSize: 10,
          paginationType: "stepped",

          paginationPosition: "both",
          exportButton: true,
          exportAllData: true,
          exportFileName: "Employees",
          addRowPosition: "first",
          actionsColumnIndex: -1,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          selectionProps: (rowData) => ({
            disabled: rowData.age == null,
          }),
          columnsButton: true,
          rowStyle: (data, index) =>
            index % 2 === 0
              ? { background: "#f5f5f5", fontSize: "14px" }
              : { fontSize: "14px" },
          headerStyle: { background: "#376fd0", color: "#fff" },
        }}
        columns={columns}
        data={rows}
        title=" "
        style={{
          borderRadius: "20px !important",
        }}
      />
    </DashboardLayout>
  );
}

export default DifferenceTable;
