import { Person, Print } from "@mui/icons-material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import loader from "../../assets/loading.svg";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useLocation, useParams } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { Box } from "@mui/system";
import MaterialReactTable from "material-react-table";
import jsPDF from "jspdf";
import MDBadge from "components/MDBadge";

function Details() {
  // let { site } = useLocation();
  // console.log(site, "site1");

  const Employee = ({ name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Person></Person>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="regular">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{id}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="regular"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const site = useSelector((state) => state.siteReducer.site);
  console.log(site, "site");

  const [employees, setEmployees] = useState([]);

  const [rows, setRows] = useState([]);

  const [success, setSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [fetched, setFetched] = useState(false);

  const columns = [
    {
      title: "Name",
      field: "employeeName",
    },
    {
      title: "Emp_Id",
      field: "empId",
      // width:"0%",
    },

    {
      title: "Type",
      field: "employeeType",
    },
    {
      title: "Designation",
      field: "employeeDesignation",
    },
    {
      title: "Contact",
      field: "contact",
    },

    {
      title: "Sfty Pass No",
      field: "safetyPassNo",
    },

    {
      title: "JNTVTI Cert. No",
      field: "jntvtiCertNo",
    },
  ];

  const downloadPdf = () => {
    const doc = new jsPDF();
    doc.text(`Employee Details - ${site}`, 15, 10);
    doc.autoTable({
      theme: "striped",
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: employees,
    });
    doc.save(`${site}.pdf`);
  };
  const fetchEmployees = () => {
    if (site)
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/employee`, {
          params: {
            site: site,
          },
        })
        .then((res) => {
          if (res.data.employees.length == 0) return;

          setEmployees(res.data.employees);
          // console.log(res.data.employees);
          if (true) {
            if (res.data.employees)
              res.data.employees.map((data) => {
                setRows((prev) => [
                  ...prev,
                  {
                    name: <Employee name={data.employeeName} />,
                    empId: (
                      <MDTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        {data.empId}
                      </MDTypography>
                    ),
                    type: (
                      <Job
                        title={data.employeeType}
                        description={data.employeeDesignation}
                      />
                    ),
                    status: (
                      <MDBox ml={-1}>
                        <MDBadge
                          badgeContent="Available"
                          color="success"
                          variant="gradient"
                          size="sm"
                        />
                      </MDBox>
                    ),
                    contact: (
                      <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        {data.contact.toString()}
                      </MDTypography>
                    ),
                    jntvtiCertNo: (
                      <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        {data.jntvtiCertNo}
                      </MDTypography>
                    ),
                  },
                ]);
                console.log("first inside");
              });

            //   setRows(emp);
            setSuccess(true);
            console.log(rows, "rows");
            console.log("first");
            setFetched(true);

            // recursive call to fetch next 10 employees until no employees are left
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          setSuccess(true);
        });
  };

  useEffect(() => {
    fetchEmployees();
  }, [site]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={4}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Employees Table
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                {!success && (
                  <Box
                    sx={{
                      marginBottom: "3rem",
                      display: "flex",
                      justifyContent: "center",
                      pt: 2,
                      paddingRight: "30px",
                    }}
                  >
                    <img
                      src={loader}
                      alt="loader"
                      style={{ width: "35px", height: "35px" }}
                    />
                  </Box>
                )}
                {success && (
                  <MaterialReactTable
                    sx={{
                      fontSize: "10px !important",
                      color: "white !important",
                    }}
                    icons={{
                      fontSize: "10px !important",
                    }}
                    localization={{
                      toolbar: {
                        searchPlaceholder: "Search",
                        fontSize: "10px",
                      },
                    }}
                    options={{
                      sorting: true,
                      search: true,
                      searchFieldAlignment: "right",
                      searchAutoFocus: true,
                      searchFieldVariant: "standard",
                      filtering: false,
                      labelRowsPerPage: "",
                      paging: true,
                      pageSizeOptions: [],
                      pageSize: 10,
                      paginationType: "stepped",
                      showFirstLastPageButtons: false,
                      // exportButton: true,
                      // exportAllData: true,
                      // exportFileName: "items",
                      addRowPosition: "first",
                      actionsColumnIndex: -1,
                      showSelectAllCheckbox: false,
                      showTextRowsSelected: false,

                      columnsButton: false,
                      rowStyle: (data, index) =>
                        index % 2 === 0 ? { background: "#f5f5f5" } : null,
                      headerStyle: { background: "#1F78E9", color: "#fff" },
                    }}
                    actions={[
                      {
                        icon: () => <Print fontSize="small" />, // you can pass icon too
                        tooltip: "Export to Pdf",

                        onClick: () => downloadPdf(),
                        isFreeAction: true,
                      },
                    ]}
                    columns={[
                      {
                        title: "Name",
                        field: "name",
                        width: "10%",
                        render: (rowData) => <>{rowData.name}</>,
                        customFilterAndSearch: (term, rowData) =>
                          rowData.name.props.name
                            .toLowerCase()
                            .indexOf(term.toLowerCase()) > -1,
                      },
                      {
                        title: "Emp_Id",
                        field: "empId",
                        // width:"0%",
                        render: (rowData) => <>{rowData.empId}</>,
                        customFilterAndSearch: (term, rowData) =>
                          rowData.empId.props.children
                            .toLowerCase()
                            .indexOf(term.toLowerCase()) > -1,
                      },

                      {
                        title: "Type",
                        field: "Type",
                        render: (rowData) => <>{rowData.type}</>,
                      },
                      {
                        title: "Status",
                        field: "status",
                        render: (rowData) => <>{rowData.status}</>,
                      },
                      {
                        title: "Contact",
                        field: "contact",
                        render: (rowData) => <>{rowData.contact}</>,
                        customFilterAndSearch: (term, rowData) =>
                          rowData.contact.props.children
                            .toLowerCase()
                            .indexOf(term.toLowerCase()) > -1,
                      },
                      {
                        title: "Jntvti Cert No.",
                        field: "jntvtiCertNo",
                        render: (rowData) => <>{rowData.jntvtiCertNo}</>,
                        customFilterAndSearch: (term, rowData) =>
                          rowData.jntvtiCertNo.props.children
                            .toLowerCase()
                            .indexOf(term.toLowerCase()) > -1,
                      },
                    ]}
                    data={rows}
                    title=" "
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Details;
