import { Box, Card, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MaterialReactTable from "material-react-table";
import React, { useEffect, useState } from "react";

import loader from "../../../assets/loading.svg";

function LoanTable() {
  const [rows, setRows] = useState([]);
  const [success, setSuccess] = useState(false);
  const [details, setDetails] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [err, setErr] = useState(false);
  useEffect(() => {
    axios({
      method: "get", //you can set what request you want to be
      url: `${process.env.REACT_APP_BASE_URL}/advance/all`,
    })
      .then((res) => {
        if (!fetched) {
          if (res.data?.advances) setRows(res.data.advances);

          //   setRows(emp);
          console.log(res.data?.advances);
          setSuccess(true);
          setFetched(true);
        }
      })
      .catch((err) => {
        setErr(true);
        setSuccess(true);
      });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        sx={{
          margin: "2rem 0",
        }}
      >
        <MDTypography variant="h6" color="white">
          Advance Table
        </MDTypography>
      </MDBox>

      {!success && (
        <Box
          sx={{
            margin: "2rem 0",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            paddingRight: "30px",
          }}
        >
          <img
            src={loader}
            alt="loader"
            style={{ width: "35px", height: "35px" }}
          />
        </Box>
      )}
      {success && (
        <MaterialReactTable
          sx={{
            fontSize: "10px !important",
            color: "white !important",
          }}
          icons={{
            fontSize: "10px !important",
          }}
          localization={{
            toolbar: {
              searchPlaceholder: "Search",
              fontSize: "10px",
            },
          }}
          options={{
            sorting: true,
            search: true,
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            labelRowsPerPage: "",
            paging: true,
            pageSizeOptions: [],
            pageSize: 10,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            // exportButton: true,
            // exportAllData: true,
            // exportFileName: "items",
            addRowPosition: "first",
            actionsColumnIndex: -1,
            showSelectAllCheckbox: false,
            showTextRowsSelected: false,

            columnsButton: false,
            // headerStyle: { fontSize:"14px",textAlign:"center", background:" #00c6ff" ,
            // background:" -webkit-linear-gradient(to right, #0072ff, #00c6ff)"  ,
            // background: "linear-gradient(to right, #0072ff, #00c6ff)" },

            headerStyle: {
              background: "#1F78E9",
              color: "#fff",
              fontSize: "14px",
              textAlign: "left",
            },
            rowStyle: (data, index) =>
              index % 2 === 0
                ? {
                    background: "#f5f5f5",
                    fontSize: "14px",
                    textAlign: "center",
                  }
                : {
                    background: "white",
                    fontSize: "14px",
                    textAlign: "center",
                  },
          }}
          columns={[
            { header: "Emp ID", accessorKey: "empId" },
            { header: "Emp Name", accessorKey: "employeeName" },

            { header: "Total Advance", accessorKey: "advance" },

            {
              header: "Monthly Deduction",
              accessorKey: "monthlyDeduction",
            },

            { header: "Loan Date", accessorKey: "date" },
          ]}
          data={rows}
          title=" "
        />
      )}
    </DashboardLayout>
  );
}

export default LoanTable;
