import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import loader from "../../assets/loading.svg";
import actions from "redux/actions";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function SiteDetails() {
  const [siteCount, setSiteCount] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setSite } = actions;

  const fetchSiteDetails = () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/employee/count`;

    const promises = [];

    sites.forEach((site) => {
      const promise = axios.get(url, {
        params: {
          site: site,
        },
      });
      promises.push(promise);
    });

    Promise.allSettled(promises)
      .then((values) => {
        console.log(values);
        values.map((value, index) => {
          if (value.status === "fulfilled") {
            setSiteCount((prev) => {
              return {
                ...prev,
                [sites[index]]: value.value.data.count,
              };
            });
          }
        });
        setLoading(false);
        console.log(siteCount);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const colors = ["dark", "info", "success", "primary"];
  const sites = [
    "COKE PLANT",
    "RMM",
    "RMBB",
    "RMBB2",
    "GBF",
    "SP#3,4",
    "SP#1,2",
    "MM",
    "LD#1",
    "PP",
    "MRSPP",
    "LINE PLANT",
  ];

  const handleRoute = (site) => {
    console.log(site);
    dispatch(setSite(site));
    navigate(`/site-details/${site}`);
  };

  useEffect(() => {
    fetchSiteDetails();
    return () => {
      console.log("unmounting");
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid
        container
        spacing={3}
        sx={{ marginTop: "2rem" }}
        justifyContent={"center"}
      >
        {loading && (
          <Box
            sx={{
              marginBottom: "3rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: 2,
              paddingRight: "30px",
            }}
          >
            <img
              src={loader}
              alt="loader"
              style={{ width: "35px", height: "35px" }}
            />
          </Box>
        )}
        {!loading &&
          sites.map((site, index) => {
            return (
              <Grid item xs={12} md={6} lg={3}>
                <Box
                  mb={1.5}
                  fullWidth
                  margin={"1rem 0"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleRoute(site)}
                >
                  <ComplexStatisticsCard
                    color={colors[index % 4]}
                    icon="account_balance"
                    title={site}
                    count={siteCount[site] || 0}
                    site={true}
                    percentage={{
                      color: "dark",
                      amount: "Site Strength",
                      label: "100",
                    }}
                  />
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </DashboardLayout>
  );
}

export default SiteDetails;
