import {LOGIN} from '../constants'

const initialState = {
    details: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null,
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                details: action.payload
            }
        default:
            return state;
    }
}

export default userReducer;