import {
  Alert,
  Box,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSunday,
  isWeekend,
} from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";

import loader from "../../../assets/loadingWhite.svg";
import Details from "./Details";
import PaySlip from "./PaySlip";
import useZustandStore from "redux/zustandStore";
function Form({ details, close }) {
  console.log("details", details);

  const myForm = React.useRef(null);

  const { attendanceMonthAndYear, employeeLastMonthAttendance } =
    useZustandStore();

  const [loading, setLoading] = useState(false);
  const getWorkingDaysInMonth = (
    inputDate = new Date(
      `${attendanceMonthAndYear.month}/28/${attendanceMonthAndYear.year}`
    )
  ) => {
    const startOfMonthDate = startOfMonth(inputDate);
    const endOfMonthDate = endOfMonth(inputDate);
    const allDaysInMonth = eachDayOfInterval({
      start: startOfMonthDate,
      end: endOfMonthDate,
    });

    // Filter out Sundays
    const workingDays = allDaysInMonth.filter((day) => !isSunday(day));
    return workingDays.length;
  };

  const workingDays = getWorkingDaysInMonth();
  console.log("No of working days ", workingDays);
  const [formDetails, setFormDetails] = useState({
    date: new Date(
      `${attendanceMonthAndYear.month}/28/${attendanceMonthAndYear.year}`
    ).toLocaleDateString(),
    advance: 0,
    working_days: 0,
    mr_days: 0,
    good_attendance: 0,
    national_holiday: 0,
    daily_wage: 0,
    ot_hours: 0,
    fixed_rate_month: getWorkingDaysInMonth(),
    custom_ot_amount: 0,
    custom_incentive_amount: 0,
    incentive_days: 0,
    mr_advance: 0,
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [checked, setChecked] = useState(true);
  const [mDeduc, setMDeduc] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [calculations, setCalculations] = useState({});
  const [dummyDate, setDummyDate] = useState(new Date());
  const [paymentType, setPaymentType] = useState("Actual");
  const [cal_rate, setCal_rate] = useState(details?.fixed_rate / 26);
  const [cal_date, setCal_date] = useState(new Date());

  const [customCheck, setCustomCheck] = useState(false);

  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    const otHoursRegex = /^\d+(\.\d{0,2})?$/; // Allow up to 2 decimal places for ot_hours

    if (e.target.name === "working_days" && e.target.value > 31) return;

    if (e.target.name === "good_attendance") {
      setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
      return;
    }

    if (
      (e.target.name === "ot_hours" || e.target.name === "working_days") &&
      otHoursRegex.test(e.target.value)
    ) {
      setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
      return;
    }

    if (e.target.value === "")
      setFormDetails({ ...formDetails, [e.target.name]: 0 });

    if (re.test(e.target.value))
      setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
    return;
  };

  const handleDate = (d) => {
    const date = new Date(d);

    const formattedDate = date.toLocaleString("en-US").split(",")[0];
    console.log(formattedDate, "formattedDate");
    console.log("dummyDate", dummyDate);
    setDummyDate(date);
    setFormDetails({
      ...formDetails,
      salary_date: formattedDate,
      fixed_rate_month: getWorkingDaysInMonth(date),
    });
    return formattedDate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formDetails, "formDetails");
    console.log("submitted");
    setLoading(true);

    const date = new Date().toLocaleString("en-US").split(",")[0];

    console.log("date inside submit", date);
    const payload = {
      ...calculations,
      empId: details.empId,
      good_attendance: parseFloat(formDetails.good_attendance),
      working_days: parseFloat(formDetails.working_days),

      mr_days: parseInt(formDetails.mr_days),
      salary_date: formDetails?.salary_date || date,
    };
    console.log("payload", payload);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/salary/store`,
      data: payload,
    })
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        setShowDetails(true);
      })
      .catch((err) => {
        console.log(err, "error");
        setLoading(false);
        setError(true);
        setShowDetails(true);
      });
  };

  const roundToTwoDecimal = (value, key) => {
    if (isNaN(value) || typeof value !== "number") {
      // Handle NaN or non-numeric input
      // You can choose to throw an error, return a default value, or handle it differently based on your requirements.
      return 0; // Default value, you can modify it as needed.
    }

    if (key === "ESI") return Math.ceil(value);
    if (key === "PF") return Math.floor(value);

    return Math.round(value);
  };

  const calculateSalary = () => {
    let {
      working_days,
      mr_days,
      good_attendance,
      advance,
      national_holiday,
      daily_wage,
      ot_hours,
      fixed_rate_month,
      custom_ot_amount,
      custom_incentive_amount,
      incentive_days,
      mr_advance,
    } = formDetails;

    working_days = parseFloat(working_days);

    mr_days = parseInt(mr_days);
    good_attendance = parseFloat(good_attendance);
    advance = parseFloat(advance);
    national_holiday = parseFloat(national_holiday) || 0;
    daily_wage = parseFloat(daily_wage);
    ot_hours = parseFloat(ot_hours);
    fixed_rate_month = parseInt(fixed_rate_month) || 26;
    custom_incentive_amount = parseFloat(custom_incentive_amount);
    custom_ot_amount = parseFloat(custom_ot_amount);
    incentive_days = parseFloat(incentive_days);
    mr_advance = parseInt(mr_advance);

    console.log(
      "calculating salary",
      working_days,
      good_attendance,
      advance,
      national_holiday,
      daily_wage,
      incentive_days
    );

    if (details?.actualRate === undefined) details.actualRate = 0;

    if (details?.incentive === undefined) details.incentive = 0;

    if (details?.otType === undefined) details.otType = 0;

    let attendance_amount;

    let rate;
    if (details?.payment_type == "Actual")
      rate = parseFloat(details?.actualRate);
    else rate = parseFloat(details?.fixed_rate) / fixed_rate_month;

    attendance_amount = roundToTwoDecimal(rate * working_days);

    const ot_rate = (rate / 8) * parseFloat(details?.otType);

    if (!customCheck) {
      const ot_amount = roundToTwoDecimal(ot_rate * ot_hours);
      const total_ar_amount = roundToTwoDecimal(attendance_amount + ot_amount);
      const basic_amount = roundToTwoDecimal(
        daily_wage * mr_days + national_holiday + good_attendance
      );
      const ESI = roundToTwoDecimal((basic_amount * 0.75) / 100, "ESI");
      const PF = roundToTwoDecimal((basic_amount * 12) / 100);
      const gross_amount =
        roundToTwoDecimal(basic_amount - (ESI + PF)) - mr_advance;
      const incentive_amount = roundToTwoDecimal(
        parseFloat(details.incentive) * (working_days + incentive_days)
      );
      console.log(
        "incentive_amount",
        incentive_amount,
        working_days,
        incentive_days
      );
      let difference_amount;
      if (checked)
        difference_amount = roundToTwoDecimal(
          total_ar_amount - PF - ESI - gross_amount - advance
        );
      else
        difference_amount = roundToTwoDecimal(
          total_ar_amount - PF - ESI - gross_amount
        );
      const grand_total = roundToTwoDecimal(
        difference_amount + incentive_amount
      );

      setCalculations({
        basic_amount,
        esi: ESI,
        pf: PF,
        ot_amount,
        gross_amount,
        attendance_amount,
        difference_amount,
        grand_total,
        incentive_amount,
        total_ar_amount,
        ot_hours,
        advance,
        mr_advance,
        total_advance: advance + mr_advance,
        national_holiday,
      });
    } else {
      const ot_amount = roundToTwoDecimal(custom_ot_amount);
      const total_ar_amount = roundToTwoDecimal(
        attendance_amount + custom_ot_amount
      );
      const basic_amount = roundToTwoDecimal(
        daily_wage * working_days + national_holiday + good_attendance
      );
      const ESI = roundToTwoDecimal((basic_amount * 0.75) / 100, "ESI");
      const PF = roundToTwoDecimal((basic_amount * 12) / 100);
      const gross_amount = roundToTwoDecimal(basic_amount - (ESI + PF));
      const incentive_amount = roundToTwoDecimal(custom_incentive_amount);
      let difference_amount;
      if (checked)
        difference_amount = roundToTwoDecimal(
          total_ar_amount - PF - ESI - gross_amount - advance
        );
      else
        difference_amount = roundToTwoDecimal(
          total_ar_amount - PF - ESI - gross_amount
        );
      const grand_total = roundToTwoDecimal(
        difference_amount + incentive_amount
      );

      setCalculations({
        basic_amount,
        esi: ESI,
        pf: PF,
        ot_amount,
        gross_amount,
        attendance_amount,
        difference_amount,
        grand_total,
        incentive_amount,
        total_ar_amount,
        ot_hours,
        advance,
        national_holiday,
      });
    }
  };

  useEffect(() => {
    calculateSalary();
  }, [formDetails, checked]);

  useEffect(() => {
    const fetchData = async () => {
      let advanceResponse, employeeTypesResponse;

      try {
        [advanceResponse, employeeTypesResponse] = await Promise.allSettled([
          axios.get(
            `${process.env.REACT_APP_BASE_URL}/advance?empId=${details?.empId}`
          ),
          axios.get(`${process.env.REACT_APP_BASE_URL}/employee/getTypes`),
        ]);
      } catch (error) {
        console.log(error);
      }

      console.log("okey", employeeTypesResponse, advanceResponse, "\n\n");
      const monthlyDeduction =
        advanceResponse?.value?.data?.advance[0]?.monthlyDeduction;
      const employeeTypes = employeeTypesResponse?.value?.data?.types;
      const employeeType = details?.employeeType;

      const daily_wage =
        employeeTypes?.find((item) => item.employeeType === employeeType)
          ?.dailyWage || 0;

      setFormDetails((prevState) => ({
        ...prevState,
        daily_wage,
        advance: monthlyDeduction || 0,
      }));
    };

    if (details?.empId && details?.employeeType) fetchData();
  }, [details?.empId, details?.employeeType]);

  useEffect(() => {
    if (employeeLastMonthAttendance) {
      employeeLastMonthAttendance.filter((item) => {
        if (item.empId === details?.empId) {
          setFormDetails((prevState) => ({
            ...prevState,
            working_days: item?.numDays,
            ot_hours: item?.otHours,
            mr_days: item?.numDays,
          }));
        }
      });
    }
  }, [employeeLastMonthAttendance]);

  return (
    <Card
      sx={{
        width: { md: "90%", xs: "90%", sm: "90%", lg: "70%" },
      }}
    >
      {!showDetails ? (
        <Grid
          container
          p={2}
          //   py={5}
          sx={{
            gap: "1rem",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          {error && (
            <Typography variant="caption" color={"red"}>
              Please Fill all the fields
            </Typography>
          )}
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <Typography variant="h5">Salary Calculation</Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <Typography variant="caption" fontWeight={"bold"}>
                ID : {details?.empId}
              </Typography>
              <Typography variant="caption" fontWeight={"bold"}>
                Name : {details?.employeeName}
              </Typography>
              <Typography variant="caption" fontWeight={"bold"}>
                Employee Type : {details?.employeeType}
              </Typography>
              <Typography variant="caption" fontWeight={"bold"}>
                Payment Type : {details?.payment_type}
              </Typography>
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Typography
                variant="caption"
                fontWeight={"bold"}
                sx={{
                  fontSize: "16px",
                }}
              >
                Daily Wage : {formDetails?.daily_wage}
              </Typography>
              <Typography
                variant="caption"
                fontWeight={"bold"}
                sx={{
                  fontSize: "16px",
                }}
              >
                Incentive Rate : {details?.incentive}
              </Typography>
              {details?.payment_type == "Actual" && (
                <Typography
                  variant="caption"
                  fontWeight={"bold"}
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  Actual Rate: {details?.actualRate}
                </Typography>
              )}
              {details?.payment_type == "Fixed" && (
                <Typography
                  variant="caption"
                  fontWeight={"bold"}
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  Fixed Rate:{" "}
                  {details?.fixed_rate
                    ? (
                        details?.fixed_rate / formDetails.fixed_rate_month
                      ).toFixed(2)
                    : 0}{" "}
                  - ({formDetails.fixed_rate_month} days)
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                }}
              >
                Custom Calc.
              </Typography>
              <Checkbox
                onChange={(e) => {
                  setCustomCheck(e.target.checked);
                  setCalculations({});
                  setFormDetails({
                    date: new Date().toLocaleDateString(),
                    advance: 0,
                    working_days: 0,
                    good_attendance: 0,
                    national_holiday: 0,
                    daily_wage: 0,
                    ot_hours: 0,
                    fixed_rate_month: workingDays,
                    custom_ot_amount: 0,
                    custom_incentive_amount: 0,
                  });
                }}
                defaultChecked={customCheck}
                value={customCheck}
              />
            </Box>
          </Box>
          <Grid
            item
            component={"form"}
            sx={{
              gap: "2.5rem",
              flexDirection: "column",
            }}
          >
            <form ref={myForm}>
              <Box
                sx={{
                  flexDirection: { sm: "column", xs: "column", md: "row" },
                  display: "flex",
                  gap: "1rem",
                  // justifyContent:"center",
                  alignItems: "center",
                }}
                key={"0"}
                fullWidth
              >
                <Box fullWidth my={1} key="0" sx={{ width: "100%" }}>
                  <TextField
                    id="outlined-basic"
                    name="date"
                    label="Date"
                    variant="outlined"
                    required
                    fullWidth
                    value={formDetails?.date || ""}
                    sx={{
                      pointerEvents: "none",
                    }}
                  />
                </Box>

                <Box my={1} sx={{ width: "100%" }} key="1">
                  <TextField
                    id="outlined-basic"
                    name="working_days"
                    onChange={handleChange}
                    label="No. of Working Days"
                    variant="outlined"
                    required
                    fullWidth
                    value={formDetails.working_days || ""}
                  />
                </Box>
                <Box my={1} sx={{ width: "100%" }} key="3">
                  <TextField
                    id="outlined-basic"
                    name="mr_days"
                    onChange={handleChange}
                    label="MR Days"
                    variant="outlined"
                    fullWidth
                    value={formDetails.mr_days || ""}
                  />
                </Box>
                <Box my={1} sx={{ width: "100%" }} key="2">
                  <TextField
                    id="outlined-basic"
                    name="good_attendance"
                    onChange={handleChange}
                    label="Good Attendance"
                    variant="outlined"
                    select
                    required
                    fullWidth
                    InputProps={{
                      style: {
                        height: "45px", // Custom height styling
                      },
                    }}
                    value={formDetails.good_attendance || 0}
                  >
                    <MenuItem value={0}>0.0</MenuItem>
                    <MenuItem value={1.4}>1.4</MenuItem>
                    <MenuItem value={2.8}>2.8</MenuItem>
                    <MenuItem value={4.2}>4.2</MenuItem>
                    <MenuItem value={5.6}>5.6</MenuItem>
                  </TextField>
                </Box>
              </Box>

              <Box
                sx={{
                  flexDirection: { sm: "column", xs: "column", md: "row" },
                  display: "flex",
                  gap: "1rem",
                  // justifyContent:"center",
                  alignItems: "center",
                  marginTop: "1.2rem",
                }}
                key={"1"}
                fullWidth
              >
                <Box my={1} sx={{ width: "100%" }} key="41">
                  <TextField
                    id="outlined-basic"
                    name="ot_hours"
                    onChange={handleChange}
                    label="OT Hours"
                    variant="outlined"
                    fullWidth
                    value={formDetails.ot_hours || ""}
                  />
                </Box>
                <Box my={1} sx={{ display: "flex", width: "100%" }} key="5">
                  <TextField
                    id="outlined-basic"
                    name="advance"
                    // defaultValue={mDeduc}
                    onChange={handleChange}
                    label="Advance"
                    variant="outlined"
                    fullWidth
                    value={formDetails.advance}
                    disabled={!checked}
                  />
                  <Checkbox
                    onChange={(e) => setChecked(e.target.checked)}
                    defaultChecked
                  />
                </Box>
                <Box my={1} sx={{ width: "100%" }} key="4">
                  <TextField
                    id="outlined-basic"
                    name="mr_advance"
                    onChange={handleChange}
                    label="MR Advance"
                    variant="outlined"
                    fullWidth
                    value={formDetails.mr_advance || ""}
                  />
                </Box>

                {/* <Box my={1} sx={{ width: "100%" }} key="3">
                  <TextField
                    id="outlined-basic"
                    name="national_holiday"
                    onChange={handleChange}
                    label="National Holiday"
                    variant="outlined"
                    fullWidth
                    value={formDetails.national_holiday || ""}
                  />
                </Box> */}

                <Box my={1} sx={{ width: "100%" }} key="2">
                  <TextField
                    id="outlined-basic"
                    name="incentive_days"
                    onChange={handleChange}
                    label="Extra Incentive Days"
                    variant="outlined"
                    select
                    fullWidth
                    InputProps={{
                      style: {
                        height: "45px", // Custom height styling
                      },
                    }}
                    value={formDetails.incentive_days || 0}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>

                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </TextField>
                  {formDetails?.incentive_days !== 0 && (
                    <Typography
                      sx={{
                        fontSize: "11px",
                        position: "absolute",
                        right: "18px",
                        color: "green",
                        opacity: 0.8,
                        fontWeight: "bold",
                      }}
                    >
                      ₹ {formDetails?.incentive_days * details?.incentive}
                    </Typography>
                  )}
                </Box>
              </Box>

              {customCheck && (
                <Box
                  sx={{
                    flexDirection: { sm: "column", xs: "column", md: "row" },
                    display: "flex",
                    gap: "1rem",
                    // justifyContent:"center",
                    alignItems: "center",
                    marginTop: "1.2rem",
                  }}
                  key={"231"}
                  fullWidth
                >
                  <Box my={1} sx={{ width: "100%" }} key="399">
                    <TextField
                      id="outlined-basic"
                      name="custom_ot_amount"
                      onChange={handleChange}
                      label="Custom OT Amt."
                      variant="outlined"
                      fullWidth
                      value={formDetails.custom_ot_amount || ""}
                    />
                  </Box>
                  <Box my={1} sx={{ width: "100%" }} key="333">
                    <TextField
                      id="outlined-basic"
                      name="custom_incentive_amount"
                      onChange={handleChange}
                      label="Custom Incentive Amt."
                      variant="outlined"
                      fullWidth
                      value={formDetails.custom_incentive_amount || ""}
                    />
                  </Box>
                </Box>
              )}

              <Divider
                sx={{
                  height: "2px",
                  width: "100%",
                  background: "gray",
                }}
              ></Divider>
              <Box
                sx={{
                  flexDirection: { sm: "column", xs: "column", md: "row" },
                  display: "flex",
                  gap: "1rem",
                  // justifyContent:"center",
                  alignItems: "center",
                  marginTop: "1.2rem",
                  marginBottom: "1.2rem",
                }}
                fullWidth
              >
                <Box my={1} sx={{ width: "100%" }} key="1">
                  <TextField
                    id="outlined-basic"
                    name="basic_amount"
                    label="Basic Amount"
                    variant="outlined"
                    fullWidth
                    sx={{
                      pointerEvents: "none",
                    }}
                    value={
                      calculations?.basic_amount &&
                      calculations?.basic_amount.toFixed(2)
                    }
                  />
                </Box>

                <Box my={1} sx={{ width: "100%" }} key="2">
                  <TextField
                    id="outlined-basic"
                    name="esi"
                    label="ESI"
                    variant="outlined"
                    fullWidth
                    sx={{
                      pointerEvents: "none",
                    }}
                    value={calculations?.esi && calculations?.esi.toFixed(2)}
                  />
                </Box>

                <Box my={1} sx={{ width: "100%" }} key="3">
                  <TextField
                    id="outlined-basic"
                    name="pf"
                    label="PF"
                    variant="outlined"
                    fullWidth
                    sx={{
                      pointerEvents: "none",
                    }}
                    value={calculations?.pf && calculations?.pf.toFixed(2)}
                  />
                </Box>

                <Box my={1} sx={{ width: "100%" }} key="4">
                  <TextField
                    id="outlined-basic"
                    name="ot_amount"
                    label="OT Amount"
                    variant="outlined"
                    fullWidth
                    sx={{
                      pointerEvents: "none",
                    }}
                    value={
                      calculations?.ot_amount &&
                      calculations?.ot_amount.toFixed(2)
                    }
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  flexDirection: { sm: "column", xs: "column", md: "row" },
                  display: "flex",
                  gap: "1rem",
                  // justifyContent:"center",
                  alignItems: "center",
                  marginTop: "1.2rem",
                  marginBottom: "1.2rem",
                }}
                fullWidth
              >
                <Box my={1} sx={{ width: "100%" }} key="1">
                  <TextField
                    id="outlined-basic"
                    name="gross_amount"
                    label="Gross/MR Amount"
                    variant="outlined"
                    fullWidth
                    sx={{
                      pointerEvents: "none",
                    }}
                    value={
                      calculations?.gross_amount &&
                      calculations?.gross_amount.toFixed(2)
                    }
                  />
                </Box>

                <Box my={1} sx={{ width: "100%" }} key="2">
                  <TextField
                    id="outlined-basic"
                    name="total_ar_amount"
                    label="Total AR Sum"
                    variant="outlined"
                    fullWidth
                    sx={{
                      pointerEvents: "none",
                    }}
                    value={
                      calculations?.total_ar_amount &&
                      calculations?.total_ar_amount.toFixed(2)
                    }
                  />
                </Box>
                <Box my={1} sx={{ width: "100%" }} key="3">
                  <TextField
                    id="outlined-basic"
                    name="attendance_amount"
                    label="Attendance Amount"
                    variant="outlined"
                    fullWidth
                    sx={{
                      pointerEvents: "none",
                    }}
                    value={
                      calculations?.attendance_amount &&
                      calculations?.attendance_amount.toFixed(2)
                    }
                  />
                </Box>
                <Box my={1} sx={{ width: "100%" }} key="4">
                  <TextField
                    id="outlined-basic"
                    name="incentive_amount"
                    label="Incentive Amount"
                    variant="outlined"
                    fullWidth
                    sx={{
                      pointerEvents: "none",
                    }}
                    value={
                      calculations?.incentive_amount &&
                      calculations?.incentive_amount.toFixed(2)
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  flexDirection: { sm: "column", xs: "column", md: "row" },
                  display: "flex",
                  gap: "1rem",
                  // justifyContent:"center",
                  alignItems: "center",
                  marginTop: "1.2rem",
                  marginBottom: "1.2rem",
                }}
                fullWidth
              >
                <Box my={1} sx={{ width: "100%" }} key="4">
                  <TextField
                    id="outlined-basic"
                    name="difference_amount"
                    label="Diff. Amount"
                    variant="outlined"
                    fullWidth
                    sx={{
                      pointerEvents: "none",
                    }}
                    value={
                      calculations?.difference_amount &&
                      calculations?.difference_amount.toFixed(2)
                    }
                  />
                </Box>
                <Box my={1} sx={{ width: "100%" }} key="3">
                  <TextField
                    id="outlined-basic"
                    name="grand_total"
                    label="Grand Total"
                    variant="outlined"
                    fullWidth
                    sx={{
                      pointerEvents: "none",
                    }}
                    value={
                      calculations?.grand_total &&
                      calculations?.grand_total.toFixed(2)
                    }
                  />
                </Box>
              </Box>

              {loading ? (
                <MDButton variant="gradient" color="info" fullWidth>
                  <img
                    src={loader}
                    style={{ width: "20px", height: "20px" }}
                  ></img>
                </MDButton>
              ) : (
                <MDButton
                  variant="gradient"
                  color="info"
                  disabled={
                    (details.empId ? false : true) ||
                    loading === true ||
                    formDetails.working_days === 0
                  }
                  fullWidth
                  onClick={handleSubmit}
                >
                  Submit
                </MDButton>
              )}
            </form>
          </Grid>
        </Grid>
      ) : (
        // <Details data={{ ...details }} salary={salary} />
        // <PaySlip
        //   data={{ ...details, ...formDetails }}
        //   salary={salary}
        // ></PaySlip>

        <Dialog
          open
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {error ? (
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  fontSize: 17,
                  color: "green",
                }}
              >
                Salary Calculation Error ❌{" "}
              </DialogContentText>
            ) : (
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  fontSize: 17,
                  color: "green",
                }}
              >
                Salary Calculated SuccessFully ✅{" "}
              </DialogContentText>
            )}
          </DialogContent>
        </Dialog>
      )}
    </Card>
  );
}

export default Form;
